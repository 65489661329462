import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HivePopupService } from 'src/app/shared/hive-popup/hive-popup.service';

type categoryType = {
  name: string;
  preview: string;
};

type panelType = {
  category: string;
  categoryTypes: categoryType[];
};

interface galleryPanel {
  lang: string;
  panels: panelType[];
}

@Component({
  selector: 'app-code-element-gallery',
  templateUrl: './code-element-gallery.component.html',
  styleUrls: ['./code-element-gallery.component.scss'],
})
export class CodeElementGalleryComponent implements OnInit {
  selectedLang: galleryPanel;
  codePanelForm = new UntypedFormGroup({});

  languageList: galleryPanel[] = [
    {
      lang: 'Python',
      panels: [
        {
          category: 'Editor',
          categoryTypes: [
            {
              name: 'Basic',
              preview: 'assets/images/code_gallery/python_editor_basic.JPG',
            },
            {
              name: 'Locked',
              preview: 'assets/images/code_gallery/python_editor_basic.JPG',
            },
            {
              name: 'Function',
              preview: 'assets/images/code_gallery/python_editor_basic.JPG',
            },
            {
              name: 'Recorded',
              preview: 'assets/images/code_gallery/python_editor_basic.JPG',
            },
          ],
        },
      ],
    },
    {
      lang: 'HTML',
      panels: [
        { category: 'Editor', categoryTypes: [{ name: 'Basic', preview: '' }] },
      ],
    },
    {
      lang: 'CSS',
      panels: [
        { category: 'Editor', categoryTypes: [{ name: 'Basic', preview: '' }] },
      ],
    },
    {
      lang: 'SCSS',
      panels: [
        { category: 'Editor', categoryTypes: [{ name: 'Basic', preview: '' }] },
      ],
    },
    {
      lang: 'SASS',
      panels: [
        { category: 'Editor', categoryTypes: [{ name: 'Basic', preview: '' }] },
      ],
    },
    {
      lang: 'JavaScript',
      panels: [
        { category: 'Editor', categoryTypes: [{ name: 'Basic', preview: '' }] },
      ],
    },
    {
      lang: 'TypeScript',
      panels: [
        { category: 'Editor', categoryTypes: [{ name: 'Basic', preview: '' }] },
      ],
    },
    {
      lang: 'Angular',
      panels: [
        { category: 'Editor', categoryTypes: [{ name: 'Basic', preview: '' }] },
      ],
    },
    {
      lang: 'React',
      panels: [
        { category: 'Editor', categoryTypes: [{ name: 'Basic', preview: '' }] },
      ],
    },
    {
      lang: 'NodeJS',
      panels: [
        { category: 'Editor', categoryTypes: [{ name: 'Basic', preview: '' }] },
      ],
    },
    {
      lang: 'PHP',
      panels: [
        { category: 'Editor', categoryTypes: [{ name: 'Basic', preview: '' }] },
      ],
    },
    {
      lang: 'GoLang',
      panels: [
        { category: 'Editor', categoryTypes: [{ name: 'Basic', preview: '' }] },
      ],
    },
  ];

  constructor(private popupService: HivePopupService) {}

  ngOnInit(): void {}

  closePopUp() {
    console.log(this.codePanelForm.value);
    this.popupService.finishPopupResult(this.codePanelForm.value);
  }

  print(event) {
    console.log(event);
  }

  getSelectedPanels() {
    for (let panel of this.languageList) {
      if (this.selectedLang.lang === panel.lang) {
        return panel.panels;
      }
    }
  }

  selectLang(event) {
    this.selectedLang = event;
    this.codePanelForm = new UntypedFormGroup({});
    for (let panel of this.getSelectedPanels()) {
      let tmpGroup = new UntypedFormGroup({
        IsSelected: new UntypedFormControl(),
        Type: new UntypedFormControl(),
      });
      this.codePanelForm.addControl(panel.category, tmpGroup);
    }
  }
}
