import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FirestoreHandleService } from 'src/app/core/firebase-handle/firestore-handle.service';
import { CourseService } from 'src/app/course/course.service';
import { CollectionTypes } from 'src/app/course/interfaces-enums/collection-types.enum';
import {
  ICourse,
  ICourseLesson,
  ICourseSection,
  ICourseTopic,
} from 'src/app/course/interfaces-enums/course';
import { StructureViewOptions } from 'src/app/course/interfaces-enums/structure-view-options.enum';
import { UserRole } from 'src/app/shared/interfaces-enums/user-role.enum';

@Injectable({ providedIn: 'root' })
export class CourseStructureService {
  addTopicValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  canEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  checkNameValidity: Subject<boolean> = new Subject<boolean>();

  structureViewMode: BehaviorSubject<StructureViewOptions> =
    new BehaviorSubject<StructureViewOptions>(StructureViewOptions.listview);

  saveAndCloseEdit: Subject<boolean> = new Subject<boolean>();
  constructor(
    private courseService: CourseService,
    private firestoreHandleService: FirestoreHandleService
  ) {}

  isTeacher(): boolean {
    return (
      this.courseService.userRuleInCourse === UserRole.teacher &&
      !this.courseService.onStudentView
    );
  }

  closeEdit(): void {
    this.saveAndCloseEdit.next(true);
  }

  canEditStatus(status: boolean): void {
    this.canEdit.next(status);
  }
  checkNameForAll(): void {
    this.checkNameValidity.next(false);
  }

  getCanEdit(): boolean {
    return this.canEdit.value;
  }

  addTopic(currentCourse: ICourse, title?: string): void {
    let lastIndex = currentCourse.topics
      ? Object.keys(currentCourse.topics).length + 1
      : 0;

    const topicID: string = this.firestoreHandleService.generateDocumentId(
      `courses/${currentCourse.id}/topics`
    );
    const lessonID: string = this.firestoreHandleService.generateDocumentId(
      `courses/${currentCourse.id}/lessons`
    );
    const sectionID: string = this.firestoreHandleService.generateDocumentId(
      `courses/${currentCourse.id}/sections`
    );

    const newSection: ICourseSection = {
      ...this.courseService.createLayer(
        1,
        sectionID,
        lessonID,
        currentCourse.creator_id
      ),
      elements: [],
      actions: [],
      type: CollectionTypes.section,
    };
    const newLesson: ICourseLesson = {
      ...this.courseService.createLayer(
        1,
        lessonID,
        topicID,
        currentCourse.creator_id
      ),
      sections: {},
      type: CollectionTypes.lesson,
    };

    const newTopic: ICourseTopic = {
      ...this.courseService.createLayer(
        lastIndex,
        topicID,
        currentCourse.id,
        currentCourse.creator_id,
        title
      ),
      lessons: {},
      type: CollectionTypes.topic,
      color: '',
    };

    newSection.metaData.has_data_changed = true;
    newLesson.sections[sectionID] = newSection;
    newTopic.lessons[lessonID] = newLesson;
    newTopic.metaData.open = true;
    newTopic.color = this.courseService.randomColorGenerator();
    this.courseService.updateCurrentCourseByPath({ topic: topicID }, newTopic);
  }

  addLesson(currentTopic: ICourseTopic, topicId: string, title?: string): void {
    let lastIndex = Object.keys(currentTopic.lessons).length + 1;

    const lessonID: string = this.firestoreHandleService.generateDocumentId(
      `courses/${this.courseService.getCourseID()}/lessons`
    );
    const sectionID: string = this.firestoreHandleService.generateDocumentId(
      `courses/${this.courseService.getCourseID()}/sections`
    );

    const newSection: ICourseSection = {
      ...this.courseService.createLayer(
        1,
        sectionID,
        lessonID,
        currentTopic.creator_id
      ),
      elements: [],
      actions: [],
      type: CollectionTypes.section,
    };
    const newLesson: ICourseLesson = {
      ...this.courseService.createLayer(
        lastIndex,
        lessonID,
        currentTopic.id,
        currentTopic.creator_id,
        title
      ),
      sections: {},
      type: CollectionTypes.lesson,
    };

    newSection.metaData.has_data_changed = true;
    newLesson.sections[sectionID] = newSection;
    this.courseService.updateCurrentCourseByPath(
      { topic: topicId, lesson: lessonID },
      newLesson
    );
  }

  addSection(
    currentLesson: ICourseLesson,
    topicId: string,
    title?: string
  ): void {
    const lastIndex = Object.keys(currentLesson.sections).length + 1;

    const sectionID: string = this.firestoreHandleService.generateDocumentId(
      `courses/${this.courseService.getCourseID()}/sections`
    );

    const newSection: ICourseSection = {
      ...this.courseService.createLayer(
        lastIndex,
        sectionID,
        currentLesson.id,
        currentLesson.creator_id,
        title
      ),
      elements: [],
      actions: [],
      type: CollectionTypes.section,
    };

    newSection.metaData.has_data_changed = true;
    this.courseService.updateCurrentCourseByPath(
      { topic: topicId, lesson: currentLesson.id, section: sectionID },
      newSection
    );
  }

  structureViewControl(view: StructureViewOptions): void {
    this.structureViewMode.next(view);
    localStorage.setItem('panel_view', view.toString());
    this.canEditStatus(true);
  }
}
