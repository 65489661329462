<div *ngIf="isTeacher">
  <div class="topic-container" [ngStyle]="getStyles()">
    <div class="topic-wrapper">
      <app-structure-header-manager
        (toggle)="onToggle($event)"
        [element]="topic"
        [path]="{ topic: id }"
      ></app-structure-header-manager>
      <div
        class="topic-body"
        [ngStyle]="topic.metaData.open ? {} : { display: 'none' }"
      >
        <div
          class="lessons-container"
          *ngFor="let lesson of topic.lessons | keyvalue : valueAscOrder"
        >
          <app-course-structure-lesson
            [lesson]="lesson.value"
            [id]="lesson.key"
            [parents]="{ topic: id }"
          >
          </app-course-structure-lesson>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isTeacher">
  <div class="topic-container" [ngStyle]="getStyles()">
    <div class="topic-wrapper">
      <app-structure-header-manager
        (toggle)="ontoggle($event)"
        [element]="topic"
        [path]="{ topic: id }"
      ></app-structure-header-manager>
      <div
        class="topic-body"
        [ngStyle]="topic.metaData.open ? {} : { display: 'none' }"
      >
        <div
          class="lessons-container"
          *ngFor="let lesson of topic.lessons | keyvalue : valueAscOrder"
        >
          <app-course-structure-lesson
            [lesson]="lesson.value"
            [id]="lesson.key"
            [parents]="{ topic: id }"
          >
          </app-course-structure-lesson>
        </div>
      </div>
    </div>
  </div>
</div>
