<div class="validator-container" >
  <div class="validator-content-wrapper">
    <div class="validator-header">
      <h3 class="validator-header-h3" i18n="are you sure title| are you sure title@@areYouSureTitle">Delete Message</h3>
      <button class="closeBTN" (click)="onNo()">
        <mat-icon class="closeIcon">close</mat-icon>
      </button>
    </div>
   <div class="validator-content">
     <p class="validatorText"
        i18n="are you sure text| are you sure text@@areYouSureText">Are you sure to delete this?</p>
     <div class="option-btn">
       <button (click)="onNo()"  i18n="are you sure no button | are you sure no button@@areYouSureNoBTN"
               class="noBTN">NO</button>
       <button (click)="onYes()" i18n="are you sure yes button | are you sure yes button@@areYouSureYesBTN"
               class="yesBTN" >YES</button>
     </div>
   </div>
  </div>
</div>
