import { canDeactivateGuard } from './../core/guards/unsaved-changes.guard';
import { HiveIdGuard } from './../core/guards/hive-id.guard';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../core/guards/auth.guard';
import { CoursePanelComponent } from './course-panel/course-panel.component';
import { CourseDetailsComponent } from './course-panel/components/course-details/course-details.component';
import { CourseFinanceComponent } from './course-panel/components/course-finance/course-finance.component';
import { CourseForumComponent } from './course-panel/components/course-forum/course-forum.component';
import { CourseLandingPageComponent } from './course-panel/components/course-landing-page/course-landing-page.component';
import { CourseResourcesComponent } from './course-panel/components/course-resources/course-resources.component';
import { CourseSettingsComponent } from './course-panel/components/course-settings/course-settings.component';
import { CourseStructureComponent } from './course-panel/components/course-structure/course-structure.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: ':course_id/editor',
        canActivate: [HiveIdGuard, AuthGuard],
        data: { path: 'editor' },
        loadChildren: () =>
          import('./../course/course-editor/course-editor.module').then(
            (m) => m.CourseEditorModule
          ),
      },
      {
        path: ':course_id/viewer',
        canActivate: [HiveIdGuard, AuthGuard],
        data: { path: 'viewer' },
        loadChildren: () =>
          import('./../course/course-viewer/course-viewer.module').then(
            (m) => m.CourseViewerModule
          ),
      },
      {
        path: ':course_id',
        canActivate: [HiveIdGuard],
        component: CoursePanelComponent,
        children: [
          {
            path: 'structure',
            component: CourseStructureComponent,
            canDeactivate: [canDeactivateGuard],
            data: { title: 'structure' },
          },
          {
            path: 'details',
            component: CourseDetailsComponent,
            data: { title: 'details' },
          },
          {
            path: 'resources',
            component: CourseResourcesComponent,
            data: { title: 'resources' },
          },
          {
            path: 'finance',
            component: CourseFinanceComponent,
            data: { title: 'finance' },
          },
          {
            path: 'forum',
            component: CourseForumComponent,
            data: { title: 'forum' },
          },
          {
            path: 'landing_page',
            component: CourseLandingPageComponent,
            data: { title: 'landing page' },
          },
          {
            path: 'settings',
            component: CourseSettingsComponent,
            data: { title: 'settings' },
          },
        ],
      },
    ],
  },
];

export const CourseRoutes = RouterModule.forChild(routes);
