
<!--<div class="section-header">-->
<!--  <div class="section-title-edit" *ngIf="sectionNameEdit">-->
<!--    <label for="section-name-edit"><strong>SECTION: </strong></label>-->
<!--    <input #sectionName type="text" id="section-name-edit"-->
<!--           placeholder="{{section.name? section.name: 'Please Enter A Name'}}">-->
<!--    <mat-icon (click)="onSectionNameSave(sectionName.value)">check</mat-icon>-->
<!--    <mat-icon (click)="onSectionNameEdit()">close</mat-icon>-->
<!--  </div>-->
<!--  <div (click)="isValid? onSectionNameEdit():''" class="section-title" *ngIf="!sectionNameEdit">-->
<!--    <p><strong>SECTION: </strong></p>-->
<!--    <p>{{section.name? section.name: 'Please Enter A Name'}}</p>-->
<!--    <mat-icon>edit</mat-icon>-->
<!--  </div>-->
<!--  <div class="section-icons">-->
<!--    <mat-icon (click)="onDelete()">delete</mat-icon>-->
<!--    <mat-icon [matMenuTriggerFor]="menu">settings</mat-icon>-->
<!--    <mat-menu #menu="matMenu">-->
<!--      <button style="background-color: orange" mat-menu-item (click)="onEditorNavigate()">Editor</button>-->
<!--      <button mat-menu-item>Settings</button>-->
<!--    </mat-menu>-->
<!--  </div>-->
<!--</div>-->

<app-structure-header-manager
  [element]="section" [path]="{topic: parents.topic,
                               lesson: parents.lesson, section: id}">

</app-structure-header-manager>
