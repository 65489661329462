import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

type categoryType = {
  name: string
  preview: string
}

type panelType = {
  category: string
  categoryTypes: categoryType[]
}

@Component({
  selector: 'app-code-gallery-panel',
  templateUrl: './code-gallery-panel.component.html',
  styleUrls: ['./code-gallery-panel.component.scss']
})
export class CodeGalleryPanelComponent implements OnInit {
  @Input() panelConfig: panelType;
  @Input() panelFormGroup: UntypedFormGroup;
  categoryName: string;

  canShow: boolean = false;

  constructor() { }

  ngOnInit(): void {
  this.categoryName = this.panelConfig.category

  }

  uncheckRadios(){
    this.panelFormGroup.controls[this.panelConfig.category].reset()
  }
  checkBasic(){
    this.panelFormGroup.controls[this.panelConfig.category]['controls']['Type'].setValue('Basic')
  }

  checkCheckbox(){
    this.panelFormGroup.controls[this.panelConfig.category]['controls']['IsSelected'].setValue(true)
  }

}
