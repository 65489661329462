import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import { CourseService } from 'src/app/course/course.service';


export enum PopupTypes{
  imageGallery = 'imageGallery',
  videoGallery = 'videoGallery',
  codeGallery = 'codeGallery',
  uniTests = 'uniTests'
}


@Injectable({providedIn:'root'})
export class HivePopupService{
  private isActive: boolean = false;
  isActiveSub: Subject<boolean> = new Subject<boolean>();
  result: Subject<any> = new Subject<any>();
  currentResult: Subject<any> = new Subject<any>();
  popupTypeSub: BehaviorSubject<PopupTypes> = new BehaviorSubject<PopupTypes>(null);
  popupTypes = PopupTypes;

  showTermsSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  // data for new popup from existing source (edit)
  prePopupData: any
  isFromUserSetting: boolean = false;

  constructor( private courseService: CourseService) {
  }

  private _setIsActive(to?: boolean){
    if(to != null){
      this.isActive = to;
      this.isActiveSub.next(this.isActive);
    }
    else{
      this.isActive = !this.isActive;
      this.isActiveSub.next(this.isActive);
    }
  }
  finishPopupResult(popupResult){
    this.result.next(popupResult);
    this._setIsActive(false);
    this.prePopupData = null;
    if(popupResult && !this.isFromUserSetting){
      setTimeout(()=>{
        let tempCourse = this.courseService.getCurrentCourseSync();
        this.courseService.updateCourseHistoryList(tempCourse)
      },0)
    }
  }

  startPopupResult(type: PopupTypes, preData?: any, isFromUserSetting?: boolean): Promise<any>{
    if(preData){
      this.prePopupData = preData;
    }
    if (isFromUserSetting){
      this.isFromUserSetting = isFromUserSetting;
    }
    //ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(()=>{
      this._setIsActive(true);
    },0)
    this.popupTypeSub.next(type);
    return new Promise<any>(resolve => {
      this.result.pipe(take(1)).subscribe(data => {
        resolve(data);
      })
    })
  }

  updateCurrentResult(data: any){
    this.currentResult.next(data)
  }

  getIsActive(): boolean{
    return this.isActive
  }

  getPrePopupData(){
    return this.prePopupData
  }

  showTermsModal(){
    this.showTermsSub.next(true)
  }
}
