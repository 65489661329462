import { Injectable } from '@angular/core';
import * as fbAuth from '@angular/fire/auth';
import { globalVariables } from '../../shared/global-variables';
import { BehaviorSubject, Observable } from 'rxjs';
import { FirestoreHandleService } from '../../core/firebase-handle/firestore-handle.service';
import { Router } from '@angular/router';
import { IUser } from './interfaces-enums/IUser';
import { UserDatabaseService } from './user.database.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user: IUser;
  authUser: fbAuth.User;
  userSubject: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);

  constructor(
    private globals: globalVariables,
    private firestoreService: FirestoreHandleService,
    private router: Router,
    private userDatabaseService: UserDatabaseService
  ) {}

  // FIREBASE AUTHENTICATION
  auth = fbAuth.getAuth(this.globals.firebase_app);
  googleProvider = new fbAuth.GoogleAuthProvider();

  authUserSub = fbAuth.onAuthStateChanged(this.auth, (authUser) => {
    if (authUser) {
      if (authUser.uid) {
        this.userDatabaseService.getUserInfo(authUser.uid).subscribe({
          next: (user) => {
            if (user) {
              this.user = user;
              this.authUser = authUser;
            }
          },
        });
      } else {
        console.log(authUser.email + ' User Not Found');
      }
    } else {
      this.signOut();
    }
  });

  setCurrentUser(user: IUser): void {
    this.user = user;
    this.userSubject.next(this.user);
  }

  isLoggedIn(): boolean {
    return !!this.user;
  }

  signOut(): void {
    this.authUser = null;
    this.user = null;
    this.userSubject.next(null);
    console.log('USER IS SIGNED OUT!');
  }

  signInWithGoogle(): void {
    fbAuth.signInWithPopup(this.auth, this.googleProvider).then((result) => {
      this.authUser = result.user;
      this.userDatabaseService.getUserInfo(result.user.uid).subscribe({
        next: (userData) => {
          if (userData) {
            this.setCurrentUser(userData);
            console.log('user logged in');
            this.router.navigateByUrl('/dashboard');
          } else {
            const newUser: IUser = {
              email: this.authUser.email,
              uid: this.authUser.uid,
              user_courses: [],
              name: this.authUser.displayName,
              image: this.authUser.photoURL,
              description: '',
            };
            this.userDatabaseService.createNewUser(newUser).subscribe({
              complete: () => {
                this.setCurrentUser(newUser);
                console.log('created user');
                this.router.navigateByUrl('/dashboard');
              },
              error: (err) => {
                console.log(`User ${newUser.email} Was Not Created!`);
                throw err;
              },
            });
          }
        },
      });
    });
  }

  getUserDetails(): IUser {
    return this.user;
  }

  getUserByUid(uid: string): Observable<IUser> {
    return this.userDatabaseService.getUserInfo(uid);
  }

  authSetLocalUser(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.user) {
        console.log('found local User');
        resolve(true);
      } else if (this.authUser) {
        console.log('found local AuthUser');
        this.userDatabaseService.getUserInfo(this.authUser.uid).subscribe({
          next: (result) => {
            if (result.uid) {
              this.setCurrentUser(result);
              resolve(true);
            } else {
              console.log(this.authUser.email + ' Does Not Exists In Database');
              resolve(false);
            }
          },
        });
      } else {
        fbAuth.onAuthStateChanged(this.auth, (user) => {
          if (user) {
            console.log('found local Auth Data, Getting User');
            this.authUser = user;
            this.userDatabaseService.getUserInfo(user.uid).subscribe({
              next: (result) => {
                if (result.uid) {
                  this.setCurrentUser(result);
                  resolve(true);
                } else {
                  console.log(
                    this.authUser.email + ' Does Not Exists In Database'
                  );
                  resolve(false);
                }
              },
            });
          } else {
            resolve(false);
          }
        });
      }
    });
  }

  getUserId(): string {
    return this.user.uid;
  }

  logout(): void {
    this.userSubject.next(null);
    this.user = null;
  }

  updateUser(user: IUser): Observable<void> {
    return this.userDatabaseService.updateUser(user);
  }
}
