import { Router } from '@angular/router';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({
  providedIn: 'root',
})
export class HttpErrorHandlingInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const timeoutValue = request.headers.get('timeout') || this.defaultTimeout;
    // const timeoutValueNumeric = Number(timeoutValue);

    return next.handle(request).pipe(
      // retry(2),
      timeout(180000),
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 422) {
              // this.initService.isLoading$.next(false);
            }

            if (error.status === 404) {
              // this.initService.isLoading$.next(false);
            }

            if (error.status === 423) {
              // this.initService.isLoading$.next(false);
              // this.router.navigate(['/error', ErrorsEnum.SECURE_ACCOUNT]);
              return throwError(error);
            }

            if (error.status === 400) {
              // this.initService.isLoading$.next(false);
              // this.router.navigate(['/','workspace']);
              return throwError(error);
            }
            if (error.status === 401) {
              // localStorage.removeItem('id_token');
              // this.initService.isLoading$.next(true);
              // this.sessionStorageService.remove(
              //   SessionStorageKeys.SELECTED_ENTITY
              // );
              // this.authService.logout({
              //   returnTo: environment.url,
              // });
            } else if (error?.error?.type === 'text/xml') {
              // this.initService.isLoading$.next(false);
            } else {
              // this.initService.isLoading$.next(false);

              if (
                error.status === 504 ||
                error.status === 200 ||
                error.status === 0
              ) {
                return throwError(error);
              } else {
                // this.router.navigate(['/error/' + error.status]);
                return throwError(error);
              }
            }
          }
          return throwError(error);
        } else {
          // client
          return throwError(error);
        }
      })
    );
  }
}
