import { Component, OnInit } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-unsaved-changes',
  templateUrl: './unsaved-changes.component.html',
  styleUrls: ['./unsaved-changes.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
})
export class UnsavedChangesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
