<div class="unittest-wrapper">
  <div class="unittest">
    <div class="unittest-header">
      <h3 *ngIf="title">{{title}}</h3>
      <span (click)="closePopUp(false)" class="material-icons-outlined closeIcon">close</span>
    </div>
    <div class="unittest-content">
        <ng-content></ng-content>
    </div>
    <div class="unittest-footer">
      <div class="unittest-btns">
        <button class="cancel-btn" (click)="getLeftButtonFunction()">{{leftButton}}</button>
        <button class="choose-btn" (click)="getRightButtonFunction()">{{rightButton}}</button>
      </div>
    </div>
  </div>
</div>

