<div class="course-container">
  <div class="course-sidebar">
    <app-sidebar-hive [items]="COURSE_MENU"></app-sidebar-hive>
  </div>
  <div class="course-content">
    <page-title
      [backToRoute]="'/courses'"
      [pageName]="pageName"
      [course]="course"
      [backToLocation]="'dashboard'"
      [userRule]="userRule"
      [onStudentView]="onStudentView"
    ></page-title>
    <router-outlet></router-outlet>
  </div>
</div>
