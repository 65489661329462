import {
  COURSES,
  DOCUMENTS,
  LESSONS,
  SECTIONS,
  SECTION_DATA,
  TOPICS,
  TO_DELETE_PATH,
  USERS_PATH,
} from './db-paths.config';
import { map } from 'rxjs/operators';
import { FirestoreHandleService } from './../core/firebase-handle/firestore-handle.service';
import { Injectable } from '@angular/core';
import {
  ICourse,
  ICourseDb,
  ICourseLesson,
  ICourseSection,
  ICourseTopic,
  IFirebaseCourse,
  ISectionData,
} from './interfaces-enums/course';
import { IFirestoreBatch } from '../core/firebase-handle/interfaces-enums/firebase-batch';
import { IUser } from '../hive/user/interfaces-enums/IUser';
import { forkJoin, Observable } from 'rxjs';
import { UserDatabaseService } from '../hive/user/user.database.service';

@Injectable({
  providedIn: 'root',
})
export class CourseDataService {
  constructor(
    private firestoreService: FirestoreHandleService,
    private userDatabaseService: UserDatabaseService
  ) {}

  getSelectedSectionElements(
    courseID: string,
    sectionID: string
  ): Observable<ISectionData | null> {
    const path = `/${COURSES}/${courseID}/${SECTION_DATA}/${sectionID}`;
    return this.firestoreService.getDocument(path).pipe(
      map((result) => result as ISectionData | null),
      map((result) => {
        if (!result) {
          console.log('Section elements not found');
        }
        return result;
      })
    );
  }

  createCourse(courseDB: ICourseDb, courseUi: ICourse) {
    return this.initiateCourseDb(courseUi, courseDB, true);
  }

  getCourseById(courseID: string): Observable<ICourseDb | null> {
    return this.firestoreService.getDocument(`/${COURSES}/${courseID}`).pipe(
      map((result) => {
        if (!result) {
          console.log('Course not found');
          return null;
        }
        return result as ICourseDb;
      })
    );
  }

  courseDbToCourseUi(course: ICourseDb) {
    const docArray = this.createDocArray(course.documents);
    const allDocs = {};
    docArray.forEach((doc) => {
      allDocs[doc.type] = this.firestoreService.getDocument(
        `/${COURSES}/${course.id}/${doc.type}/${doc.id}`
      );
    });
    return forkJoin(allDocs);
  }

  initiateCourseDb(
    createdCourse: ICourse,
    createdCourseDb: ICourseDb,
    isNewCourse = false
  ) {
    const courseData = this.courseDataToDb(createdCourse);
    delete createdCourseDb.metaData;
    const firebaseCourse: IFirebaseCourse = {
      ...courseData,
      courses: createdCourseDb,
    };

    if (isNewCourse) {
      this.initCourseLayers(firebaseCourse);
    }
    return {
      batch: this.firebaseCourseToFirestoreBatch(firebaseCourse),
      firebaseCourse,
    };
  }

  firebaseCourseToFirestoreBatch(firebaseCourse: IFirebaseCourse) {
    const firestoreBatch: IFirestoreBatch[] = Object.entries(firebaseCourse)
      .filter(([key]) => key !== SECTION_DATA)
      .map(([key, value]) => {
        const basePath =
          key === COURSES
            ? `/${COURSES}`
            : `/${COURSES}/${firebaseCourse.courses.id}/${key}`;
        const documentId =
          key === COURSES
            ? firebaseCourse.courses.id
            : firebaseCourse.courses.documents[key][0];

        return {
          path: basePath,
          document: documentId,
          data: {
            ...value,
            creator_id: firebaseCourse.courses.creator_id,
          },
        };
      });

    return this.firestoreService.setBatch(
      this.setSectionDataToFirestoreBatch(firebaseCourse, firestoreBatch)
    );
  }

  setSectionDataToFirestoreBatch(
    firebaseCourse: IFirebaseCourse,
    firestoreBatch: IFirestoreBatch[]
  ) {
    Object.entries(firebaseCourse.sectionData).forEach(([key, value]) => {
      firestoreBatch.push({
        path: `/${COURSES}/${firebaseCourse.courses.id}/${SECTION_DATA}`,
        document: key,
        data: {
          ...value,
          creator_id: firebaseCourse.courses.creator_id,
        },
      });
    });

    return firestoreBatch;
  }

  updateCourse(course: ICourse, user: IUser) {
    const coursePath = `/${COURSES}/${course.id}`;
    const timestamp = this.firestoreService.getTimeStamp();
    course.last_updated = timestamp;

    const userUpdate$ = this.userDatabaseService.updateUser(user);

    return this.firestoreService
      .setDocument(coursePath, course)
      .pipe(map(() => userUpdate$.subscribe()));
  }

  deleteCourse(course: ICourse, user: IUser) {
    const timeStamp = this.firestoreService.getTimeStamp();
    user.user_courses = user.user_courses.map((userCourse) =>
      userCourse.id === course.id
        ? { ...userCourse, deleted: true, deletion_date: timeStamp }
        : userCourse
    );
    const updateBatch = this.getUpdateBatchList(course, user, timeStamp);
    return this.firestoreService.updateBatch(updateBatch);
  }

  copyCourseObject(item): any {
    return JSON.parse(JSON.stringify(item));
  }

  courseDataToDb(course: ICourse) {
    const topics: ICourseTopic = {} as ICourseTopic;
    const lessons: ICourseLesson = {} as ICourseLesson;
    const sections: ICourseSection = {} as ICourseSection;
    const sectionData: ISectionData = {} as ISectionData;

    if (course.metaData?.to_delete_section) {
      this.removeDeletedSectionDataDoc(course);
    }

    Object.values(course.topics).forEach((topic) => {
      topics[topic.id] = { ...topic };
      delete topics[topic.id].lessons;
      delete topics[topic.id].metaData;

      Object.values(topic.lessons).forEach((lesson) => {
        lessons[lesson.id] = { ...lesson };
        delete lessons[lesson.id].sections;
        delete lessons[lesson.id].metaData;

        Object.values(lesson.sections).forEach((section) => {
          sections[section.id] = { ...section };
          if (sections[section.id].metaData?.has_data_changed) {
            sectionData[section.id] = {
              elements: sections[section.id].elements
                ? sections[section.id].elements
                : [],
              actions: sections[section.id].actions
                ? sections[section.id].actions
                : [],
              parentID: section.id,
            };
          }

          delete sections[section.id].elements;
          delete sections[section.id].actions;
          delete sections[section.id].metaData;

          course.topics[topic.id].lessons[lesson.id].sections[
            section.id
          ].metaData.has_data_changed = false;
        });
      });
    });

    return {
      topics,
      lessons,
      sections,
      sectionData,
    };
  }

  removeDeletedSectionDataDoc(course: ICourse) {
    course.metaData.to_delete_section.forEach((docID: string) => {
      this.firestoreService.deleteDocument(
        `/${COURSES}/${course.id}/${SECTION_DATA}/${docID}`
      );
    });
    course.metaData.to_delete_section = [];
  }

  initCourseLayers(firebaseCourse: IFirebaseCourse) {
    firebaseCourse.courses[DOCUMENTS] = {
      topics: [
        this.firestoreService.generateDocumentId(
          `/${COURSES}/${firebaseCourse.courses.id}/${TOPICS}`
        ),
      ],
      lessons: [
        this.firestoreService.generateDocumentId(
          `/${COURSES}/${firebaseCourse.courses.id}/${LESSONS}`
        ),
      ],
      sections: [
        this.firestoreService.generateDocumentId(
          `/${COURSES}/${firebaseCourse.courses.id}/${SECTIONS}`
        ),
      ],
    };
  }

  createDocArray(documents: any) {
    const arr = [];
    for (let doc in documents) {
      arr.push({
        type: doc,
        id: documents[doc][0],
      });
    }
    return arr;
  }

  dbDataToCourse(course: IFirebaseCourse): ICourse {
    delete course.topics.creator_id;
    const result = {
      ...course.courses,
      topics: {},
    };

    for (const topicId in course.topics) {
      const topicsWithMetaData = this.getLayerMetadata(course.topics[topicId]);
      result.topics[topicId] = {
        ...topicsWithMetaData,
        lessons: {},
      };

      for (const lessonId in course.lessons) {
        const lessonsWithMetaData = this.getLayerMetadata(
          course.lessons[lessonId]
        );

        if (lessonsWithMetaData.parent === topicsWithMetaData.id) {
          result.topics[topicId].lessons[lessonId] = {
            ...lessonsWithMetaData,
            sections: {},
          };

          for (const sectionId in course.sections) {
            const sectionWithMetaData = this.getLayerMetadata(
              course.sections[sectionId]
            );
            if (sectionWithMetaData.parent === lessonsWithMetaData.id) {
              result.topics[topicId].lessons[lessonId].sections[sectionId] = {
                ...sectionWithMetaData,
              };
            }
          }
        }
      }
    }
    return result;
  }

  getLayerMetadata(layer: ICourseTopic | ICourseLesson | ICourseSection) {
    return {
      ...layer,
      metaData: { open: true },
    };
  }

  getUpdateBatchList(course: ICourse, user: IUser, timeStamp) {
    return [
      {
        path: `/${COURSES}`,
        document: course.id,
        data: {
          deleted: true,
          deletion_date: timeStamp,
          creator_id: user.uid,
        },
      },
      {
        path: `/${USERS_PATH}/`,
        document: user.uid,
        data: user,
        creator_id: user.uid,
      },
      {
        path: `/${TO_DELETE_PATH}/`,
        document: course.id,
        data: {
          creator_id: user.uid,
          date: timeStamp
        },
      },
    ];
  }
}
