  <div class="image-gallery-wrapper">
    <div class="image-gallery">
      <div class="image-gallery-header">
        <h3>Choose Image</h3>
        <span (click)="closePopUp()" class="material-icons-outlined closeIcon">close</span>
      </div>
      <div class="image-gallery-content">
        <ul class="image-list">
          <li class="imageItem" *ngFor="let item of imageList; let i = index">
            <img  [src]="item" alt="image in gallery"  (click)="onSelectImage(item)" />
            <div *ngIf="imageUrl === item || i === isExistsIndex " class="isSelected" (dblclick)="closePopUp(item)"></div>
<!--            <div *ngIf="i === isExistsIndex" class="isSelected" (dblclick)="closePopUp(item)"></div>-->
          </li>
        </ul>
      </div>

      <div *ngIf="!addNewUrl" class="image-gallery-footer">
        <div class="image-gallery-btns">
          <button class="add-new-image-btn" (click)="addNewUrl= true">
            <span class="material-icons-outlined">add</span>
            NEW IMAGE
          </button>
        </div>
        <div class="image-gallery-btns">
          <button class="cancel-btn" (click)="closePopUp()" >Cancel</button>
          <button class="choose-btn" (click)="closePopUp(imageUrl)">Choose</button>
        </div>
      </div>

      <div *ngIf="addNewUrl" class="image-gallery-footer add-new-image">
        <div class="url-input">
          <label for="image-url">URL:</label>
          <input class="input-value" type="text" id="image-url" name="image-url" (click)="reloadValidations()"
                 #newImageUrl [(ngModel)]="urlValue" placeholder="paste here image url">
          <span class="material-icons-outlined edit-icon">edit</span>
          <p class="notValid" *ngIf="isValid">invalid image URL</p>
          <p class="notValid" *ngIf="isExists">URL already exists in your gallery</p>
        </div>
        <div class="image-gallery-btns">
          <button class="add-new-image-btn" (click)="onAddNewImage(newImageUrl.value)">
            <span class="material-icons-outlined">add</span>
            NEW IMAGE
          </button>
      </div>
      </div>
   </div>
  </div>
