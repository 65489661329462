import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  private isActive: boolean = false;
  isActiveSub: Subject<boolean> = new Subject<boolean>();
  result: Subject<boolean> = new Subject<boolean>();

  private _setIsActive(to?: boolean) {
    if (to != null) {
      this.isActive = to;
      this.isActiveSub.next(this.isActive);
    } else {
      this.isActive = !this.isActive;
      this.isActiveSub.next(this.isActive);
    }
  }
  setResult(result) {
    this.result.next(result);
    this._setIsActive(false);
  }

  getResult() {
    this._setIsActive(true);
    return this.result.pipe(take(1));
  }
}
