import { UnsavedChangesComponent } from './unsaved-changes.component';
import { Injectable, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesService {
  unsavedChanges = signal(false);
  resetCourse = new BehaviorSubject(false);

  constructor(public dialog: MatDialog) {}

  confirm() {
    const dialogRef = this.dialog.open(UnsavedChangesComponent);
    dialogRef.afterClosed().subscribe((data) => {
      if (data === true) {
        this.setUnsavedChanges(false);
      }
      this.resetCourse.next(data);
    });

    return dialogRef.afterClosed();
  }

  setUnsavedChanges(val: boolean) {
    this.unsavedChanges.set(val);
  }

  getUnsavedChanges() {
    return this.unsavedChanges();
  }
}
