import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageHandleService } from '../../../core/firebase-handle/message-handle.service';
import { Subscription } from 'rxjs';
import { messageModel } from '../../../core/firebase-handle/message.model';

@Component({
  selector: 'app-user-inbox',
  templateUrl: './user-inbox.component.html',
  styleUrls: ['./user-inbox.component.scss'],
})
export class UserInboxComponent implements OnInit, OnDestroy {
  userInbox: { [p: string]: messageModel };
  userInboxSub: Subscription;

  constructor(private firebase: MessageHandleService) {}

  ngOnInit(): void {
    // this.sidebarService.setCurrentComponentPath('default');
    this.userInboxSub = this.firebase.localMessages.subscribe((messages) => {
      if (messages) {
        this.userInbox = messages;
      }
    });
    this.firebase.getMessagesOnce().then((data) => {
      if (!this.firebase.isInSync()) {
        this.firebase.updateLocalMessagesDB().then((data) => {
          this.userInbox = data;
        });
      } else {
        this.userInbox = data;
      }
    });
  }

  ngOnDestroy() {
    this.userInboxSub.unsubscribe();
  }

  print(event) {
    console.log(event);
  }
}
