import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Subscription } from 'rxjs';
import { HivePopupService } from '../../../shared/hive-popup/hive-popup.service';
import { PrimeNGConfig } from 'primeng/api';
import { Router } from '@angular/router';
import { ElementFrameService } from 'src/app/course/course-editor/element-frame/element-frame.service';
import {IUser} from '../interfaces-enums/IUser';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  isHebrew: boolean = false;
  currentUser: IUser;
  selectedElementSub: Subscription;

  days: any[];
  selectedDays;

  print(event) {
    console.log(event);
  }

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private userService: UserService,
    private frameService: ElementFrameService,
    private popUpService: HivePopupService,
    private primeNGConfig: PrimeNGConfig,
    private router: Router
  ) {
    this.days = [
      { name: 'SUN', value: 'sunday' },
      { name: 'MON', value: 'monday' },
      { name: 'TUE', value: 'tuesday' },
      { name: 'WED', value: 'wednesday' },
      { name: 'THU', value: 'thursday' },
      { name: 'FRI', value: 'friday' },
      { name: 'SAT', value: 'saturday' },
    ];
  }

  ngOnInit(): void {
    this.primeNGConfig.ripple = true;
    if (this.locale === 'he') {
      this.isHebrew = true;
    }
    this.currentUser = this.userService.getUserDetails();
  }

  uploadNewImg() {
    this.openGalleryPopUp();
  }

  removeImg() {
    console.log(this.currentUser.image);
    this.currentUser.image = 'assets/user_img_deafult.png';
  }

  updateCurrentUser(name, image, description) {
    this.updateLocalUser(name, image, description);
  }

  updateLocalUser(name, image, description) {
    this.currentUser.name = name;
    this.currentUser.image = image;
    this.currentUser.description = description;
  }

  ngOnDestroy(): void {
    this.updateCurrentUser(
      this.currentUser.name,
      this.currentUser.image,
      this.currentUser.description
    );
    this.popUpService.isFromUserSetting = false;
  }

  openGalleryPopUp() {
    this.popUpService
      .startPopupResult(this.popUpService.popupTypes.imageGallery, null, true)
      .then((data) => {
        if (data) {
          this.currentUser.image = data;
        }
      });
  }

  onAddPaymentMethod() {}

  onAddLandingPage() {
    this.router.navigateByUrl('/hive/landing_page');
  }

  addSelectedDays(days) {
    this.selectedDays = days;
  }
}
