import { CourseLanguage } from './../../../shared/interfaces-enums/course-language.enum';
import { skillLevel } from './../../../shared/interfaces-enums/course-skill-level.enum';
export const CourseLevels = [
  skillLevel.beginner,
  skillLevel.intermediate,
  skillLevel.expert,
];
export const CourseLanguages = [
  CourseLanguage.english,
  CourseLanguage.hebrew,
  CourseLanguage.russian,
];
