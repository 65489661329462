
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  errorNumber: any = '';
  subTitle: string = '';
  auditId = '';
  currentStep = '';
  isVisibleMiddle = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {

  }

  goBack() {
  }

  signIn() {

  }
  
  logout() {

  }



}
