import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IUserCourse,
  IUserLesson,
  IUserSection,
  IUserTopic,
} from './interfaces-enums/IUserCourse';
import { LayerTypes } from '../../course/interfaces-enums/layerTypes.enum';

import { globalVariables } from '../../shared/global-variables';
import { FirestoreHandleService } from '../../core/firebase-handle/firestore-handle.service';
import { IUser } from './interfaces-enums/IUser';
import {
  ICourse,
  ICourseLesson,
  ICourseSection,
  ICourseTopic,
} from 'src/app/course/interfaces-enums/course';

@Injectable({
  providedIn: 'root',
})
export class UserDatabaseService {
  constructor(
    private globals: globalVariables,
    private firestoreService: FirestoreHandleService
  ) {}

  setUserCourse(
    user: IUser,
    course: ICourse,
    createNew: boolean = false
  ): Observable<void> {
    if (course) {
      const userCourse = this._courseToUserCourse(course, createNew);
      return this.firestoreService.setDocument(
        `/users/${user.uid}/courses/${course.id}`,
        userCourse
      );
    }
  }

  private _courseToUserCourse(
    course: ICourse,
    createNew: boolean
  ): IUserCourse {
    const userCourse: IUserCourse = {} as IUserCourse;
    userCourse.topics = {};
    for (const topic in course.topics) {
      userCourse.topics[topic] = this._topicToUserTopic(
        course.topics[topic],
        createNew
      );
    }
    userCourse.type = LayerTypes.course;
    userCourse.progress = {
      done: false,
    };

    return userCourse;
  }

  private _topicToUserTopic(
    topic: ICourseTopic,
    createNew: boolean
  ): IUserTopic {
    const userTopic: IUserTopic = {} as IUserTopic;
    userTopic.lessons = {};
    for (const lesson in topic.lessons) {
      userTopic.lessons[lesson] = this._lessonToUserLesson(
        topic.lessons[lesson],
        createNew
      );
    }
    userTopic.type = LayerTypes.topic;
    userTopic.progress = {
      done: createNew ? false : topic.metaData.done,
    };

    return userTopic;
  }

  private _lessonToUserLesson(
    lesson: ICourseLesson,
    createNew: boolean
  ): IUserLesson {
    const userLesson: IUserLesson = {} as IUserLesson;
    userLesson.sections = {};
    for (const section in lesson.sections) {
      userLesson.sections[section] = this._sectionToUserSection(
        lesson.sections[section],
        createNew
      );
    }
    userLesson.type = LayerTypes.lesson;
    userLesson.progress = {
      done: createNew ? false : lesson.metaData.done,
    };

    return userLesson;
  }

  private _sectionToUserSection(
    section: ICourseSection,
    createNew: boolean
  ): IUserSection {
    const userSection: IUserSection = {} as IUserSection;
    userSection.type = LayerTypes.section;
    userSection.progress = {
      done: createNew ? false : section.metaData.done,
    };

    return userSection;
  }

  createNewUser(user: IUser): Observable<string> {
    const userDocumentPath = 'users/' + user.uid;
    return this.firestoreService.createDocument(userDocumentPath, user);
  }

  updateUser(user: IUser): Observable<void> {
    const userDocumentPath = '/users/' + user.uid;
    return this.firestoreService.updateDocument(userDocumentPath, user);
  }

  getUserInfo(uid: string): Observable<IUser> {
    const userDocumentPath = 'users/' + uid;
    return this.firestoreService.getDocument(userDocumentPath);
  }
}
