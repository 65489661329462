<h3
  class="form-title"
  mat-dialog-title
  i18n="
    new course page title | main title for create new
    course@@mainTitleForCreateNewCourse"
>
  <mat-icon class="title-icon">list_alt</mat-icon> Your Course Details
</h3>

<mat-dialog-content>
  <form class="create-course-form" [formGroup]="createCourseForm">
    <div class="form-wrapper">
      <div class="first-column">
        <div class="title controls">
          <label
            i18n="
              new course page course name title | create new course course name
              title@@createNewCourseCourseTitle"
            for="title"
            >1. pick a course title</label
          >
          <input
            class="controls-input"
            type="text"
            id="title"
            placeholder="course title"
            required
            minlength="3"
            maxlength="50"
            formControlName="title"
            i18n-placeholder
            [ngClass]="
              (createCourseForm.controls.title.touched &&
                createCourseForm.controls.title.invalid) ||
              formIsValidAfterSubmitted
                ? 'form-error-input'
                : ''
            "
          />
          <p
            class="form-error"
            *ngIf="
              (createCourseForm.controls.title.touched &&
                createCourseForm.controls.title.invalid) ||
              formIsValidAfterSubmitted
            "
          >
            Please enter course title.
          </p>
        </div>

        <div class="describe controls">
          <label
            i18n="
              new course page course description title | create new course
              course description title@@createNewCourseCourseDescription"
            for="describe"
            >2. describe your course</label
          >
          <textarea
            id="describe"
            maxlength="250"
            placeholder="write here..."
            i18n-placeholder
            rows="4"
            formControlName="description"
          ></textarea>
        </div>

        <div class="image controls">
          <label
            i18n="
              new course page course image url title | create new course course
              image url title@@createNewCourseCourseImage"
            for="courseImg"
            >3. choose a course image.</label
          >
          <div class="image-wrapper">
            <mat-icon class="hashTag">alternate_email</mat-icon>
            <input
              class="borderLess controls-input"
              #imageUrl
              i18n-placeholder
              type="text"
              id="courseImg"
              placeholder="Image Url"
              formControlName="image"
            />
          </div>
          <img
            class="selected-image"
            *ngIf="
              imageUrl &&
              createCourseForm.controls.image.touched &&
              createCourseForm.controls.image.value !== ''
            "
            src="{{ imageUrl.value }}"
            alt="Invalid Image"
          />
        </div>
      </div>

      <div class="second-column">
        <div class="course-level controls">
          <label
            i18n="
              new course page course course level title | create new course
              course course level needed title@@createNewCourseCourseLevel"
            for="level"
            >4. course level</label
          >
          <select class="controls-input" name="levels" id="level" required>
            <option *ngFor="let item of courseLevels" [ngValue]="item" i18n>
              {item, select, Beginner {Beginner} Intermediate {Intermediate}
              Expert {Expert} }
            </option>
            <!--                <option value="beginner" selected>Beginner</option>-->
            <!--                <option value="intermediate">Intermediate</option>-->
            <!--                <option value="expert">Expert</option>-->
          </select>
        </div>

        <div class="required-skills controls">
          <label
            i18n="
              new course page course course skill title | create new course
              course course skill needed title@@createNewCourseCourseSkill"
            for="skill"
            >5. required skills</label
          >
          <div class="required-skill-wrapper">
            <input
              class="controls-input"
              #skillType
              type="text"
              id="skill"
              placeholder="Example: 'Python'"
              i18n-placeholder
            />
            <select
              class="controls-input"
              #selectedLevel
              name="levels"
              required
            >
              <option *ngFor="let item of courseLevels" [ngValue]="item" i18n>
                {item, select, Beginner {Beginner} Intermediate {Intermediate}
                Expert {Expert} }
              </option>
              <!--                  <option value="beginner" selected>Beginner</option>-->
              <!--                  <option value="intermediate">Intermediate</option>-->
              <!--                  <option value="expert">Expert</option>-->
            </select>
            <a (click)="onAddCourseSkill(skillType.value, selectedLevel.value)">
              <mat-icon class="addBTN" color="primary">add_circle</mat-icon>
              <div class="icon-background"></div>
            </a>
          </div>
          <div *ngIf="requiredSkillsList">
            <div class="added_Skills" *ngFor="let item of requiredSkillsList">
              <mat-icon
                (click)="onCourseSkillRemove(item)"
                style="margin-right: 10px"
                color="warn"
                >remove_circle_outline</mat-icon
              >
              <p>
                <strong>{{ item.skill }} </strong>- {{ item.level }}
              </p>
            </div>
          </div>
        </div>

        <div class="language controls">
          <label
            i18n="
              new course page course course language title | create new course
              course course language needed title@@createNewCourseCourseLanguage"
            for="language"
            >6. course language
          </label>
          <select class="controls-input" name="language" id="language" required>
            <option *ngFor="let item of courseLanguage" [ngValue]="item" i18n>
              {item, select, English {English} Russian {Russian} Hebrew {Hebrew}
              }
            </option>

            <!--                <option value="english" selected>English</option>-->
            <!--                <option value="russian">Russian</option>-->
            <!--                <option value="hebrew">Hebrew</option>-->
          </select>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions class="create-form-action">
  <button
    (click)="onCancelClick()"
    i18n="
      new course page course create course button | create new course course
      create course button@@createNewCourseCourseBTN"
    class="form-btns"
    mat-button
  >
    Cancel
  </button>
  <button
    [disabled]="createCourseForm.invalid"
    i18n="
      new course page course create course button | create new course course
      create course button@@createNewCourseCourseBTN"
    class="create-btn form-btns"
    mat-button
    [mat-dialog-close]="createCourseForm"
  >
    Create
  </button>
</div>
