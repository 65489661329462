import { CourseStructureComponent } from './../../course/course-panel/components/course-structure/course-structure.component';
import { Observable } from 'rxjs';
import {
  CanDeactivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { CourseEditorComponent } from 'src/app/course/course-editor/course-editor.component';

export const canDeactivateGuard: CanDeactivateFn<
  CourseStructureComponent | CourseEditorComponent
> = (
  component: CourseStructureComponent | CourseEditorComponent,
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> | boolean => {
  if (!component.unsavedChangesService?.getUnsavedChanges()) {
    return true;
  }
  return component.unsavedChangesService.confirm();
};
