

<div class="landing-page-editor-container">
  <div class="landing-page-sidebar">
    <app-user-editor-landing-page-sidebar></app-user-editor-landing-page-sidebar>
  </div>
<!--  <button (click)="unSelectTemplate()"> choose Another Template</button>-->

  <div class="landing-page-editor-wrapper" style=" transform: scale(0.8);transform-origin:top center; position: relative; top: 90px;">

    <div class="header-section" [ngClass]="selectedSection.section_name === 'header'? 'selected-section' : 'not-selected'"
         (click)="onChangeSelectedSection('header')">
<!--    first template-->
    <div class="header-section-first" *ngIf="landingPageData.header.welcome.type === 'header_first_template'" style=" width: 100%">
      <div *ngIf="landingPageData.header.logo.is_visible" class="logo-section"
           [style]="{height: '64px', background:landingPageData.header.logo.style.background_color, alignItems: 'center',
        color: landingPageData.header.logo.style.text_color, display: 'flex',justifyContent: landingPageData.header.logo.style.alignment, padding: '0 100px'}">
        <p [ngClass]="selectedSection.section_name === 'header'? 'selected-section-elements' : ''"
          style="font-size: 30px; font-weight: 500; height: 50%; margin: 0">{{landingPageData.header.logo.text}}</p>
      </div>
      <div class="welcome-section" [style]="{ background: landingPageData.header.welcome.style.background_color,
            color: landingPageData.header.welcome.style.text_color, direction: landingPageData.header.welcome.style.direction,
            opacity: landingPageData.header.welcome.style.opacity, height: '100%'}">
        <div class="section-content" style="display: flex; flex-direction: row; justify-content: space-around; width: 100%; padding: 10px 100px">
          <div class="welcome-section-text" style="display: flex;flex-direction: column;justify-content: center; padding: 0 25px; width: 50%">
            <h1 [ngClass]="selectedSection.section_name === 'header'? 'selected-section-elements' : ''"
                style="font-size: 50px">{{landingPageData.header.welcome.text.title}}</h1>
            <h3 [ngClass]="selectedSection.section_name === 'header'? 'selected-section-elements' : ''"
              style="font-size: 30px; font-weight: 400">{{landingPageData.header.welcome.text.sub_title}}</h3>
          </div>
          <div class="welcome-secrion-image"  style=" display: flex; justify-content: center; align-items: center;padding: 0 25px; width: 50%">
            <img [ngClass]="selectedSection.section_name === 'header'? 'selected-section-elements' : ''"
                 style=" height: 60%; object-fit: cover; margin: 0 50px; padding: 5px"
                 [src]="landingPageData.header.welcome.image.image_url">
          </div>
        </div>
      </div>
    </div>
<!--    second template-->
    <div class="header-section-second" *ngIf="landingPageData.header.welcome.type === 'header_second_template'" style=" width: 100%">
      <div *ngIf="landingPageData.header.logo.is_visible" class="logo-section"
           [style]="{height: '64px', background:landingPageData.header.logo.style.background_color, alignItems: 'center',
        color: landingPageData.header.logo.style.text_color, display: 'flex',justifyContent: landingPageData.header.logo.style.alignment, padding: '0 100px'}">
        <p [ngClass]="selectedSection.section_name === 'header'? 'selected-section-elements' : ''"
           style="font-size: 30px; font-weight: 500; height: 50%; margin: 0">{{landingPageData.header.logo.text}}</p>
      </div>
      <div class="welcome-section" [style]="{ backgroundImage: landingPageData.header.welcome.style.background_url,
            color: landingPageData.header.welcome.style.text_color, direction: landingPageData.header.welcome.style.direction,
            opacity: landingPageData.header.welcome.style.opacity, height: '500px'}">
        <img [src]="landingPageData.header.welcome.style.background_url"
             style=" width: 100%; height: 100%; object-fit: cover; object-position: 100% 0%;">

      </div>
    </div>
<!--    third template-->
    <div class="header-section-third" *ngIf="landingPageData.header.welcome.type === 'header_third_template'" style=" width: 100%">
      <div *ngIf="landingPageData.header.logo.is_visible" class="logo-section"
           [style]="{height: '64px', background:landingPageData.header.logo.style.background_color, alignItems: 'center',
        color: landingPageData.header.logo.style.text_color, display: 'flex',justifyContent: landingPageData.header.logo.style.alignment, padding: '0 100px'}">
        <p [ngClass]="selectedSection.section_name === 'header'? 'selected-section-elements' : ''"
           style="font-size: 30px; font-weight: 500; height: 50%; margin: 0">{{landingPageData.header.logo.text}}</p>
      </div>
      <div class="welcome-section" [style]="{ backgroundImage: landingPageData.header.welcome.style.background_url,
            color: landingPageData.header.welcome.style.text_color, direction: landingPageData.header.welcome.style.direction,
            opacity: landingPageData.header.welcome.style.opacity, height: '640px', position: 'relative'}">
        <img [src]="landingPageData.header.welcome.style.background_url"
             style=" width: 100%; height: 100%; object-fit: cover; object-position: 100% 0%; position: absolute; z-index: -1">

          <div class="welcome-section-text" style="display: flex;flex-direction: column;justify-content: center;
              padding: 70px 100px; height: 100% ;text-align: center;">
            <h1 style="font-size: 55px">{{landingPageData.header.welcome.text.title}}</h1>
            <h3 style="font-size: 30px; font-weight: 400; margin: 0;">{{landingPageData.header.welcome.text.sub_title}}</h3>
          </div>
      </div>
    </div>

  </div>
    <div class="about-section" [ngClass]="selectedSection.section_name === 'about'? 'selected-section' : 'not-selected'"
    (click)="onChangeSelectedSection('about')">
<!--    first template-->
    <div class="about-section-first" *ngIf="landingPageData.about.type === 'about_first_template'"
         [style]="{width: '100%', background: landingPageData.about.style.background_color,
         color: landingPageData.about.style.text_color, height: '100%', display: 'flex', flexDirection:'column',
          alignItems: 'center', padding: '70px 100px', direction: landingPageData.about.style.direction,
          opacity: landingPageData.about.style.opacity}">
      <div class="section-title-center" style="position: relative">
        <div [style]="{height: '14px', background:landingPageData.about.style.accessorizes_color, width: '100%',position: 'absolute',
              top: '66px' , left: '0', zIndex: '1' }" class="accessorizes"></div>
        <h2 style="font-size: 40px; position: relative; z-index: 2">{{landingPageData.about.text.title}}</h2>
      </div>
        <p style="text-align: center; margin-top: 40px;margin-bottom: 30px;">{{landingPageData.about.text.paragraph}}</p>

    </div>
<!--    second template-->
    <div class="about-section-second" *ngIf="landingPageData.about.type === 'about_second_template'"
         [style]="{width: '100%', background: landingPageData.about.style.background_color,
         color: landingPageData.about.style.text_color, height: '420px', display: 'flex', flexDirection:'column',
          alignItems: 'center', padding: '70px 100px', direction: landingPageData.about.style.direction,
          opacity: landingPageData.about.style.opacity, position: 'relative'}">

      <div class="about-section-content" style="position: absolute; width: 70%; padding: 60px; background: white;
            text-align: center; top: -100px; box-shadow: 0 3px 6px 0 rgba(136, 136, 136, 0.5)">
        <div class="section-title-center">
          <h2 style="font-size: 45px">{{landingPageData.about.text.title}}</h2>
          <h4 style="font-size: 30px; font-weight: 400">{{landingPageData.about.text.sub_title}}</h4>
        <p style="text-align: center; margin-bottom: 30px">{{landingPageData.about.text.paragraph}}</p>
      </div>
    </div>

  </div>
    <!--    third template-->
    <div class="about-section-third" *ngIf="landingPageData.about.type === 'about_third_template'"
         [style]="{width: '100%', background: landingPageData.about.style.background_color,
         color: landingPageData.about.style.text_color, height: '100%', display: 'flex', flexDirection:'column',
          alignItems: 'center', padding: '80px 200px', direction: landingPageData.about.style.direction,
          opacity: landingPageData.about.style.opacity, position: 'relative'}">

      <div class="about-section-content" style="width: 100%; display: flex; flex-direction: row;  position: relative">
        <div class="image-content" style="width:46%">
          <div class="accessorizes" [style]="{height: '99%', width:' 41%', background: landingPageData.about.style.accessorizes_color,
          position: 'absolute', zIndex:' 1', left: '-15px', top: '16px'}">
          </div>
          <div style="position: relative; z-index: 2; ">
          <img style="height: 100%;width: 100%; object-fit: cover; object-position: 100% 0%" [src]="landingPageData.about.image.image_url">
            </div>
        </div>
        <div class="section-text-center" style="width: 65%; padding-left: 100px">
          <h2 style="font-size: 45px">{{landingPageData.about.text.title}}</h2>
          <p style="margin-bottom: 30px">{{landingPageData.about.text.paragraph}}</p>
        </div>

      </div>
    </div>
  </div>
    <div class="info-bullets" [ngClass]="selectedSection.section_name === 'info bullets'? 'selected-section' : 'not-selected'"
         (click)="onChangeSelectedSection('info-bullets')">
    <!--    first template-->
    <div class="info-bullets-section-first"  *ngIf="landingPageData.info_bullets.type === 'info_bullets_first_template'"
         [style]="{width: '100%', background: landingPageData.info_bullets.style.background_color,
          color: landingPageData.info_bullets.style.text_color, height: '620px', display: 'flex', flexDirection:'column',
          alignItems: 'center', padding: '70px 100px', opacity: landingPageData.info_bullets.style.opacity,
          direction: landingPageData.info_bullets.style.direction}">
      <div class="section-title-center" style="position: relative">
        <div [style]="{height: '14px', background:landingPageData.info_bullets.style.accessorizes_color, width: '100%',position: 'absolute',
              top: '66px' , left: '0', zIndex: '1' }" class="accessorizes"></div>
        <h2 style="font-size: 40px; position: relative; z-index: 2">{{landingPageData.info_bullets.title}}</h2>
      </div>

      <div class="bullets" style="display:flex; flex-direction: row; justify-content: space-between; width: 100%; margin-top: 60px;">

        <div class="first-bullet" style="display: flex; flex-direction: column; width: 25%; justify-content: center; align-items: center">
          <div class="icon-container" [style]="{background: landingPageData.info_bullets.style.accessorizes_color,padding: '20px',borderRadius: '50%'}">
            <img [src]="landingPageData.info_bullets.bullets['1'].icon_svg" style="height: 70px ">
          </div>
          <div class="text-container" style="text-align: center; margin: 20px;">
            <h3 style="margin: 0">{{landingPageData.info_bullets.bullets["1"].title}}</h3>
            <p style="margin: 0; padding-top: 10px">{{landingPageData.info_bullets.bullets["1"].text}}</p>
          </div>
        </div>

        <div class="second-bullet" style="display: flex; flex-direction: column; width: 25%; justify-content: center; align-items: center">
          <div class="icon-container" [style]="{background: landingPageData.info_bullets.style.accessorizes_color,padding: '20px',borderRadius: '50%'}">
            <img [src]="landingPageData.info_bullets.bullets['2'].icon_svg" style="height: 70px ">
          </div>
          <div class="text-container" style="text-align: center; margin: 20px;">
            <h3 style="margin: 0">{{landingPageData.info_bullets.bullets["2"].title}}</h3>
            <p style="margin: 0; padding-top: 10px">{{landingPageData.info_bullets.bullets["2"].text}}</p>
          </div>
        </div>

        <div class="third-bullet" style="display: flex; flex-direction: column; width: 25%; justify-content: center; align-items: center">
          <div class="icon-container" [style]="{background: landingPageData.info_bullets.style.accessorizes_color,padding: '20px',borderRadius: '50%'}">
            <img [src]="landingPageData.info_bullets.bullets['3'].icon_svg" style="height: 70px ">
          </div>
          <div class="text-container" style="text-align: center; margin: 20px;">
            <h3 style="margin: 0">{{landingPageData.info_bullets.bullets["3"].title}}</h3>
            <p style="margin: 0; padding-top: 10px">{{landingPageData.info_bullets.bullets["3"].text}}</p>
          </div>
        </div>

        <div class="four-bullet" style="display: flex; flex-direction: column; width: 25%; justify-content: center; align-items: center">
          <div class="icon-container" [style]="{background: landingPageData.info_bullets.style.accessorizes_color,padding: '20px',borderRadius: '50%'}">
            <img [src]="landingPageData.info_bullets.bullets['4'].icon_svg" style="height: 70px ">
          </div>
          <div class="text-container" style="text-align: center; margin: 20px;">
            <h3 style="margin: 0">{{landingPageData.info_bullets.bullets["4"].title}}</h3>
            <p style="margin: 0; padding-top: 10px">{{landingPageData.info_bullets.bullets["4"].text}}</p>
          </div>
        </div>
      </div>
    </div>
    <!--    second template-->
    <div class="info-bullets-section-second"  *ngIf="landingPageData.info_bullets.type === 'info_bullets_second_template'"
         [style]="{width: '100%', background: landingPageData.info_bullets.style.background_color,
          color: landingPageData.info_bullets.style.text_color, height: '100%', display: 'flex', flexDirection:'column',
          alignItems: 'center', padding: '70px 100px', opacity: landingPageData.info_bullets.style.opacity,
          direction: landingPageData.info_bullets.style.direction}">
      <div class="section-title-center" style="position: relative">
        <div [style]="{height: '14px', background:landingPageData.info_bullets.style.accessorizes_color, width: '100%',position: 'absolute',
              top: '66px' , left: '0', zIndex: '1' }" class="accessorizes"></div>
        <h2 style="font-size: 40px; position: relative; z-index: 2">{{landingPageData.info_bullets.title}}</h2>
      </div>


      <div class="bullets" style="display:flex; flex-direction: row; justify-content: space-between; width: 100%; margin-top: 40px;">

        <div class="first-bullet" style="display: flex; flex-direction: row; width: 25%;">
          <div class="icon-container">
            <h1 [style]="{color: landingPageData.info_bullets.style.accessorizes_color, fontSize: '140px', fontWeight:800, margin: '0' }">
              {{landingPageData.info_bullets.bullets["1"].icon_svg}}</h1>
          </div>
          <div class="text-container" style=" margin: 50px 20px 20px;">
            <h3 style="margin: 0">{{landingPageData.info_bullets.bullets["1"].title}}</h3>
            <p style="margin: 0; padding-top: 10px">{{landingPageData.info_bullets.bullets["1"].text}}</p>
          </div>
        </div>
        <div class="second-bullet" style="display: flex; flex-direction: row; width: 25%;">
          <div class="icon-container">
            <h1 [style]="{color: landingPageData.info_bullets.style.accessorizes_color, fontSize: '140px', fontWeight:800, margin: '0' }">
              {{landingPageData.info_bullets.bullets["2"].icon_svg}}</h1>
          </div>
          <div class="text-container" style=" margin: 50px 20px 20px;">
            <h3 style="margin: 0">{{landingPageData.info_bullets.bullets["2"].title}}</h3>
            <p style="margin: 0; padding-top: 10px">{{landingPageData.info_bullets.bullets["2"].text}}</p>
          </div>
        </div>
        <div class="third-bullet" style="display: flex; flex-direction: row; width: 25%;">
          <div class="icon-container">
            <h1 [style]="{color: landingPageData.info_bullets.style.accessorizes_color, fontSize: '140px', fontWeight:800, margin: '0' }">
              {{landingPageData.info_bullets.bullets["3"].icon_svg}}</h1>
          </div>
          <div class="text-container" style=" margin: 50px 20px 20px;">
            <h3 style="margin: 0">{{landingPageData.info_bullets.bullets["3"].title}}</h3>
            <p style="margin: 0; padding-top: 10px">{{landingPageData.info_bullets.bullets["3"].text}}</p>
          </div>
        </div>
        <div class="four-bullet" style="display: flex; flex-direction: row; width: 25%;">
          <div class="icon-container">
            <h1 [style]="{color: landingPageData.info_bullets.style.accessorizes_color, fontSize: '140px', fontWeight:800, margin: '0' }">
              {{landingPageData.info_bullets.bullets["4"].icon_svg}}</h1>
          </div>
          <div class="text-container" style=" margin: 50px 20px 20px;">
            <h3 style="margin: 0">{{landingPageData.info_bullets.bullets["4"].title}}</h3>
            <p style="margin: 0; padding-top: 10px">{{landingPageData.info_bullets.bullets["4"].text}}</p>
          </div>
        </div>

      </div>
    </div>
    <!--    third template-->
    <div class="info-bullets-section-first"  *ngIf="landingPageData.info_bullets.type === 'info_bullets_third_template'"
         [style]="{width: '100%', background: landingPageData.info_bullets.style.background_color,
          color: landingPageData.info_bullets.style.text_color, height: '620px', display: 'flex', flexDirection:'column',
          alignItems: 'center', padding: '70px 100px', opacity: landingPageData.info_bullets.style.opacity,
          direction: landingPageData.info_bullets.style.direction}">
      <div class="section-title-center">
        <h2 style="font-size: 40px">{{landingPageData.info_bullets.title}}</h2>
      </div>

      <div class="bullets" style="display:flex; flex-direction: row; justify-content: space-between; width: 100%; margin-top: 60px; position: relative">

        <div class="first-bullet" style="display: flex; flex-direction: column; width: 25%; justify-content: center; align-items: center;">
          <div [style]="{height: '103px', width: '103px',position: 'absolute',top: '11px' , left: '113px', zIndex: '1' }"
               [ngStyle]="{'background-color':landingPageData.info_bullets.style.background_color==='#ffffff'? '#3C3C3C':landingPageData.info_bullets.style.accessorizes_color}" class="accessorizes"></div>
          <div class="icon-container" [style]="{background: landingPageData.info_bullets.style.accessorizes_color,padding: '15px',
          position: 'relative', zIndex: '2'}"
               [ngStyle]="{'background-color': landingPageData.info_bullets.style.background_color==='#ffffff'? landingPageData.info_bullets.style.accessorizes_color:'white'}">
            <img [src]="landingPageData.info_bullets.bullets['1'].icon_svg" style="height: 70px ">
          </div>
          <div class="text-container" style="text-align: center; margin:  50px 20px 20px;">
            <h3 style="margin: 0">{{landingPageData.info_bullets.bullets["1"].title}}</h3>
            <p style="margin: 0; padding-top: 10px">{{landingPageData.info_bullets.bullets["1"].text}}</p>
          </div>
        </div>
        <div class="second-bullet" style="display: flex; flex-direction: column; width: 25%; justify-content: center; align-items: center; position: relative">
          <div [style]="{height: '103px', width: '103px',position: 'absolute',
              top: '11px' , left: '113px', zIndex: '1' }" [ngStyle]="{'background-color':landingPageData.info_bullets.style.background_color==='#ffffff'?'#3C3C3C':landingPageData.info_bullets.style.accessorizes_color}" class="accessorizes"></div>
          <div class="icon-container" [style]="{background: landingPageData.info_bullets.style.accessorizes_color,padding: '15px',
          position: 'relative', zIndex: '2', background: 'white'}"
               [ngStyle]="{'background-color': landingPageData.info_bullets.style.background_color==='#ffffff'? landingPageData.info_bullets.style.accessorizes_color:'white'}">
            <img [src]="landingPageData.info_bullets.bullets['2'].icon_svg" style="height: 70px ">
          </div>
          <div class="text-container" style="text-align: center; margin:  50px 20px 20px;">
            <h3 style="margin: 0">{{landingPageData.info_bullets.bullets["2"].title}}</h3>
            <p style="margin: 0; padding-top: 10px">{{landingPageData.info_bullets.bullets["2"].text}}</p>
          </div>
        </div>
        <div class="third-bullet" style="display: flex; flex-direction: column; width: 25%; justify-content: center; align-items: center; position: relative">
          <div [style]="{height: '103px', width: '103px',position: 'absolute',
              top: '11px' , left: '113px', zIndex: '1' }" [ngStyle]="{'background-color':landingPageData.info_bullets.style.background_color==='#ffffff'?
               '#3C3C3C':landingPageData.info_bullets.style.accessorizes_color}" class="accessorizes"></div>
          <div class="icon-container" [style]="{background: landingPageData.info_bullets.style.accessorizes_color,padding: '15px',
          position: 'relative', zIndex: '2', background: 'white'}"
               [ngStyle]="{'background-color': landingPageData.info_bullets.style.background_color==='#ffffff'? landingPageData.info_bullets.style.accessorizes_color:'white'}">
            <img [src]="landingPageData.info_bullets.bullets['3'].icon_svg" style="height: 70px ">
          </div>
          <div class="text-container" style="text-align: center; margin:  50px 20px 20px;">
            <h3 style="margin: 0">{{landingPageData.info_bullets.bullets["3"].title}}</h3>
            <p style="margin: 0; padding-top: 10px">{{landingPageData.info_bullets.bullets["3"].text}}</p>
          </div>
        </div>
        <div class="four-bullet" style="display: flex; flex-direction: column; width: 25%; justify-content: center; align-items: center; position: relative">
          <div [style]="{height: '103px', width: '103px',position: 'absolute',
              top: '11px' , left: '113px', zIndex: '1' }" [ngStyle]="{'background-color':landingPageData.info_bullets.style.background_color==='#ffffff'?
               '#3C3C3C':landingPageData.info_bullets.style.accessorizes_color}" class="accessorizes"></div>
          <div class="icon-container" [style]="{background: landingPageData.info_bullets.style.accessorizes_color,padding: '15px',
          position: 'relative', zIndex: '2', background: 'white'} "
               [ngStyle]="{'background-color': landingPageData.info_bullets.style.background_color==='#ffffff'? landingPageData.info_bullets.style.accessorizes_color:'white'}">
            <img [src]="landingPageData.info_bullets.bullets['4'].icon_svg" style="height: 70px ">
          </div>
          <div class="text-container" style="text-align: center; margin:  50px 20px 20px;">
            <h3 style="margin: 0">{{landingPageData.info_bullets.bullets["4"].title}}</h3>
            <p style="margin: 0; padding-top: 10px">{{landingPageData.info_bullets.bullets["4"].text}}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
    <div class="courses-section" [ngClass]="selectedSection.section_name === 'courses'? 'selected-section' : 'not-selected'"
         (click)="onChangeSelectedSection('courses')">
      <!--      first template-->
      <div class="courses-section-first" style="width: 100%}" *ngIf="landingPageData.courses.courses_cards.type === 'courses_lengthwise'">
        <div class="course-cards-section" [style]="{background: landingPageData.courses.courses_cards.style.background_color,
          color: landingPageData.courses.courses_cards.style.text_color, height: '100%', display: 'flex', flexDirection:'column',
          alignItems: 'center', direction: landingPageData.courses.courses_cards.style.direction,
          opacity: landingPageData.courses.courses_cards.style.opacity, padding: '70px 100px' }">
          <div class="section-title-center" style="position: relative; margin-bottom: 30px;">
          <div [style]="{height: '14px', background:landingPageData.courses.courses_cards.style.accessorizes_color, width: '100%',position: 'absolute',
              top: '66px' , left: '0', zIndex: '1' }" class="accessorizes"></div>
          <h2 style="font-size: 40px; position: relative; z-index: 2">{{landingPageData.info_bullets.title}}</h2>
        </div>

          <div *ngFor="let card of landingPageData.courses.courses_cards.course_card  | keyvalue"
               class="course-cards" style="display: flex; flex-direction: row; background: white;
      box-shadow:0 1px 3px 0 rgba(136, 136, 136, 0.5); border-radius: 10px; width: 100% ; padding: 30px; margin-bottom: 30px ">
            <div class="course-card-image" style="width: 40%">
              <img [src]="landingPageData.courses.courses_cards.course_card[card.key].image_url " style="width: 440px;
          height: 250px; object-fit: cover">
            </div>
            <div class="course-card-text" style="width: 60%; padding-left: 20px; display: flex; flex-direction: column;
                                      justify-content: center;">
              <h2 style="font-size: 38px;  text-transform: uppercase; margin: 0;">
                {{landingPageData.courses.courses_cards.course_card[card.key].title}}</h2>
              <p style="font-size: 18px; color: #BFBFBF">
                {{landingPageData.courses.courses_cards.course_card[card.key].sub_title}}</p>
              <p>{{landingPageData.courses.courses_cards.course_card[card.key].paragraph}}</p>

            </div>
          </div>
        </div>
        <div class="footer-section" *ngIf="landingPageData.courses.footer.is_visible"
             [style]="{background: landingPageData.courses.footer.style.background_color,
          color: landingPageData.courses.footer.style.text_color, height: '100%', padding: '10px 100px',
          textAlign: landingPageData.courses.footer.style.alignment }">
          <p>{{landingPageData.courses.footer.text}}</p>
        </div>
      </div>
      <!--      second template-->
      <div class="courses-section-second" style="width: 100%}" *ngIf="landingPageData.courses.courses_cards.type === 'courses_widthwise'">
        <div class="course-cards-section" [style]="{background: landingPageData.courses.courses_cards.style.background_color,
          color: landingPageData.courses.courses_cards.style.text_color, height: '100%', display: 'flex', flexDirection:'column',
          alignItems: 'center', direction: landingPageData.courses.courses_cards.style.direction,
          opacity: landingPageData.courses.courses_cards.style.opacity, padding: '70px 100px 20px' }">
          <div class="section-title-center" style="position: relative; margin-bottom: 30px;">
            <h2 style="font-size: 40px; position: relative; z-index: 2">{{landingPageData.info_bullets.title}}</h2>
          </div>

          <div class="cards-container" style="display:flex; flex-direction: row; flex-wrap: wrap; width: 100%; justify-content: space-evenly;">
            <div *ngFor="let card of landingPageData.courses.courses_cards.course_card  | keyvalue"
                 class="course-cards" style="display: flex; flex-direction: column; align-items: center; background: white;
                  box-shadow:0 1px 3px 0 rgba(136, 136, 136, 0.5); border-radius: 10px; width:31% ;
                  height: 100%; padding: 20px; margin-bottom: 50px; ">
              <div class="course-card-image" style="width: 100%">
                <img [src]="landingPageData.courses.courses_cards.course_card[card.key].image_url " style="width: 100%;
                height: 180px; object-fit: cover">
              </div>
              <div class="course-card-text" style="width: 90%; padding: 20px 0 0; display: flex; flex-direction: column;
                                      justify-content: center; text-align: center">
                <h2 style="font-size: 30px;  text-transform: uppercase; margin: 0;">
                  {{landingPageData.courses.courses_cards.course_card[card.key].title}}</h2>
                <p style="font-size: 18px; color: #BFBFBF">
                  {{landingPageData.courses.courses_cards.course_card[card.key].sub_title}}</p>
                <div class="line" style="margin: 10px 0; height: 1px; width: 100%; background: #BFBFBF"></div>
                <p>{{landingPageData.courses.courses_cards.course_card[card.key].paragraph}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-section" *ngIf="landingPageData.courses.footer.is_visible"
             [style]="{background: landingPageData.courses.footer.style.background_color,
          color: landingPageData.courses.footer.style.text_color, height: '100%', padding: '10px 100px',
          textAlign: landingPageData.courses.footer.style.alignment }">
          <p>{{landingPageData.courses.footer.text}}</p>
        </div>
      </div>

    </div>
  </div>
</div>
