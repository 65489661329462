
<div class="user-settings-container  position-center">
  <div class="user-settings-wrapper">
    <div class="course-title-wrapper">
      <h1 i18n="user settings page title|user settings page title@@userSettingsPageMainTitle">USER SETTINGS</h1>
      <div class="go-back-btn">
        <a routerLink="dashboard" i18n="go back to hive btn| go back to hive button@@goBackToHive">
          <mat-icon *ngIf="!isHebrew">arrow_back</mat-icon>
          <mat-icon *ngIf="isHebrew">arrow_forward</mat-icon>
          back to the HIVE</a>
      </div>
    </div>

    <div class="user-settings-content">
      <p-tabView>

        <p-tabPanel header="User Profile"  [selected]="true">
          <div class="user-profile-content">
            <h3 class="subtitle-user-settings">Basic info</h3>
            <div class="first-raw">
              <div class="first-column">
                <div class="user-first-name">
                  <label for="user-first-name">your first name</label>
                  <input id="user-first-name" type="text"  [(ngModel)]="currentUser.name">
                </div>

              </div>
              <div class="second-column">
                <label>your photo</label>
                <div class="user-image">
                  <div class="user-image-url">
                    <img *ngIf="currentUser.image" (click)="uploadNewImg()"  src="{{currentUser.image}}" alt="user image">
                    <img *ngIf="!currentUser.image"  (click)="uploadNewImg()" src="src/assets/user_img_deafult.png" alt="user image">
                  </div>
                 <div class="user-image-a">
                   <a (click)="uploadNewImg()" class="upload-image-a">upload new photo</a>
                   <a (click)="removeImg()" class="remove-image-a">remove your photo</a>
                 </div>
                </div>
              </div>
            </div>
              <div class="second-raw">
                <label for="about-user-info">About you</label>
                <textarea id="about-user-info" rows="10" placeholder="Write here"  [(ngModel)]="currentUser.description"></textarea>
              </div>

          </div>
        </p-tabPanel>

        <p-tabPanel header="Payment">
          <div class="payment-content">
            <h3 class="subtitle-user-settings">Payment Methods</h3>
            <div class="first-raw-payment">
              <p class="first-raw-p">You don’t have any payment methods in your account yet.</p>
              <button (click)="onAddPaymentMethod()" class="user-setting-btn">
                <span class="material-icons-outlined add-icon">add</span>
                Add Payment Method</button>
            </div>
          </div>
        </p-tabPanel>

        <p-tabPanel header="Notifications">
          <div class="notifications-content">
            <h3 class="subtitle-user-settings">Do Not Disturb Schedule</h3>
            <div class="first-raw-notifications">
              <input class="checkbox-notifications" type="checkbox" id="timeNotToDisturb" name="timeNotToDisturb" value="timeNotToDisturb">
              <label for="timeNotToDisturb">Do not notify me from:
                <input class="notifications-input doNotDisturb-input" type="text" placeholder="17:00">
                To:
                <input class="notifications-input doNotDisturb-input" type="text" placeholder="08:00">
              </label>
            </div>
            <div class="second-raw-notification">
              <p class="second-raw-p">Do not disturb me on my days off:</p>
              <div class="days-off">
                <p-selectButton [options]="days" (onChange)="addSelectedDays($event.value)" optionValue="value"
                                optionLabel="name"  multiple="multiple"></p-selectButton>
              </div>
            </div>
            <div class="third-raw-notifications">
              <h3 class="subtitle-notifications">Email notifications</h3>

              <div class="checkbox-container">
                <input class="checkbox-email-notifications" type="checkbox" id="first-notification" name="first-notification" value="first-notification">
                <label for="first-notification">Lorem ipsum dolor sit amet, consectetur adipiscing elit,</label>
              </div>
             <div class="checkbox-container">
               <input class="checkbox-email-notifications" type="checkbox" id="second-notification" name="second-notification" value="second-notification">
               <label for="second-notification">Lorem ipsum dolor sit amet, consectetur adipiscing elit,</label>
             </div>
              <div class="checkbox-container">
                <input class="checkbox-email-notifications" type="checkbox" id="third-notification" name="third-notification" value="third-notification">
                <label for="third-notification">Lorem ipsum dolor sit amet, consectetur adipiscing elit,</label>
                <br>
              </div>

            </div>
          </div>
        </p-tabPanel>

        <p-tabPanel header="Landing Page">
          <div class="langind-page-content">
            <h3 class="subtitle-user-settings">Your Landing Page</h3>
            <div class="first-raw-landing-page">
              <p class="first-raw-p">You don’t have any landing page.</p>
              <button (click)="onAddLandingPage()" class="user-setting-btn">
                <span class="material-icons-outlined add-icon">add</span>
                create landing page</button>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
