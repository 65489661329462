<div *ngIf="currentUser" class="header-container">
  <span class="logo" routerLink="/courses"
    ><span class="logoThin">Skill</span>BEE</span
  >
  <a
    *ngIf="currentRoute === '/courses'"
    i18n="create new course| create new course button@@createNewCourse"
    class="newCourse"
    (click)="openCreateCourseDialog()"
  >
    <mat-icon class="iconAdd" aria-hidden="false">add</mat-icon>
    CREATE A NEW COURSE</a
  >
  <span class="header-spacer"></span>
  <button
    *ngIf="canSave"
    class="save-btn"
    [disabled]="isSaving"
    (click)="onSave()"
  >
    {{ getSave() }}
  </button>
  <p class="line-spacer line-he">|</p>
  <div class="user-info-wrapper">
    <ul class="user-info" (click)="$event.stopPropagation()">
      <li class="messages">
        <a (click)="toggleHeaderMenus('messages')">
          <mat-icon
            aria-hidden="false"
            matBadge="5"
            matBadgeColor="accent"
            class="header-icon"
            color="primary"
          >
            chat_bubble_outline
          </mat-icon>
        </a>
        <ul *ngIf="messagesIcon" class="dropdown dropdown-messages">
          <li>
            <h3
              i18n="messagesTitle| header massages title@@ headerMessagesTitle"
            >
              Messages
            </h3>
          </li>
          <li>
            <p
              i18n="
                messagesDescription| header massages description@@
                headerMessagesDescription"
            >
              No New Messages
            </p>
          </li>
        </ul>
      </li>
      <li class="notifications">
        <a (click)="toggleHeaderMenus('notifications')">
          <mat-icon
            aria-hidden="false"
            matBadge="5"
            matBadgeColor="warn"
            class="header-icon"
            color="primary"
          >
            notifications_none
          </mat-icon>
        </a>
        <ul *ngIf="notificationsIcon" class="dropdown dropdown-notifications">
          <h3
            i18n="
              NotificationsTitle| headerNotifications title@@
              headerNotificationsTitle"
          >
            Notifications
          </h3>
          <p
            i18n="
              NotificationsDescription| header Notifications description@@
              headerNotificationsDescription"
          >
            No New Notifications
          </p>
        </ul>
      </li>
      <li>
        <p class="line-spacer">|</p>
      </li>
      <li class="user" *ngIf="currentUser">
        <a (click)="toggleHeaderMenus('userMenu')">
          <img
            class="userImg"
            src="{{ currentUser.image }}"
            alt="{{ currentUser.name }}"
          />
        </a>
        <ul *ngIf="userIcon" class="dropdown dropdown-user">
          <li>
            <div class="user-data">
              <img
                src="{{ currentUser.image }}"
                alt="{{ currentUser.name }}"
              />
              <div class="user-name-email">
                <h4>{{ currentUser.name }}</h4>
                <h4 *ngIf="!currentUser.name">Hello, user</h4>
                <h5>{{ currentUser.email }}</h5>
              </div>
            </div>
          </li>
          <div class="change-language-raw">
            <ul class="hover-nav user-nav language-raw">
              <span class="material-icons-outlined arrow-icons"
                >arrow_back_ios</span
              >
              <li>
                <a
                  ><span class="material-icons-outlined language-icons"
                    >language</span
                  >{{ selectedLanguage }}</a
                >
              </li>
            </ul>
            <ul class="language-list">
              <li
                (click)="changeLanguage(lung)"
                class="language-item"
                *ngFor="let lung of languageList"
              >
                {{ lung }}
              </li>
            </ul>
          </div>
          <ul class="hover-nav user-nav">
            <li (click)="onNavigateClick('/settings')">
              <a>Settings</a>
            </li>
            <li (click)="onNavigateClick('/inbox')"><a>Inbox</a></li>
            <li (click)="onNavigateClick('/courses')"><a>Courses</a></li>
          </ul>
          <ul class="hover-nav user-nav">
            <li (click)="showTerms()"><a>Terms and conditions</a></li>
          </ul>

          <ul class="hover-nav user-nav logout-raw" (click)="logOut()">
            <li>
              <a
                ><span class="material-icons-outlined loguot-icon">logout</span
                >LOG OUT</a
              >
            </li>
          </ul>
        </ul>
      </li>
    </ul>
  </div>
</div>
