import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserLandingPageModel} from './user-landing-page.model';
import {UserLandingPageService} from './user-landing-page.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-landing-page',
  templateUrl: './user-landing-page.component.html',
  styleUrls: ['./user-landing-page.component.scss']
})
export class UserLandingPageComponent implements OnInit, OnDestroy {

  landingPageData: UserLandingPageModel;
  landingPageDataSub: Subscription;


  constructor(
              private  userLandingPageService: UserLandingPageService,) { }

  ngOnInit(): void {

    this.landingPageDataSub = this.userLandingPageService.selectedLandingPage.subscribe( data => {
      this.landingPageData = data;
    })


  }

  ngOnDestroy(): void {
    this.landingPageDataSub.unsubscribe();
  }


}
