import {
  Component,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
} from '@angular/core';
import { UserRole } from '../interfaces-enums/user-role.enum';
import { StructureViewOptions } from 'src/app/course/interfaces-enums/structure-view-options.enum';
import { CourseStructureService } from 'src/app/course/course-panel/components/course-structure/course-structure.service';
import { ICourse } from 'src/app/course/interfaces-enums/course';

@Component({
  selector: 'page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {
  private _pageName = '';
  @Input() set pageName(val: string) {
    this._pageName = val;
  }
  get pageName() {
    return this._pageName;
  }

  @Input() backToRoute = '';
  @Input() backToLocation = '';
  @Input() course: ICourse;
  @Input() userRule: UserRole;
  @Input() onStudentView = false;
  @Input() showIcons = false;
  UserRole = UserRole;
  isHebrew: boolean = false;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private structureService: CourseStructureService
  ) {}

  ngOnInit() {
    if (this.locale === 'he') {
      this.isHebrew = true;
    }
  }

  @HostListener('click', ['$event'])
  onPanelClick(event: MouseEvent) {
    if (!this.structureService.getCanEdit()) {
      this.structureService.closeEdit();
    }
  }

  onStructureViewChange(view: string) {
    this.structureService.structureViewControl(<StructureViewOptions>view);
  }
}
