<div class="sidebar-container" *ngIf="items && !onStudentView">
  <div class="sidebar-elements">
    <div
      class="sidebar-list"
      [ngClass]="{ active: rla.isActive }"
      *ngFor="let item of items"
    >
      <div class="list-item">
        <a
          routerLink="{{ item.itemPath }}"
          routerLinkActive
          #rla="routerLinkActive"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <mat-icon
            class="sidebar-icons"
            aria-hidden="false"
            [aria-label]="item.itemName"
            [fontIcon]="item.itemIcon"
            ><div class="side-line" *ngIf="rla.isActive"></div
          ></mat-icon>

          <span>{{ item.itemName }}</span>
        </a>
        <div *ngIf="rla.isActive" class="arrow-right"></div>
      </div>
    </div>
  </div>
</div>
