import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserRole } from 'src/app/shared/interfaces-enums/user-role.enum';
import { COURSE_MENU_CONFIG } from 'src/app/shared/sidebar-hive/config/menu.config';
import { CourseService } from '../course.service';
import { ICourse } from '../interfaces-enums/course';
import { UnsavedChangesService } from 'src/app/shared/unsaved-changes/unsaved-changes.service';

@Component({
  selector: 'app-course-panel',
  templateUrl: './course-panel.component.html',
  styleUrls: ['./course-panel.component.scss'],
})
export class CoursePanelComponent implements OnInit, OnDestroy {
  COURSE_MENU = COURSE_MENU_CONFIG;
  course: ICourse;
  courseSub: Subscription;
  courseID: string;
  pageNameSub: Subscription;
  pageName: string;
  userRule: UserRole;
  onStudentView = false;

  constructor(
    private router: Router,
    private courseService: CourseService,
    public unsavedChangesService: UnsavedChangesService
  ) {
    this.pageNameSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.pageName = this.getTitle(
          this.router.routerState,
          this.router.routerState.root
        ).join('-');
      });
  }

  ngOnInit(): void {
    this.courseSub = this.courseService.courseSub.subscribe((course) => {
      this.course = course;
      this.courseID = this.courseService.getCourseID();
      this.userRule = this.courseService.isUserTeacher();
      this.onStudentView = this.courseService.onStudentView;
    });
    this.unsavedChangesService.resetCourse.subscribe((data) => {
      if (data) {
        this.courseService.setCourseToCourseFromDB();
      }
    });
  }

  private getTitle(state: any, parent: any): string[] {
    const data = [];
    if (parent?.snapshot?.data?.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }

  ngOnDestroy(): void {
    this.courseSub?.unsubscribe();
    this.pageNameSub?.unsubscribe();
    this.courseService.onStudentView = false;
  }
}
