// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};
export const firebaseConfig = {
  apiKey: 'AIzaSyA5hrZQ2iW-9WsBp71bzZjkKK0hWtqtvzQ',
  authDomain: 'skillbee-296903.firebaseapp.com',
  databaseURL: 'https://skillbee-296903-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'skillbee-296903',
  storageBucket: 'skillbee-296903.appspot.com',
  messagingSenderId: '1054170784184',
  appId: '1:1054170784184:web:9b0fddb5c517a4d4166f4d',
  measurementId: 'G-5S5T5S1DNK'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
