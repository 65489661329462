<div class="code-gallery-wrapper">
  <div class="code-gallery">
    <div class="code-gallery-header">
      <h3>Select Code Element</h3>
      <span (click)="closePopUp()" class="material-icons-outlined closeIcon">close</span>
    </div>
    <div class="code-gallery-content">
      <div class="code-gallery-languages">
        <ul class="code-languages" *ngFor="let codeType of languageList">
          <li (click)="selectLang(codeType)" class="single-language">
            <a>{{codeType.lang}}</a>
            <span class="material-icons-outlined arrowIcon">arrow_right</span>
          </li>
        </ul>
      </div>

      <div class="code-gallery-menu-wrapper" *ngIf="selectedLang">
        <div class="code-gallery-menu"*ngFor="let panel of selectedLang.panels">
            <form [formGroup]="codePanelForm">
              <app-code-gallery-panel *ngIf="selectedLang && codePanelForm"
                                      [panelConfig]="panel"
                                      [panelFormGroup]="codePanelForm"></app-code-gallery-panel>
            </form>
        </div>
      </div>
    </div>
    <div class="code-gallery-footer">
      <div class="code-gallery-btns">
        <button class="cancel-btn" (click)="closePopUp()">Cancel</button>
        <button class="choose-btn" (click)="closePopUp()">Choose</button>
      </div>
    </div>
  </div>
</div>

