import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CourseService } from 'src/app/course/course.service';
import { ICourse } from 'src/app/course/interfaces-enums/course';

@Component({
  selector: 'app-course-settings',
  templateUrl: './course-settings.component.html',
  styleUrls: ['./course-settings.component.scss'],
})
export class CourseSettingsComponent implements OnInit, OnDestroy {
  currentCourse: ICourse;
  courseSub: Subscription;

  constructor(private courseService: CourseService) {}

  ngOnInit(): void {
    this.courseSub = this.courseService.courseSub.subscribe((course) => {
      this.currentCourse = course;
    });
  }
  ngOnDestroy() {
    this.courseSub.unsubscribe();
  }

  renameCourse(newName: string) {
    this.courseService
      .renameCourse(this.currentCourse.id, newName)
      .then(() => {});
  }

  changeImage(imageUrl: string) {
    this.courseService
      .changeCourseImage(this.currentCourse.id, imageUrl)
      .then(() => {});
  }
}
