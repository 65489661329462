import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HivePopupService} from '../hive-popup.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-gallery-popup-template',
  templateUrl: './gallery-popup-template.component.html',
  styleUrls: ['./gallery-popup-template.component.scss']
})
export class GalleryPopupTemplateComponent implements OnInit, OnDestroy {
  @Input() rightButton: string;
  @Input() leftButton: string;
  @Input() title: string;
  @Output() onNext: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentResult: any
  currentResultSub: Subscription;

  constructor(private popupService: HivePopupService) { }

  ngOnInit(): void {
    if(!this.rightButton){
      this.rightButton = "Done"
    }
    if(!this.leftButton){
      this.leftButton = "Cancel"
    }

    this.currentResultSub = this.popupService.currentResult.subscribe(data =>{
      this.currentResult = data;
    })
  }


  closePopUp(result?: boolean){
    if(this.currentResult){
      this.popupService.finishPopupResult(this.currentResult)
    }
    else{
      this.popupService.finishPopupResult(result? result:false)
    }
  }


  getRightButtonFunction(){
    if(this.rightButton === 'Done'){
      return this.closePopUp(true)
    }
    else if(this.rightButton === 'Next'){
      this.onNext.emit(true)
    }
  }

  getLeftButtonFunction(){
    if(this.leftButton === 'Cancel'){
      return this.closePopUp(false)
    }
    else if(this.leftButton === "Previous"){
      this.onNext.emit(false)
    }
  }

  ngOnDestroy(): void {
    this.currentResultSub.unsubscribe();
  }

}
