import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EditorCanvasService } from './editor-canvas/editor-canvas.service';
import { CourseService } from '../course.service';
import { IElement } from './element-content-types/interfaces-enums/IElement';
import { ICourse, ICourseSection } from '../interfaces-enums/course';
import { NodePath } from './editor-sidebar/interfaces-enums/node-tree';
import { Router } from '@angular/router';
import {ElementActionsService} from './element-actions/element-actions.service';

@Injectable({ providedIn: 'root' })
export class CourseEditorService {
  containerHeight: BehaviorSubject<number> = new BehaviorSubject<number>(
    window.innerHeight
  );

  selectedSection: BehaviorSubject<ICourseSection> = new BehaviorSubject<ICourseSection>(null);

  copiedElement: IElement;
  copiedElementsList = [];

  pageHeight = document.documentElement.scrollHeight - 64;
  pageWidth = document.body.clientWidth - 300;

  constructor(
    private courseService: CourseService,
    private router: Router,
    private canvasService: EditorCanvasService
  ) {}

  getSelectedSection(): ICourseSection {
    return this.selectedSection.value;
  }

  setContainerHeight(height: number): void {
    this.containerHeight.next(height);
  }

  getPathByCurrentSection(): NodePath {
    return this.getSectionPathByCourse(
      this.courseService.getCurrentCourseSync(),
      this.selectedSection.value
    );
  }

  setSelectedSection(section: ICourseSection): void {
    this.selectedSection.next(section);
  }

  changeSelectedSectionUrlParam(path: string, sectionID: string): void {
    this.canvasService.resetElementList();
    this.router.navigate([
      `/course/${this.courseService.getCourseID()}/${path}/section`,
      sectionID,
    ]);
  }

  updateCurrentSection(): void {
    const temp = this.getPathByCurrentSection();
    const section = this.selectedSection.value;
    this.courseService.updateCurrentCourseByPath(temp, section);
  }

  updateCourse(): void {
    this.canvasService.resetFocusedElements();
    const sectionPath = this.getPathByCurrentSection();
    this.courseService.updateCurrentCourseByPath(
      sectionPath,
      this.selectedSection.value
    );
  }

  getSectionPathByCourse(course: ICourse, selectedSection: ICourseSection): NodePath {
    for (const topic in course.topics) {
      for (const lesson in course.topics[topic].lessons) {
        for (const section in course.topics[topic].lessons[lesson].sections) {
          if (section === selectedSection.id) {
            return {
              topic,
              lesson,
              section,
            };
          }
        }
      }
    }
  }

  getSectionById(course: ICourse, sectionId: string) {
    if (course) {
      for (const topic of Object.values(course.topics)) {
        for (const lesson of Object.values(topic.lessons)) {
          const section = lesson.sections[sectionId];
          if (section) {
            return section;
          }
        }
      }
    }
    return null;
  }

  setIndexToElement(): number {
    const currentSection = this.selectedSection.value;
    let index = 0;
    if (currentSection.elements) {
      for (const item of currentSection.elements) {
        if (item.specs.z_index === 0) {
          index = 1;
        }
        if (item.specs.z_index + 1 > index) {
          index = item.specs.z_index + 1;
        }
      }
    }
    return index;
  }

  deleteElement(element: IElement): void {
    // works only because of section reference...
    const currentSection = this.selectedSection.value;
    const sectionPath = this.getPathByCurrentSection();
    const updatedSection = currentSection;
    updatedSection.metaData.has_data_changed = true;
    currentSection.elements.forEach((value, index) => {
      if (value.id === element.id) {
        const item = updatedSection.elements.splice(index, 1);
        this.courseService.updateCurrentCourseByPath(
          sectionPath,
          updatedSection
        );
      }
    });
  }

  deleteMultipleElements(list: IElement[]): void {
    const currentSection = this.selectedSection.value;
    const sectionPath = this.getPathByCurrentSection();
    const updatedSection = currentSection;
    updatedSection.metaData.has_data_changed = true;

    const temp = JSON.parse(JSON.stringify(currentSection.elements));
    list.forEach((value) => {
      temp.forEach((value1, index) => {
        if (value1.id === value.id) {
          temp.splice(index, 1);
        }
      });
    });
    currentSection.elements = temp;
    this.courseService.updateCurrentCourseByPath(sectionPath, updatedSection);
  }

  copyElement(element: IElement): void {
    this.copiedElement = JSON.parse(JSON.stringify(element));
    this.copiedElement.id = this.courseService.generateID();
  }

  pasteElement(): void {
    if (this.copiedElement) {
      this.copiedElement.specs.top += 25;
      this.copiedElement.specs.left += 25;
      // if (this.copiedElement.type === 'group') {
      //   const elements = this.getAllElementsFromGroup(
      //     this.copiedElement as ElementGroupModel
      //   );
      //   for (const value of elements.elements) {
      //     if (
      //       value.specs.height + 25 > this.pageHeight ||
      //       value.specs.width + 25 > this.pageWidth
      //     ) {
      //       value.specs.top = 0;
      //       value.specs.left = 0;
      //     } else {
      //       value.specs.top += 25;
      //       value.specs.left += 25;
      //     }
      //   }
      //   for (const value of elements.groups) {
      //     if (
      //       value.specs.height + 25 > this.pageHeight ||
      //       value.specs.width + 25 > this.pageWidth
      //     ) {
      //       value.specs.top = 0;
      //       value.specs.left = 0;
      //     } else {
      //       value.specs.top += 25;
      //       value.specs.left += 25;
      //     }
      //   }
      // }

      this.addElementToSection(this.copiedElement);
      this.canvasService.setSelected(false);
      this.canvasService.setFocusedElement(this.copiedElement);
    }
    this.copiedElement = null;
  }

  copyMultipleElements(list: IElement[]): void {
    this.copiedElementsList = [];
    for (const item of list) {
      this.copiedElement = JSON.parse(JSON.stringify(item));
      this.copiedElement.id = this.courseService.generateID();
      this.copiedElementsList.push(this.copiedElement);
    }
  }

  pasteMultiElements(): void {
    if (this.copiedElementsList.length > 1) {
      const allElementSpecs = this.getCurrentListSpecs(this.copiedElementsList);
      this.copiedElementsList.forEach((value, index) => {
        value.specs.top += 25;
        value.specs.left += 25;
        // if (value.type === 'group') {
        //   const elements = this.getAllElementsFromGroup(value as ElementGroupModel);
        //   for (const value of elements.elements) {
        //     if (
        //       value.specs.height + 25 > this.pageHeight ||
        //       value.specs.width + 25 > this.pageWidth
        //     ) {
        //       value.specs.top = 0;
        //       value.specs.left = 0;
        //     } else {
        //       value.specs.top += 25;
        //       value.specs.left += 25;
        //     }
        //   }
        //   for (const value of elements.groups) {
        //     if (
        //       value.specs.height + 25 > this.pageHeight ||
        //       value.specs.width + 25 > this.pageWidth
        //     ) {
        //       value.specs.top = 0;
        //       value.specs.left = 0;
        //     } else {
        //       value.specs.top += 25;
        //       value.specs.left += 25;
        //     }
        //   }
        // }
        this.addElementToSection(value);
      });
      this.copiedElementsList = [];
      this.canvasService.setSelected(false);
    } else {
      console.log('first copy element');
    }
  }

  pasteElementCanvas(x?: number, y?: number): void {
    const mouseX = x - 296;
    const mouseY = y - 60;

    if (this.copiedElement && this.copiedElementsList.length === 0) {
      // if (this.copiedElement.type === 'group') {
      //   const elements = this.getAllElementsFromGroup(
      //     this.copiedElement as ElementGroupModel
      //   );
      //   for (const value of elements.elements) {
      //     const leftDistance = this.copiedElement.specs.left - value.specs.left;
      //     const topDistance = this.copiedElement.specs.top - value.specs.top;
      //     if (
      //       mouseX + this.copiedElement.specs.width < this.pageWidth &&
      //       mouseY + this.copiedElement.specs.height < this.pageHeight
      //     ) {
      //       value.specs.left = mouseX - leftDistance;
      //       value.specs.top = mouseY - topDistance;
      //     } else {
      //       value.specs.left += 25;
      //       value.specs.top += 25;
      //     }
      //   }
      //   for (const value of elements.groups) {
      //     const leftDistance = this.copiedElement.specs.left - value.specs.left;
      //     const topDistance = this.copiedElement.specs.top - value.specs.top;
      //     if (
      //       mouseX + this.copiedElement.specs.width < this.pageWidth &&
      //       mouseY + this.copiedElement.specs.height < this.pageHeight
      //     ) {
      //       value.specs.left = mouseX - leftDistance;
      //       value.specs.top = mouseY - topDistance;
      //     } else {
      //       value.specs.left += 25;
      //       value.specs.top += 25;
      //     }
      //   }
      // }
      if (
        mouseX + this.copiedElement.specs.width < this.pageWidth &&
        mouseY + this.copiedElement.specs.height < this.pageHeight
      ) {
        this.copiedElement.specs.left = mouseX;
        this.copiedElement.specs.top = mouseY;
      } else {
        this.copiedElement.specs.left += 25;
        this.copiedElement.specs.top += 25;
      }

      this.addElementToSection(this.copiedElement);
      this.canvasService.setSelected(false);
      this.canvasService.setFocusedElement(this.copiedElement);
      this.copiedElement = null;
    }

    if (this.copiedElementsList.length !== 0) {
      const allElementsSpecs = this.getCurrentListSpecs(
        this.copiedElementsList as IElement[]
      );
      this.copiedElementsList.forEach((value, index) => {
        const leftDistance = allElementsSpecs.left - value.specs.left;
        const topDistance = allElementsSpecs.top - value.specs.top;
        if (
          mouseX + allElementsSpecs.width < this.pageWidth &&
          mouseY + allElementsSpecs.height < this.pageHeight
        ) {
          value.specs.left = mouseX - leftDistance;
          value.specs.top = mouseY - topDistance;
        } else {
          value.specs.left += 25;
          value.specs.top += 25;
        }

        // if (value.type === 'group') {
        //   const elements = this.getAllElementsFromGroup(value);
        //   for (const item of elements.elements) {
        //     const leftDistance = allElementsSpecs.left - item.specs.left;
        //     const topDistance = allElementsSpecs.top - item.specs.top;
        //     if (
        //       mouseX + allElementsSpecs.width < this.pageWidth &&
        //       mouseY + allElementsSpecs.height < this.pageHeight
        //     ) {
        //       item.specs.left = mouseX - leftDistance;
        //       item.specs.top = mouseY - topDistance;
        //     } else {
        //       item.specs.left += 25;
        //       item.specs.top += 25;
        //     }
        //   }
        //   for (const item of elements.groups) {
        //     const leftDistance = allElementsSpecs.left - item.specs.left;
        //     const topDistance = allElementsSpecs.top - item.specs.top;
        //     if (
        //       mouseX + allElementsSpecs.width < this.pageWidth &&
        //       mouseY + allElementsSpecs.height < this.pageHeight
        //     ) {
        //       item.specs.left = mouseX - leftDistance;
        //       item.specs.top = mouseY - topDistance;
        //     } else {
        //       item.specs.left += 25;
        //       item.specs.top += 25;
        //     }
        //   }
        // }
        this.addElementToSection(value);
      });
      this.canvasService.setSelected(false);
    }
    if (!this.copiedElement && !this.copiedElementsList) {
      console.log('first copy element');
    }
    this.copiedElementsList = [];
    this.copiedElement = null;
  }

  addElementToSection(element: IElement): void {
    const currentSection = this.selectedSection.value;
    const sectionPath = this.getPathByCurrentSection();
    const updatedSection = currentSection;
    updatedSection.metaData.has_data_changed = true;

    currentSection.elements.push(element);
    this.courseService.updateCurrentCourseByPath(sectionPath, updatedSection);
  }

  addMultipleElementsToSection(elements: IElement[]): void {
    const currentSection = this.selectedSection.value;
    const sectionPath = this.getPathByCurrentSection();
    const updatedSection = currentSection;
    updatedSection.metaData.has_data_changed = true;

    for (const item of elements) {
      currentSection.elements.push(item);
    }
    this.courseService.updateCurrentCourseByPath(sectionPath, updatedSection);
  }

  getCurrentListSpecs(paramList) {
    const list = paramList;

    const finalSpecs = {
      width: list[0].specs.width,
      height: list[0].specs.height,
      left: list[0].specs.left,
      top: list[0].specs.top,
      z_index: list[0].specs.z_index,
    };
    let lowestElementTop = 0;
    let lowestElementHeight = 0;

    let rightestElement = list[0].specs.left;
    let rightestElementWidth = list[0].specs.width;

    let leftestElement = list[0].specs.left;
    let leftestElementWidth = list[0].specs.width;

    for (const item of list) {
      for (const key in item.specs) {
        const value = item.specs[key];
        if (finalSpecs[key] < value && (key === 'width' || key === 'index')) {
          finalSpecs[key] = value;
        }
        if (
          key === 'left' &&
          rightestElement + rightestElementWidth < value + item.specs.width
        ) {
          rightestElement = value;
          rightestElementWidth = item.specs.width;
        }
        if (key === 'left' && leftestElement > value) {
          leftestElement = value;
          leftestElementWidth = item.specs.width;
        }
        if (finalSpecs[key] > value && key === 'top') {
          finalSpecs[key] = value;
        }
        if (
          key === 'top' &&
          lowestElementTop + lowestElementHeight < value + item.specs.height
        ) {
          lowestElementTop = value;
          lowestElementHeight = item.specs.height;
        }
      }
    }

    finalSpecs.height = lowestElementHeight + lowestElementTop - finalSpecs.top;
    finalSpecs.width = rightestElement + rightestElementWidth - leftestElement;
    finalSpecs.left = leftestElement;
    return finalSpecs;
  }

  // getAllElementsFromGroup(group: ElementGroupModel): {
  //   elements: ElementModel[];
  //   groups: ElementGroupModel[];
  // } {
  //   let elements: ElementModel[] = [];
  //   let groups: ElementGroupModel[] = [];
  //
  //   group.elements.forEach((value) => {
  //     if (value.type === 'group') {
  //       groups.push(<ElementGroupModel>value);
  //       let tmpValue = this.getAllElementsFromGroup(<ElementGroupModel>value);
  //       tmpValue.elements.forEach((value) => {
  //         elements.push(value);
  //       });
  //     } else {
  //       elements.push(<ElementModel>value);
  //     }
  //   });
  //
  //   return { elements: elements, groups: groups };
  // }
}
