import { IFirestoreBatch } from './interfaces-enums/firebase-batch';
import { inject, Injectable } from '@angular/core';
import * as firestore from '@angular/fire/firestore';
import {
  addDoc,
  doc,
  Firestore,
  getDoc,
  setDoc,
  collection,
  updateDoc,
  UpdateData,
  deleteDoc,
  writeBatch,
} from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FirestoreHandleService {
  private db: Firestore = inject(Firestore);

  constructor() {}

  getDocument(path: string): Observable<any> {
    const documentRef = doc(this.db, path);
    const documentPromise = getDoc(documentRef);
    return from(documentPromise).pipe(
      take(1),
      map((result) => result.data())
    );
  }

  createDocument(collectionPath: string, data: any): Observable<string> {
    const collectionRef = collection(this.db, `${collectionPath}`);
    return from(addDoc(collectionRef, data)).pipe(
      take(1),
      map((documentRef) => documentRef.id)
    );
  }
  // https://firebase.google.com/docs/reference/js/firestore_.md#updatedoc
  updateDocument(path: string, data: UpdateData<any>): Observable<void> {
    const documentRef = doc(this.db, path);
    return from(updateDoc(documentRef, data)).pipe(take(1));
  }

  setDocument(path: string, data: any): Observable<void> {
    const documentRef = doc(this.db, path);
    return from(setDoc(documentRef, data)).pipe(take(1));
  }

  deleteDocument(path: string): Observable<void> {
    const documentRef = doc(this.db, path);
    return from(deleteDoc(documentRef)).pipe(take(1));
  }

  generateDocumentId(collectionPath: string): string {
    return doc(collection(this.db, collectionPath)).id;
  }

  getTimeStamp(): Date {
    return firestore.Timestamp.now().toDate();
  }

  updateBatch(items: IFirestoreBatch[]) {
    const batch = writeBatch(this.db);
    items.forEach((item) => {
      const docRef = doc(this.db, item.path, item.document);
      batch.set(docRef, item.data, { merge: true });
    });
    return from(batch.commit()).pipe(take(1));
  }

  setBatch(items: IFirestoreBatch[]) {
    const batch = writeBatch(this.db);
    items.forEach((item) => {
      const docRef = doc(this.db, item.path, item.document);
      batch.set(docRef, item.data);
    });
    return from(batch.commit()).pipe(take(1));
  }
}
