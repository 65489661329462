import {Component, HostListener, OnInit} from '@angular/core';
import {ValidatorService} from "./validator.service";

@Component({
  selector: 'app-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.scss']
})
export class ValidatorComponent implements OnInit {

  constructor(private validator: ValidatorService) { }

  ngOnInit(): void {
  }


  onYes(){
    this.validator.setResult(true);
  }
  onNo(){
    this.validator.setResult(false);
  }

  @HostListener('document:keyup', ['$event'])
  onKeyPress(event: KeyboardEvent){
    console.log(event)
    if(event.code === "Enter"){
      this.onYes();
    }
    else if(event.code === "Escape"){
      this.onNo();
    }
  }

}
