import { Directive, ElementRef, Injector, Renderer2 } from '@angular/core';
import { CourseService } from './course.service';
import { Subscription, filter } from 'rxjs';
import { NodePath } from './course-editor/editor-sidebar/interfaces-enums/node-tree';
import { ICourseSection, ICourse } from './interfaces-enums/course';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CourseEditorService } from './course-editor/course-editor.service';
import { CoursesRoutes } from './interfaces-enums/course-routes.enum';
import {ElementActionsService} from './course-editor/element-actions/element-actions.service';

@Directive({
  selector: '[appSelectedSection]',
})
export class SelectedSectionDirective {
  // constructor injections
  router: Router;
  route: ActivatedRoute;
  el: ElementRef;
  renderer: Renderer2;
  courseService: CourseService;
  courseEditorService: CourseEditorService;
  actionService: ElementActionsService;

  selectedSection: ICourseSection;
  selectedSectionSub: Subscription;
  selectedSectionID!: string;
  sectionPath: NodePath;

  currentCourse: ICourse;
  currentCourseSub: Subscription;
  elementsListSub: Subscription;
  routeChangedSub: Subscription;

  coursesRoutes = CoursesRoutes;


  constructor(protected injector: Injector) {
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.el = injector.get(ElementRef);
    this.renderer = injector.get(Renderer2);
    this.courseService = injector.get(CourseService);
    this.courseEditorService = injector.get(CourseEditorService);
    this.actionService = injector.get(ElementActionsService);
  }

  subscribeToCourse() {
    this.courseService.updateCourseHistoryList(null);
    this.currentCourseSub = this.courseService.courseSub.subscribe((course) => {
      this.currentCourse = course;
      if (
        !this.selectedSectionID &&
        !this.selectedSection &&
        this.currentCourse
      ) {
        this.selectedSectionID = this.route.snapshot.paramMap.get('id');
        this.updateSelectedSection(this.selectedSectionID);
      }
    });
  }

  updateSelectedSection(id: string) {
    this.selectedSection = this.courseEditorService.getSectionById(
      this.currentCourse,
      id
    );
    if (this.selectedSection) {
      this.courseEditorService.setSelectedSection(this.selectedSection);
      this.renderSectionHeight();
      this.loadSectionElements();
      this.sectionPath = this.courseEditorService.getSectionPathByCourse(
        this.currentCourse,
        this.selectedSection
      );
    }
  }

  renderSectionHeight() {
    if (this.selectedSection?.height) {
      this.renderer.setStyle(
        this.el.nativeElement.children[0].children[1],
        'height',
        `${this.selectedSection.height}px`
      );
    } else {
      this.renderer.setStyle(
        this.el.nativeElement.children[0].children[1],
        'height',
        'calc( 100vh - 64px )'
      );
    }
  }

  loadSectionElements() {
    this.selectedSection.elements = [];
    if (this.selectedSection && this.currentCourse) {
      this.elementsListSub = this.courseService
        .getSelectedSectionElements(
          this.currentCourse.id,
          this.selectedSection.id
        )
        .subscribe((sectionElements) => {
          this.selectedSection.elements = sectionElements.elements;
          this.selectedSection.actions = sectionElements.actions;
          this.actionService.setActionList(this.selectedSection.actions);
        });
    }
  }

  subscribeToSelectedSection() {
    this.selectedSectionSub =
      this.courseEditorService.selectedSection.subscribe(
        (selectedSection: ICourseSection) => {
          this.selectedSection = selectedSection;
        }
      );
  }

  updateOnRouteChange() {
    this.routeChangedSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.currentCourse) {
          this.selectedSectionID = this.route.snapshot.paramMap.get('id');
          this.updateSelectedSection(this.selectedSectionID);
        }
      });
  }
}
