<app-gallery-popup-template
  (onNext)="$event? goNext():goPrevious()"
  title="Testing Options"
  [rightButton]="rightButton"
  [leftButton]="leftButton">

  <div class="stepper">
    <div class="stepper-wrapper">
      <div class="steps step-one" (click)="setStep(1)">
        <div class="step-title">
          <div class="step-number" [ngClass]="currentStep===1? 'current-step':''">1</div>
          <h4>Naming</h4>
        </div>
      </div>
      <div class="steps step-two" (click)="setStep(2)">
        <div class="step-title">
          <div class="step-number" [ngClass]="currentStep===2? 'current-step':''">2</div>
          <h4>Tests</h4>
        </div>
      </div>
      <div class="steps step-three" (click)="setStep(3)">
        <div class="step-title">
          <div class="step-number" [ngClass]="currentStep===3? 'current-step':''">3</div>
          <h4>Finish</h4>
        </div>
      </div>
    </div>
    <div class="stepper-content">
      <div class="first-step-content" *ngIf="currentStep===1">
        <form [formGroup]="firstStep" class="first-step-form" >
          <div class="two-columns">
            <div class="first-column-stepper">
              <div class="form-input funcName bottom-margin">
                <label for="funcName">Function Name:</label>
                <input (ngModelChange)="updatePreview()" id="funcName" formControlName="functionName" placeholder="function name">
                <span class="material-icons-outlined stepper-form-icons">edit</span>
              </div>
              <div class="form-input paramAmount bottom-margin">
                <label for="paramAmount">Parameters Amount:</label>
                <select
                  formControlName="paramAmount"
                  id="paramAmount"
                  name="paramAmount" (change)="paramChange()">
                  <option *ngFor="let amount of paramsAmount" [ngValue]="amount">{{amount}}</option>
                </select>
              </div>
              <div class="form-input paramNames bottom-margin">
                <label>Parameters Names:</label>
                <br>
                <div  formGroupName="paramNames" *ngFor="let item of counter(firstStep.value['paramAmount'])">
                  <div class="paramNameEdit" formGroupName="param{{item}}">
                    <input class="paramNameInput" (ngModelChange)="updatePreview()" formControlName="name" placeholder="name{{item}}">
                    <span class="material-icons-outlined stepper-form-icons">edit</span>
                    <label class="separator">,</label>
                    <label class="paramType">Parameter Type:</label>
                    <select formControlName="type">
                      <option *ngFor="let result of resultType" [ngValue]="result">{{result}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="second-column-steeper">
              <div class="form-preview">
                <label>Preview:</label>
                <div class="preview-editor">
                  <ngx-monaco-editor class="macro-editor"
                                     [options]="editorOptions" (onInit)="previewEditorInit($event)">
                  </ngx-monaco-editor>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="(firstStep.invalid || !noDuplicateParams() )&& submitted">
            <p class="invalid-massage"> *Pleace Fill Out all Info</p>
          </div>

        </form>
      </div>
      <div class="second-step-content" *ngIf="currentStep===2">
        <form [formGroup]="secondStep">
          <div class="addNewTests">
            <div class="addNewTests-wrapper">
              <div class="testsAmount form-input">
                <label for="testsAmount">Number Of Tests To Add:</label>
                <input class="testsAmountNum" id="testsAmount" #testsAmount>
                <span class="material-icons-outlined stepper-form-icons">edit</span>
              </div>
              <div class="line-separator"> | </div>
              <div class="resultType form-input">
                <label for="resultType">Result Type:</label>
                <select formControlName="resultType" id="resultType">
                  <option *ngFor="let result of resultType" [ngValue]="result">{{result}}</option>
                </select>
              </div>
            </div>
            <button (click)="addNewTests(testsAmount.value); print(secondStep)" class="addTests">+ADD</button>
          </div>
          <div class="newTestsAdded form-input" formGroupName="tests">
            <ol class="newTestsList">
              <li  *ngFor="let test of secondStep.controls['tests']['controls'] | keyvalue">
                <div class="test-list-wrapper">
                  <div formGroupName="{{test.key}}" class="test-list-item">
                    <label class="testFuncName">{{firstStep.controls['functionName'].value}} (</label>
                    <div formGroupName="params"  class="numOfParam" *ngFor="let item of test.value.get('params').controls | keyvalue; let last = last">
                      <input formControlName="{{item.key}}" class="paramsInputs" placeholder="{{item.key}}">
                      <p *ngIf="!last"> ,</p>
                    </div>
                    <label>)</label>
                    <div class="resultToTest">
                      <label class="testResult">Result:</label>
                      <input style="padding: 0;" formControlName="result" onkeypress="this.style.width = ((this.value.length + 1) * 8) < 630?  ((this.value.length + 1) * 8)+ 'px' : 630 + 'px'"
                             placeholder="{{secondStep.get('resultType').value}}: result">
                    </div>

                  </div>
                  <div class="test-list-icons">
<!--                    <skillBee-icons class="test-list-icon" name="add-btn"></skillBee-icons>-->
                    <skillBee-icons class="test-list-icon" style="margin-left: 10px" name="trash" (click)="deleteTest(test.key.toString())"></skillBee-icons>
                  </div>
                </div>
              </li>
            </ol>
          </div>
          <div *ngIf="(secondStep.invalid || !noDuplicateParams() )&& submitted">
            <p class="invalid-massage second-step-error-massage"> *Pleace Fill Out all Info</p>
          </div>
        </form>
      </div>
      <div class="third-step-content"  *ngIf="currentStep===3">
        <form [formGroup]="thirdStep">
          <div class="test-run">
            <div class="test-runner-content form-input">
              <label>Test Runner:</label>
              <ngx-monaco-editor class="macro-editor"
                                 [options]="editorOptions"
                                 (onInit)="testRunnerInit($event)"
                                 (focusout)="updateTestCode()">
              </ngx-monaco-editor>
            </div>
            <div class="test-run-results">
              <label style="position: relative; left: 10px">Test Result:</label>
              <app-code-element-compiler *ngIf="shouldCompile" [unitTests]="tests"></app-code-element-compiler>
            </div>
          </div>
          <button class="text-btn" (click)="getToCompile()">Run <span class="run-test material-icons-outlined">chevron_right</span></button>
        </form>
      </div>
    </div>
  </div>

</app-gallery-popup-template>
