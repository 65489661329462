<div class="landing-page-container">
  <div *ngIf="!landingPageData">
      <app-create-user-landing-page></app-create-user-landing-page>
  </div>
  <div *ngIf="landingPageData">
      <app-user-landing-page-editor></app-user-landing-page-editor>
  </div>

</div>

