import { CourseService } from 'src/app/course/course.service';
import { IMenuItem } from './inteface/side-menu';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-hive',
  templateUrl: './sidebar-hive.component.html',
  styleUrls: ['./sidebar-hive.component.scss'],
})
export class SidebarHiveComponent implements OnInit {
  private _items: IMenuItem[] = [];
  hideMenu = false;
  @Input() set items(val) {
    this._items = val;
  }
  get items() {
    return this._items;
  }

  onStudentView = false;

  constructor(private courseService: CourseService) {}

  ngOnInit(): void {
    this.onStudentView = this.courseService.onStudentView;
  }
}
