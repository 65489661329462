export enum MenuItem {
  DASHBOARD = 'DASHBOARD',
  COURSES = 'COURSES',
  CREATE_COURSE = 'COURSES',
  ANALYTICS = 'ANALYTICS',
  STRUCTURE = 'STRUCTURE',
  DETAILS = 'DETAILS',
  RESOURCES = 'RESOURCES',
  FINANCE = 'FINANCE',
  FORUM = 'FORUM',
  LANDING_PAGE = 'LANDING PAGE',
  SETTINGS = 'SETTINGS',
}
