import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { EditorCanvasService } from '../editor-canvas/editor-canvas.service';

@Injectable({ providedIn: 'root' })
export class ElementFrameService {
  pushFrame: Subject<{ x: number; y: number; id: string }> = new Subject<{
    x: number;
    y: number;
    id: string;
  }>();

  contextMenuById: Subject<string> = new Subject();

  isDragging: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private canvasService: EditorCanvasService) {}

  setIsDragging(value: boolean): void {
    this.isDragging.next(value);
  }

  getIsDragging() {
    return this.isDragging;
  }


  setMultiContextMenu(id: string | null) {
    this.contextMenuById.next(id);
  }


  alignToLeft() {
    const list = this.canvasService.currentElementList.value;
    let mostLeft = list[0].specs.left;

    for (const el of list) {
      if (el.specs.left < mostLeft) {
        mostLeft = el.specs.left;
      }
    }
    for (const item of list) {
      // if (item.type === 'group') {
      //   const elements = this.getAllElementsFromGroup(item);
      //   const distance = item.specs.left - mostLeft;
      //
      //   for (const element of elements.elements) {
      //     element.specs.left -= distance;
      //   }
      //   for (const element of elements.groups) {
      //     element.specs.left -= distance;
      //   }
      // }
      item.specs.left = mostLeft;
      this.pushFrameById({ x: mostLeft, y: item.specs.top, id: item.id });
      this.canvasService.resettingGroupAfterAlignment(list);
    }
  }

  alignToCenter() {
    const list = this.canvasService.currentElementList.value;
    const tempSpec = this.canvasService.getCurrentListSpecs(list);
    const largestWidth = tempSpec.width;
    const leftPoint = tempSpec.left;
    const centralPoint = largestWidth / 2 + leftPoint;

    for (const item of list) {
      // if (item.type === 'group') {
      //   const elements = this.getAllElementsFromGroup(item as ElementGroupModel);
      //   const distance = Math.abs(
      //     item.specs.left - Math.abs(centralPoint - item.specs.width / 2)
      //   );
      //
      //   for (const element of elements.elements) {
      //     if (item.specs.left + item.specs.width / 2 < centralPoint) {
      //       element.specs.left += distance;
      //     } else if (item.specs.left + item.specs.width / 2 > centralPoint) {
      //       element.specs.left -= distance;
      //     }
      //   }
      //   for (const element of elements.groups) {
      //     if (item.specs.left + item.specs.width / 2 < centralPoint) {
      //       element.specs.left += distance;
      //     } else if (item.specs.left + item.specs.width / 2 > centralPoint) {
      //       element.specs.left -= distance;
      //     }
      //   }
      // }

      const whereToMove = Math.abs(centralPoint - item.specs.width / 2);
      item.specs.left = whereToMove;
      this.pushFrameById({ x: whereToMove, y: item.specs.top, id: item.id });

      this.canvasService.resettingGroupAfterAlignment(list);
    }
  }

  alignToRight() {
    const list = this.canvasService.currentElementList.value;
    let rightestLeft = 0;
    let rightestPoint = 0;

    for (const item of list) {
      if (item.specs.left + item.specs.width > rightestPoint) {
        rightestLeft = item.specs.left;
        rightestPoint = item.specs.left + item.specs.width;
      }
    }

    for (const item of list) {
      // if (item.type === 'group') {
      //   const elements = this.getAllElementsFromGroup(item as ElementGroupModel);
      //   const distance = rightestPoint - (item.specs.left + item.specs.width);
      //
      //   for (const element of elements.elements) {
      //     element.specs.left += distance;
      //   }
      //   for (const element of elements.groups) {
      //     element.specs.left += distance;
      //   }
      // }
      item.specs.left = rightestPoint - item.specs.width;
      this.pushFrameById({
        x: rightestPoint - item.specs.width,
        y: item.specs.top,
        id: item.id,
      });

      this.canvasService.resettingGroupAfterAlignment(list);
    }
  }

  alignVertically() {
    const list = this.canvasService.currentElementList.value;

    const sortedArray = list.sort((obj1, obj2) => {
      if (obj1.specs.top > obj2.specs.top) {
        return 1;
      }
      if (obj1.specs.top < obj2.specs.top) {
        return -1;
      }
      return 0;
    });

    const tempSpecs = this.canvasService.getCurrentListSpecs(sortedArray);
    const lowestTopHeight =
      sortedArray[0].specs.height + sortedArray[0].specs.top;
    const highestTop = sortedArray[0].specs.top;
    const lowestTop = sortedArray[sortedArray.length - 1].specs.top;

    const groupHeight = tempSpecs.height;
    let allElementsHeight = 0;

    sortedArray.forEach((value) => {
      allElementsHeight += value.specs.height;
    });

    const amountOfItems = sortedArray.length - 2;
    const spaceBetween = Math.floor(
      (groupHeight - allElementsHeight) / (amountOfItems + 1)
    );

    let startPoint = lowestTopHeight + spaceBetween;

    for (const item of sortedArray) {
      if (item.specs.top !== highestTop && item.specs.top !== lowestTop) {
        // if (item.type === 'group') {
        //   const elements = this.getAllElementsFromGroup(item as ElementGroupModel);
        //   const distance = item.specs.top - startPoint;
        //
        //   for (const element of elements.elements) {
        //     element.specs.top -= distance;
        //   }
        //   for (const element of elements.groups) {
        //     element.specs.top -= distance;
        //   }
        // }

        item.specs.top = startPoint;
        this.pushFrameById({ x: item.specs.left, y: startPoint, id: item.id });
        startPoint += item.specs.height + spaceBetween;

        this.canvasService.resettingGroupAfterAlignment(sortedArray);
      }
    }
  }

  alignToTop() {
    const list = this.canvasService.currentElementList.value;
    let topPoint = list[0].specs.top;

    for (const item of list) {
      if (item.specs.top < topPoint) {
        topPoint = item.specs.top;
      }
    }
    for (const item of list) {
      // if (item.type === 'group') {
      //   const elements = this.getAllElementsFromGroup(item as ElementGroupModel);
      //   const distance = item.specs.top - topPoint;
      //
      //   for (const element of elements.elements) {
      //     element.specs.top -= distance;
      //   }
      //   for (const element of elements.groups) {
      //     element.specs.top -= distance;
      //   }
      // }
      item.specs.top = topPoint;
      this.pushFrameById({ x: item.specs.left, y: topPoint, id: item.id });
      this.canvasService.resettingGroupAfterAlignment(list);
    }
  }

  alignToMiddle() {
    const list = this.canvasService.currentElementList.value;
    const tempSpecs = this.canvasService.getCurrentListSpecs(list);
    const middlePoint = tempSpecs.height / 2 + tempSpecs.top;

    for (const item of list) {
      // if (item.type === 'group') {
      //   const elements = this.getAllElementsFromGroup(item as ElementGroupModel);
      //   const distance = Math.abs(
      //     item.specs.top - Math.abs(middlePoint - item.specs.height / 2)
      //   );
      //
      //   for (const element of elements.elements) {
      //     if (item.specs.top + item.specs.height / 2 < middlePoint) {
      //       element.specs.top += distance;
      //     } else if (item.specs.top + item.specs.height / 2 > middlePoint) {
      //       element.specs.top -= distance;
      //     }
      //   }
      //   for (const element of elements.groups) {
      //     if (item.specs.top + item.specs.height / 2 < middlePoint) {
      //       element.specs.top += distance;
      //     } else if (item.specs.top + item.specs.height / 2 > middlePoint) {
      //       element.specs.top -= distance;
      //     }
      //   }
      // }

      const whereToMove = Math.abs(middlePoint - item.specs.height / 2);
      item.specs.top = whereToMove;
      this.pushFrameById({ x: item.specs.left, y: whereToMove, id: item.id });

      this.canvasService.resettingGroupAfterAlignment(list);
    }
  }

  alignToBottom() {
    const list = this.canvasService.currentElementList.value;
    let lowestTop = 0;
    let bottomPoint = 0;

    for (const item of list) {
      if (item.specs.top + item.specs.height > bottomPoint) {
        lowestTop = item.specs.top;
        bottomPoint = item.specs.top + item.specs.height;
      }
    }

    for (const item of list) {
      // if (item.type === 'group') {
      //   const elements = this.getAllElementsFromGroup(item as ElementGroupModel);
      //   const distance = bottomPoint - (item.specs.top + item.specs.height);
      //
      //   for (const element of elements.elements) {
      //     element.specs.top += distance;
      //   }
      //   for (const element of elements.groups) {
      //     element.specs.left += distance;
      //   }
      // }
      item.specs.top = bottomPoint - item.specs.height;
      this.pushFrameById({
        x: item.specs.left,
        y: bottomPoint - item.specs.height,
        id: item.id,
      });

      this.canvasService.resettingGroupAfterAlignment(list);
    }
  }

  alignHorizontally() {
    const list = this.canvasService.currentElementList.value;

    const sortedArray = list.sort((obj1, obj2) => {
      if (obj1.specs.left > obj2.specs.left) {
        return 1;
      }
      if (obj1.specs.left < obj2.specs.left) {
        return -1;
      }
      return 0;
    });

    const tempSpecs = this.canvasService.getCurrentListSpecs(sortedArray);
    const leftestHeight =
      sortedArray[0].specs.width + sortedArray[0].specs.left;
    const leftestPoint = sortedArray[0].specs.left;
    const rightestPoint = sortedArray[sortedArray.length - 1].specs.left;

    const groupWidth = tempSpecs.width;
    let allElementsWidth = 0;

    sortedArray.forEach((value) => {
      allElementsWidth += value.specs.width;
    });

    const amountOfItems = sortedArray.length - 2;
    const spaceBetween = Math.floor(
      (groupWidth - allElementsWidth) / (amountOfItems + 1)
    );

    let startPoint = leftestHeight + spaceBetween;

    for (const item of sortedArray) {
      if (
        item.specs.left !== leftestPoint &&
        item.specs.top !== rightestPoint
      ) {
        // if (item.type === 'group') {
        //   const elements = this.getAllElementsFromGroup(item as ElementGroupModel);
        //   const distance = item.specs.left - startPoint;
        //
        //   for (const element of elements.elements) {
        //     element.specs.left -= distance;
        //   }
        //   for (const element of elements.groups) {
        //     element.specs.left -= distance;
        //   }
        // }

        item.specs.left = startPoint;
        this.pushFrameById({ x: startPoint, y: item.specs.top, id: item.id });
        startPoint += item.specs.width + spaceBetween;

        this.canvasService.resettingGroupAfterAlignment(sortedArray);
      }
    }
  }

  pushFrameById(frame: { x: number; y: number; id: string; list? }) {
    this.pushFrame.next(frame);
  }



  dragMultipleElements(delta: { x: number; y: number }) {
    const currentElements = this.canvasService.currentElementList.value;

    for (const item of currentElements) {
      const left = item.specs.left + delta.x;
      const top = item.specs.top + delta.y;
      this.pushFrameById({ x: left, y: top, id: item.id });
    }
  }

  endDragMulti(distance: { x: number; y: number }) {
    const newList = this.canvasService.currentElementList.value;

    newList.forEach((item) => {
      item.specs.left += distance.x;
      item.specs.top += distance.y;
    });

    for (const item of newList) {
      // if (item.type === 'group') {
      //   const elements = this.getAllElementsFromGroup(item as ElementGroupModel);
      //
      //   for (const element of elements.elements) {
      //     element.specs.left += distance.x;
      //     element.specs.top += distance.y;
      //   }
      //   for (const element of elements.groups) {
      //     element.specs.left += distance.x;
      //     element.specs.top += distance.y;
      //   }
      // }
      this.pushFrameById({
        x: item.specs.left,
        y: item.specs.top,
        id: item.id,
      });

      this.canvasService.resettingGroupAfterAlignment(newList);
    }
  }

}
