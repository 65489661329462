import {
  Component,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { KeyValue } from '@angular/common';
import { CourseStructureService } from '../course-structure.service';
import { Subscription } from 'rxjs';
import { CourseService } from 'src/app/course/course.service';
import { ValidatorService } from 'src/app/shared/validator/validator.service';
import {
  ICourseLesson,
  ICourseSection,
  ICourseTopic,
} from 'src/app/course/interfaces-enums/course';
import { CollectionTypes } from 'src/app/course/interfaces-enums/collection-types.enum';

@Component({
  selector: 'app-course-structure-topic',
  templateUrl: './course-structure-topic.component.html',
  styleUrls: ['./course-structure-topic.component.scss'],
})
export class CourseStructureTopicComponent implements OnInit, OnDestroy {
  @Input() topic: ICourseTopic;
  @Input() id: string;
  course_topic: { [id: string]: ICourseTopic };

  // -----To Be Removed----- //
  isValid;
  isValidSub: Subscription;

  isOpenFolder: string;

  borderSide = 'borderLeft';

  topicNameEdit = false;
  @ViewChild('topicName') topicName: ElementRef;
  // -----To Be Removed----- //

  isTeacher: boolean;

  constructor(
    private courseService: CourseService,
    private validator: ValidatorService,
    private structureService: CourseStructureService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  ngOnInit(): void {
    this.isTeacher = this.structureService.isTeacher();
    // -----To Be Removed----- //
    this.isValidSub = this.structureService.canEdit.subscribe((status) => {
      this.isValid = status;
    });

    this.course_topic = { id: this.topic };
  }

  valueAscOrder = (
    a: KeyValue<string, ICourseLesson>,
    b: KeyValue<string, ICourseLesson>
  ): number => {
    return a.value.index > b.value.index
      ? 1
      : b.value.index > a.value.index
      ? -1
      : 0;
  };

  // -----To Be Removed----- //
  onTopicNameEdit() {
    if (this.topicNameEdit === false) {
      this.structureService.canEditStatus(false);
    } else {
      this.structureService.canEditStatus(true);
    }
    this.topicNameEdit = !this.topicNameEdit;
    setTimeout(() => {
      if (this.topicName) {
        this.topicName.nativeElement.focus();
      }
    }, 100);
  }
  // -----To Be Removed----- //

  // -----To Be Removed----- //
  onTopicNameSave(topicName: string) {
    this.topic.title = topicName;
    this.courseService.updateCurrentCourseByPath(
      { topic: this.id },
      this.topic
    );
    this.onTopicNameEdit();
  }
  // -----To Be Removed----- //

  // -----To Be Removed----- //
  onDelete() {
    this.validator.getResult().subscribe((result) => {
      if (result) {
        this.courseService.removeFromCourseByPath({ topic: this.id });
      }
    });
  }
  // -----To Be Removed----- //

  addLesson() {
    this.structureService.addLesson(this.topic, this.topic.id);

    const lastIndex = Object.keys(this.topic.lessons).length + 1;

    const lessonID: string = this.courseService.generateID();
    const sectionID: string = this.courseService.generateID();

    const newSection: ICourseSection = {
      ...this.courseService.createLayer(
        1,
        sectionID,
        lessonID,
        this.topic.creator_id
      ),
      elements: [],
      actions: [],
      type: CollectionTypes.section,
    };
    const newLesson: ICourseLesson = {
      ...this.courseService.createLayer(
        lastIndex,
        lessonID,
        this.topic.id,
        this.topic.creator_id
      ),
      sections: {},
      type: CollectionTypes.lesson,
    };

    newLesson.sections[sectionID] = newSection;
    this.courseService.updateCurrentCourseByPath(
      { topic: this.id, lesson: lessonID },
      newLesson
    );
  }

  ngOnDestroy(): void {
    this.isValidSub.unsubscribe();
  }

  getStyles() {
    if (this.locale === 'he') {
      return {
        borderRight:
          'solid 8px ' + (this.topic.color ? this.topic.color : '#FF1C1C'),
      };
    } else {
      return {
        borderLeft:
          'solid 8px ' + (this.topic.color ? this.topic.color : '#FF1C1C'),
      };
    }
  }

  onToggle(event: boolean) {
    this.topic.metaData.open = event;
  }
}
