import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTitleComponent } from './page-title.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, MatIconModule, RouterModule],
  declarations: [PageTitleComponent],
  exports: [PageTitleComponent],
})
export class PageTitleModule {}
