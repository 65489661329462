<div *ngIf="isTeacher">
  <div class="lesson-wrapper">
    <div
      class="lesson-title-wrapper"
      [ngStyle]="
        lesson.metaData.open
          ? { 'background-color': '#f7f7f7' }
          : { 'background-color': '#f0f0f0' }
      "
    >
      <div class="indexNumber" [ngStyle]="{ backgroundColor: getTopicColor() }">
        {{ lesson.index }}
      </div>
      <app-structure-header-manager
        class="lesson-title-component"
        (toggle)="onToggle($event)"
        [element]="lesson"
        [path]="{ topic: parents.topic, lesson: id }"
      >
      </app-structure-header-manager>
    </div>
    <div
      class="lesson-body"
      [ngStyle]="lesson.metaData.open ? {} : { display: 'none' }"
    >
      <div
        class="section-container"
        *ngFor="let section of lesson.sections | keyvalue : valueAscOrder"
      >
        <app-course-structure-section
          [section]="section.value"
          [id]="section.key"
          [parents]="{ lesson: id, topic: parents.topic }"
        >
        </app-course-structure-section>
      </div>
      <button
        [disabled]="!canEdit"
        class="section-btn"
        (click)="addSection()"
        mat-button
        color="primary"
        i18n="
          course panel new section button|course panel new section
          button@@coursePanelNewSectionBTN"
      >
        <mat-icon class="material-icons-outlined addNewItem">add</mat-icon>
        SECTION
      </button>
    </div>
  </div>
</div>

<div *ngIf="!isTeacher">
  <div class="lesson-wrapper">
    <div
      class="lesson-title-wrapper"
      [ngStyle]="
        lesson.metaData.open
          ? { 'background-color': '#f7f7f7' }
          : { 'background-color': '#f0f0f0' }
      "
    >
      <div class="indexNumber" [ngStyle]="{ backgroundColor: getTopicColor() }">
        {{ lesson.index }}
      </div>
      <app-structure-header-manager
        class="lesson-title-component"
        (toggle)="onToggle($event)"
        [element]="lesson"
        [path]="{ topic: parents.topic, lesson: id }"
      >
      </app-structure-header-manager>
    </div>
    <div
      class="lesson-body"
      [ngStyle]="lesson.metaData.open ? {} : { display: 'none' }"
    >
      <div
        class="section-container"
        *ngFor="let section of lesson.sections | keyvalue : valueAscOrder"
      >
        <app-course-structure-section
          [section]="section.value"
          [id]="section.key"
          [parents]="{ lesson: id, topic: parents.topic }"
        >
        </app-course-structure-section>
      </div>
    </div>
  </div>
</div>
