import { UnsavedChangesService } from './../../../../shared/unsaved-changes/unsaved-changes.service';
import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CourseStructureService } from './course-structure.service';
import { KeyValue } from '@angular/common';

import { CourseService } from 'src/app/course/course.service';
import { ICourse, ICourseTopic } from 'src/app/course/interfaces-enums/course';

@Component({
  selector: 'app-course-structure',
  templateUrl: './course-structure.component.html',
  styleUrls: ['./course-structure.component.scss'],
})
export class CourseStructureComponent implements OnInit, OnDestroy {
  currentCourse: ICourse;
  courseSub: Subscription;
  panelView: string;
  panelViewSub: Subscription;

  constructor(
    private courseService: CourseService,
    private courseStructureService: CourseStructureService,
    public unsavedChangesService: UnsavedChangesService
  ) {}

  ngOnInit(): void {
    this.courseSub = this.courseService.courseSub.subscribe((course) => {
      if (course) {
        this.currentCourse = course;
      }
    });

    this.panelViewSub = this.courseStructureService.structureViewMode.subscribe(
      (view) => {
        if (!this.panelView && localStorage.getItem('panel_view')) {
          this.panelView = localStorage.getItem('panel_view');
        } else {
          this.panelView = view;
        }
      }
    );
  }

  valueAscOrder = (
    a: KeyValue<string, ICourseTopic>,
    b: KeyValue<string, ICourseTopic>
  ): number => {
    return a.value.index > b.value.index
      ? 1
      : b.value.index > a.value.index
      ? -1
      : 0;
  };

  ngOnDestroy() {
    this.courseSub.unsubscribe();
    this.courseStructureService.canEditStatus(true);
    this.unsavedChangesService.setUnsavedChanges(false);
  }

  addTopic() {
    this.courseStructureService.addTopic(this.currentCourse);
  }

  areThereTopics() {
    if (this.currentCourse?.topics) {
      return Object.keys(this.currentCourse?.topics)?.length > 0;
    }
  }
}
