import {Component, OnDestroy, OnInit} from '@angular/core';
import {HivePopupService} from '../hive-popup/hive-popup.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit, OnDestroy {
  displayModal: boolean;
  modalHeaderText: string;
  modalBodyText: string;

  modalPopupSub: Subscription;

  constructor(private popupService: HivePopupService) { }



  ngOnInit(): void {
    this.modalPopupSub = this.popupService.showTermsSub.subscribe(data => {
      this.displayModal = data;
    })
  }

  ngOnDestroy(): void {
    this.modalPopupSub.unsubscribe()
  }

}
