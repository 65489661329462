<div
  class="course-structure-container"
  *ngIf="currentCourse && panelView == 'listview'"
>
  <div *ngFor="let topic of currentCourse.topics | keyvalue : valueAscOrder">
    <app-course-structure-topic
      [topic]="topic.value"
      [id]="topic.key"
    ></app-course-structure-topic>
  </div>
</div>

<div class="addNewTopicWrapper" *ngIf="!areThereTopics()">
  <button
    i18n="add new topic btn| add new topic button@@addNewTopicBTN"
    class="addTopic"
    (click)="addTopic()"
  >
    <mat-icon class="material-icons-outlined plusIcon">add</mat-icon> Topic
  </button>
</div>

<div *ngIf="currentCourse && panelView == 'tabview'">
  <p-tabView>
    <p-tabPanel
      [header]="topic.value.index + '.' + topic.value.title"
      *ngFor="let topic of currentCourse.topics | keyvalue : valueAscOrder"
    >
      <app-course-structure-topic
        [id]="topic.key"
        [topic]="topic.value"
      ></app-course-structure-topic>
    </p-tabPanel>
  </p-tabView>
</div>
