<app-loading-screen *ngIf="isLoading"></app-loading-screen>
<app-validator *ngIf="isValidating"></app-validator>
<app-hive-popup *ngIf="isPopup"></app-hive-popup>
<app-terms></app-terms>

<top-navigation></top-navigation>

<div class="app-container">
  <router-outlet></router-outlet>
</div>
<p-toast position="top-right"></p-toast>
