import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserLandingPageModel } from '../user-landing-page.model';
import { KeyValue } from '@angular/common';
import { UserLandingPageService } from '../user-landing-page.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-landing-page-editor',
  templateUrl: './user-landing-page-editor.component.html',
  styleUrls: ['./user-landing-page-editor.component.scss'],
})
export class UserLandingPageEditorComponent implements OnInit, OnDestroy {
  landingPageData: UserLandingPageModel;
  landingPageDataSub: Subscription;

  selectedSection;
  selectedSectionSub: Subscription;

  private onCompare(
    _left: KeyValue<any, any>,
    _right: KeyValue<any, any>
  ): number {
    return -1;
  }

  constructor(private userLandingPageService: UserLandingPageService) {}

  ngOnInit(): void {
    // this.sidebarService.setCurrentComponentPath('default');
    this.landingPageDataSub =
      this.userLandingPageService.selectedLandingPage.subscribe((data) => {
        this.landingPageData = data;
        this.userLandingPageService.setSelectedSection(
          this.landingPageData.header
        );
      });

    this.selectedSectionSub =
      this.userLandingPageService.selectedSection.subscribe((data) => {
        this.selectedSection = data;
      });
  }

  unSelectTemplate() {
    this.userLandingPageService.setSelectedLandingPage(null);
  }

  onChangeSelectedSection(sectionName) {
    if (sectionName === 'header') {
      this.userLandingPageService.setSelectedSection(
        this.landingPageData.header
      );
    } else if (sectionName === 'about') {
      this.userLandingPageService.setSelectedSection(
        this.landingPageData.about
      );
    } else if (sectionName === 'info-bullets') {
      this.userLandingPageService.setSelectedSection(
        this.landingPageData.info_bullets
      );
    } else if (sectionName === 'courses') {
      this.userLandingPageService.setSelectedSection(
        this.landingPageData.courses
      );
    }
  }

  ngOnDestroy(): void {
    this.landingPageDataSub.unsubscribe();
    // this.selectedSectionSub.unsubscribe();
  }
}
