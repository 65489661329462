<mat-dialog-content class="mat-typography">
  <h3>You have unsaved changes</h3>

  <p>are you sure you want yoe exit the page before saving?</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    [mat-dialog-close]="false"
    color="primary"
  >
    Cancel
  </button>
  <button mat-button [mat-dialog-close]="true" color="primary">Ok</button>
</mat-dialog-actions>
