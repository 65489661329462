import { CourseSettingsComponent } from './course-panel/components/course-settings/course-settings.component';
import { CourseResourcesComponent } from './course-panel/components/course-resources/course-resources.component';
import { CourseLandingPageComponent } from './course-panel/components/course-landing-page/course-landing-page.component';
import { CourseForumComponent } from './course-panel/components/course-forum/course-forum.component';
import { CourseDetailsComponent } from './course-panel/components/course-details/course-details.component';
import { CourseFinanceComponent } from './course-panel/components/course-finance/course-finance.component';
import { CourseStructureModule } from './course-panel/components/course-structure/course-structure.module';
import { CoursePanelComponent } from './course-panel/course-panel.component';

import { PageTitleModule } from './../shared/page-title/page-title.module';
import { SidebarHiveModule } from '../shared/sidebar-hive/sidebar-hive.module';
import { CourseRoutes } from './course.routing';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { ButtonModule } from 'primeng/button';
import { SelectedSectionDirective } from './selected-section.directive';

@NgModule({
  imports: [
    CommonModule,
    CourseRoutes,
    SidebarHiveModule,
    MatIconModule,
    PageTitleModule,
    CourseStructureModule,
    ButtonModule,
  ],
  declarations: [	
    CoursePanelComponent,
    CourseFinanceComponent,
    CourseDetailsComponent,
    CourseForumComponent,
    CourseLandingPageComponent,
    CourseResourcesComponent,
    CourseSettingsComponent,
      SelectedSectionDirective
   ],
  exports: [],
})
export class CourseModule {}
