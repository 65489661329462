import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MessageTypes } from 'src/app/shared/interfaces-enums/message-types.enum';

@Injectable({
  providedIn: 'root',
})
export class ErrorsHandlerService {
  message: Subject<{
    severity: MessageTypes;
    summary: string;
    detail: string;
  }> = new Subject();
  constructor() {}

  setMessage(severity: MessageTypes, summary: string, detail: string) {
    this.message.next({ severity, summary, detail });
  }

  setError(error:any){
    if (error.code === 'permission-denied') {
      this.setMessage(
        MessageTypes.error,
        'permission-denied',
        'You do not have permission to redirect to course editor.'
      );
    } else {
      this.setMessage(
        MessageTypes.error,
        'error',
        'An error occurred. Please try again later.'
      );
    }
  }
}
