import { CreateCourseService } from './../../../hive/create-course/create-course.service';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, GuardsCheckEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorsHandlerService } from 'src/app/core/services/errors-handler.service';
import { CourseService } from 'src/app/course/course.service';
import { ICourseForm } from 'src/app/course/interfaces-enums/course';
import { CreateCourseComponent } from 'src/app/hive/create-course/create-course.component';
import { UserService } from 'src/app/hive/user/user.service';
import { HivePopupService } from 'src/app/shared/hive-popup/hive-popup.service';
import { MessageTypes } from 'src/app/shared/interfaces-enums/message-types.enum';
import { UserRole } from 'src/app/shared/interfaces-enums/user-role.enum';
import { IUser } from '../../../hive/user/interfaces-enums/IUser';
import { UnsavedChangesService } from 'src/app/shared/unsaved-changes/unsaved-changes.service';
import { CourseEditorService } from 'src/app/course/course-editor/course-editor.service';

@Component({
  selector: 'top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss'],
})
export class TopNavigationComponent implements OnInit, OnDestroy {
  currentUser: IUser;
  currentUserSub: Subscription;
  isSaving: boolean = false;

  routeSub: Subscription;
  userRuleInCourseSub: Subscription;
  userRuleInCourse: UserRole;
  onStudentView = false;

  currentRoute: string;
  canSave: boolean = false;

  save = $localize`:@@save:save`;
  saving = $localize`:@@saving:saving...`;

  messagesIcon: boolean = false;
  notificationsIcon: boolean = false;
  userIcon: boolean = false;

  languageList = ['English', 'Hebrew', 'Russian'];
  selectedLanguage = this.languageList[0];

  constructor(
    private userService: UserService,
    private courseService: CourseService,
    private router: Router,
    private popupService: HivePopupService,
    public dialog: MatDialog,
    private createCourseService: CreateCourseService,
    private errorsHandlerService: ErrorsHandlerService,
    private unsavedChangesService: UnsavedChangesService,
    private courseEditorService: CourseEditorService
  ) {}

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    this.canSave = this.canShowSave();
    this.routeSub = this.router.events.subscribe((data) => {
      if (data instanceof GuardsCheckEnd && data.shouldActivate) {
        this.currentRoute = data.url;
        this.canSave = this.canShowSave();
      }
    });

    this.currentUserSub = this.userService.userSubject.subscribe((data) => {
      if (data) {
        this.currentUser = data;
        if (!this.currentUser.image) {
          this.currentUser.image = '/assets/user_img_deafult.png';
        }
      }
    });

    this.userRuleInCourseSub = this.courseService.userRuleInCourseSub.subscribe(
      (role: UserRole) => {
        if (role) {
          this.userRuleInCourse = role;
          this.canSave = this.canShowSave();
          this.onStudentView = this.courseService.onStudentView;
        }
      }
    );
  }

  getSave() {
    if (this.isSaving) {
      return this.saving;
    } else {
      return this.save;
    }
  }

  ngOnDestroy() {
    this.routeSub?.unsubscribe();
    this.currentUserSub?.unsubscribe();
    this.userRuleInCourseSub?.unsubscribe();
  }

  onSave() {
    this.courseEditorService.updateCourse();
    this.isSaving = true;
    const { batch, firebaseCourse } =
      this.courseService.updateCurrentCourseDB();
    batch.subscribe({
      error: (err) => {
        this.errorsHandlerService.setMessage(
          MessageTypes.error,
          err.code,
          err.message
        );
      },
      complete: () => {
        this.errorsHandlerService.setMessage(
          MessageTypes.success,
          `Course: ${firebaseCourse.courses.title}`,
          'was successfully saved!'
        );
        this.isSaving = false;
        this.unsavedChangesService.setUnsavedChanges(false);
      },
    });
  }

  canShowSave() {
    if (
      (!this.courseService.onStudentView &&
        this.currentRoute.indexOf('/structure') > 0 &&
        this.courseService.userRuleInCourse === UserRole.teacher) ||
      this.currentRoute.indexOf('/editor') > 0
    ) {
      return true;
    }
    return false;
  }

  toggleHeaderMenus(menuType) {
    if (menuType === 'messages') {
      this.userIcon = false;
      this.notificationsIcon = false;
      this.messagesIcon = !this.messagesIcon;
    }
    if (menuType === 'notifications') {
      this.userIcon = false;
      this.messagesIcon = false;
      this.notificationsIcon = !this.notificationsIcon;
    }
    if (menuType === 'userMenu') {
      this.messagesIcon = false;
      this.notificationsIcon = false;
      this.userIcon = !this.userIcon;
    }
  }

  changeLanguage(lung) {
    console.log(lung);
    this.languageList.forEach((value) => {
      if (value === lung) {
        this.selectedLanguage = value;
      }
    });
    this.userIcon = false;
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.messagesIcon = false;
    this.notificationsIcon = false;
    this.userIcon = false;
  }

  onNavigateClick(route: string) {
    this.router.navigateByUrl(route).then();
  }

  logOut() {
    this.userService.auth.signOut().then(() => {
      this.currentUser = null;
      this.router.navigateByUrl('/login');
    });
  }

  showTerms() {
    this.popupService.showTermsModal();
  }

  openCreateCourseDialog(): void {
    const dialogRef = this.dialog.open(CreateCourseComponent);

    dialogRef.afterClosed().subscribe((createCourseForm) => {
      if (createCourseForm && createCourseForm.valid) {
        const data: ICourseForm = {
          title: '',
        };
        for (const key in createCourseForm.value) {
          if (createCourseForm.value[key]) {
            data[key] = createCourseForm.value[key];
          }
        }
        this.createCourseService.createCourse(data);
      }
    });
  }
}
