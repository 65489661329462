import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { KeyValue } from '@angular/common';

import { Subscription } from 'rxjs';
import { CourseStructureService } from '../course-structure.service';
import { ValidatorService } from 'src/app/shared/validator/validator.service';
import { CourseService } from 'src/app/course/course.service';
import {
  ICourseLesson,
  ICourseSection,
  ICourseTopic,
} from 'src/app/course/interfaces-enums/course';
import { CollectionTypes } from 'src/app/course/interfaces-enums/collection-types.enum';

interface Parents {
  topic: string;
}

@Component({
  selector: 'app-course-structure-lesson',
  templateUrl: './course-structure-lesson.component.html',
  styleUrls: ['./course-structure-lesson.component.scss'],
})
export class CourseStructureLessonComponent implements OnInit, OnDestroy {
  @Input() lesson: ICourseLesson;
  @Input() id: string;
  @Input() parents: Parents;

  isValid;
  isValidSub: Subscription;

  canEdit: boolean;
  canEditSub: Subscription;

  isOpenFolder: string;

  lessonNameEdit = false;

  isTeacher: boolean;
  @ViewChild('lessonName') lessonName: ElementRef;

  constructor(
    private courseService: CourseService,
    private validator: ValidatorService,
    private structureService: CourseStructureService,
    private courseStructureService: CourseStructureService
  ) {}

  ngOnInit(): void {
    this.isTeacher = this.structureService.isTeacher();

    this.canEditSub = this.structureService.canEdit.subscribe((data) => {
      this.canEdit = data;
    });

    this.isValidSub = this.structureService.canEdit.subscribe((status) => {
      this.isValid = status;
    });

  }

  valueAscOrder = (
    a: KeyValue<string, ICourseTopic>,
    b: KeyValue<string, ICourseTopic>
  ): number => {
    return a.value.index > b.value.index
      ? 1
      : b.value.index > a.value.index
      ? -1
      : 0;
  };

  onLessonNameEdit() {
    if (this.lessonNameEdit === false) {
      this.structureService.canEditStatus(false);
    } else {
      this.structureService.canEditStatus(true);
    }
    this.lessonNameEdit = !this.lessonNameEdit;
    setTimeout(() => {
      if (this.lessonName) {
        this.lessonName.nativeElement.focus();
      }
    }, 100);
  }

  onLessonNameSave(lessonName: string) {
    this.lesson.title = lessonName;
    this.courseService.updateCurrentCourseByPath(
      { ...this.parents, lesson: this.id },
      this.lesson
    );
    this.onLessonNameEdit();
  }

  onDelete() {
    this.validator.getResult().subscribe((result) => {
      if (result) {
        this.courseService.removeFromCourseByPath({
          ...this.parents,
          lesson: this.id,
        });
      }
    });
  }

  addSection(): void {
    this.courseStructureService.addSection(this.lesson, this.parents.topic);
  }

  ngOnDestroy(): void {
    this.isValidSub.unsubscribe();
    this.canEditSub.unsubscribe();
  }

  getTopicColor(): string {
    return this.courseService.getCurrentTopicColor(this.parents.topic);
  }

  onToggle(event: boolean): void {
    this.lesson.metaData.open = event;
  }
}
