import { Component, OnInit } from '@angular/core';
import { ImageElementService } from '../image-element.service';
import { HivePopupService } from 'src/app/shared/hive-popup/hive-popup.service';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit {
  imageUrl: string;
  addNewUrl: boolean = false;
  isValid: boolean = false;
  isExists: boolean = false;
  isExistsIndex: number;

  urlValue: string = '';

  imageList: string[];
  constructor(
    private popUpService: HivePopupService,
    private imageService: ImageElementService,
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('user-image-list')) {
      this.imageList = JSON.parse(localStorage.getItem('user-image-list'));
    } else {
      let imageArr = [
        'https://image.freepik.com/free-photo/light-bulb-made-with-yellow-ball-yarn-blue-backdrop_23-2147873538.jpg',
        'https://image.freepik.com/free-vector/webinar-concept-illustration_114360-4764.jpg',
        'https://image.freepik.com/free-photo/top-view-desk-arrangement-with-laptop_23-2148128342.jpg',
        'https://image.freepik.com/free-photo/top-view-desk-concept-with-laptop_23-2148604914.jpg',
        'https://image.freepik.com/free-photo/laptop-with-different-applications-installed_1134-89.jpg',
        'https://image.freepik.com/free-photo/top-view-coworkers-team-working-office_329181-12379.jpg',
      ];
      localStorage.setItem('user-image-list', JSON.stringify(imageArr));
      this.imageList = JSON.parse(localStorage.getItem('user-image-list'));
    }
  }

  print(data) {
    console.log(data);
  }

  closePopUp(url?: string) {
    if (url) {
      const img = new Image();
      img.src = url;
      let id = this.imageService.getImageID();
      this.imageService.setImageElementSize(
        img.naturalHeight,
        img.naturalWidth,
        id
      );

      this.popUpService.finishPopupResult(url);
    } else {
      this.popUpService.finishPopupResult(false);
    }
    this.imageService.setImageID(null);
  }

  onSelectImage(url) {
    this.imageUrl = url;
  }

  checkIfImageExists(url, callback) {
    const img = new Image();

    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };
      img.onerror = () => {
        callback(false);
      };
    }
  }

  onAddNewImage(url) {
    let isExistsIList: boolean = false;
    this.checkIfImageExists(url, (exists) => {
      if (exists) {
        this.isValid = false;
        this.imageList.forEach((value, index) => {
          if (value === url) {
            // this.imageList.splice(index, 1);
            isExistsIList = true;
            this.isExists = true;
            this.isExistsIndex = index;
            console.log('url already exists in your gallery');
          }
        });
        if (!isExistsIList) {
          this.imageList.push(url);
          localStorage.setItem(
            'user-image-list',
            JSON.stringify(this.imageList)
          );
          this.isExistsIndex = null;
          this.popUpService.finishPopupResult(url);
        }
      } else {
        this.isValid = true;
      }
    });

    this.urlValue = null;
  }

  reloadValidations() {
    this.isValid = false;
    this.isExists = false;
  }
}
