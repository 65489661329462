<div class="loader-wrapper">
  <div class="loader-container">

    <svg class="loader-bee" viewBox="0 0 560 557" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path opacity="0.6" d="M356.25 104.464C360.46 106.106 362.602 110.034 360.763 114.14C358.858 118.238 354.223 119.466 350.159 117.596C346.007 115.75 343.881 111.757 345.729 107.643C347.584 103.691 351.819 102.74 355.825 104.309C355.988 104.366 356.136 104.431 356.25 104.464Z" fill="black"/>
        <path opacity="0.65" d="M320.794 98.0641C319.952 102.463 315.611 105.317 311.164 104.407C306.741 103.569 303.864 99.2512 304.763 94.8847C305.621 90.445 309.954 87.6396 314.369 88.469C314.867 88.5422 315.317 88.7292 315.726 88.8919C319.388 90.2905 321.628 94.1366 320.794 98.0641Z" fill="black"/>
        <path opacity="0.55" d="M395.606 125.369C399.145 128.142 399.84 133.257 397.036 136.826C394.306 140.372 389.131 140.99 385.509 138.249C381.978 135.477 381.356 130.338 384.087 126.8C386.392 123.914 390.275 122.962 393.521 124.231C394.24 124.507 394.951 124.914 395.606 125.369Z" fill="black"/>
        <path opacity="0.7" d="M278.079 92.7547C278.202 97.2188 274.67 100.984 270.165 101.154C265.636 101.325 261.859 97.8205 261.688 93.3239C261.516 88.8272 265.039 85.0705 269.56 84.8998C270.754 84.8347 271.841 85.0299 272.863 85.4283C275.814 86.5667 277.94 89.372 278.079 92.7547Z" fill="black"/>
        <path opacity="0.5" d="M427.619 156.79C430.472 160.295 429.917 165.426 426.36 168.239C422.845 171.069 417.703 170.475 414.842 166.979C412.013 163.433 412.61 158.368 416.109 155.53C418.488 153.651 421.553 153.277 424.202 154.294C425.518 154.814 426.736 155.627 427.619 156.79Z" fill="black"/>
        <path opacity="0.75" d="M235.2 97.382C236.443 101.708 233.868 106.188 229.519 107.416C225.178 108.636 220.673 106.083 219.447 101.757C218.237 97.382 220.804 92.9016 225.129 91.7226C226.919 91.194 228.726 91.3729 230.303 91.9828C232.633 92.8935 234.522 94.7881 235.2 97.382Z" fill="black"/>
        <path opacity="0.45" d="M451.491 194.658C453.469 198.731 451.728 203.594 447.649 205.505C443.545 207.465 438.656 205.741 436.735 201.683C434.822 197.617 436.555 192.763 440.585 190.836C442.735 189.803 445.073 189.836 447.101 190.624C448.932 191.324 450.559 192.714 451.491 194.658Z" fill="black"/>
        <path opacity="0.8" d="M194.611 111.757C196.818 115.693 195.33 120.653 191.39 122.8C187.392 124.922 182.438 123.515 180.255 119.531C178.105 115.612 179.503 110.676 183.517 108.505C185.683 107.301 188.25 107.187 190.425 108.09C192.15 108.757 193.662 109.993 194.611 111.757Z" fill="black"/>
        <path opacity="0.4" d="M466.002 236.973C466.975 241.372 464.162 245.697 459.731 246.657C455.317 247.625 450.919 244.803 450.011 240.436C449.03 236.037 451.842 231.711 456.257 230.752C457.925 230.41 459.494 230.549 460.982 231.118C463.427 232.069 465.405 234.224 466.002 236.973Z" fill="black"/>
        <path opacity="0.85" d="M158.378 134.989C161.419 138.323 161.158 143.462 157.806 146.471C154.405 149.463 149.238 149.203 146.213 145.877C143.205 142.486 143.515 137.372 146.867 134.363C149.214 132.249 152.5 131.777 155.296 132.859C156.416 133.29 157.487 133.997 158.378 134.989Z" fill="black"/>
        <path opacity="0.35" d="M468.553 280.776C468.553 280.776 468.553 280.776 468.544 280.825C467.016 284.72 465.577 289.225 461.048 289.111C456.551 289.054 453.388 285.443 453.494 280.996C453.567 276.475 457.524 273.002 462.07 273.076C463.059 273.092 463.263 273.002 464.105 273.344C467.22 274.564 469.754 277.743 468.553 280.776Z" fill="black"/>
        <path opacity="0.9" d="M128.53 165.889C132.275 168.434 133.207 173.524 130.648 177.24C128.097 180.916 122.979 181.851 119.243 179.289C115.54 176.752 114.584 171.711 117.159 167.995C119.341 164.823 123.429 163.669 126.879 165.01C127.435 165.23 128.007 165.563 128.53 165.889Z" fill="black"/>
        <path opacity="0.3" d="M464.154 325.793C462.977 330.119 458.497 332.737 454.115 331.639C449.79 330.476 447.142 326.012 448.27 321.662C449.431 317.336 453.911 314.718 458.252 315.872C458.587 315.937 458.889 316.059 459.2 316.173C463.026 317.661 465.217 321.776 464.154 325.793Z" fill="black"/>
        <path opacity="0.95" d="M113.913 214.392C113.864 214.489 113.848 214.546 113.799 214.644C113.766 214.742 113.709 214.888 113.643 215.051C112.008 219.254 107.324 221.336 103.073 219.742C98.8627 218.108 96.729 213.384 98.3804 209.188C98.4131 209.082 98.4376 209.033 98.4703 208.928C98.5357 208.773 98.6011 208.627 98.6501 208.48C100.302 204.285 105.027 202.211 109.253 203.845C113.48 205.48 115.54 210.196 113.913 214.392Z" fill="black"/>
        <path opacity="0.25" d="M444.485 356.44C448.491 358.578 450.036 363.49 447.894 367.425C445.793 371.45 440.863 372.971 436.825 370.881C432.86 368.759 431.315 363.848 433.399 359.888C435.386 356.163 439.817 354.57 443.643 356.041C443.937 356.172 444.174 356.318 444.485 356.44Z" fill="black"/>
        <path opacity="0.2" d="M421.651 392.852C425.069 395.804 425.445 400.959 422.485 404.35C419.477 407.724 414.294 408.05 410.885 405.098C407.525 402.163 407.14 396.999 410.108 393.608C412.495 390.982 416.133 390.177 419.248 391.38C420.106 391.722 420.965 392.226 421.651 392.852Z" fill="black"/>
        <path opacity="0.15" d="M391.052 422.986C393.635 426.662 392.768 431.744 389.114 434.346C385.402 436.923 380.293 436.102 377.693 432.419C375.077 428.784 375.944 423.71 379.598 421.1C381.953 419.449 384.879 419.181 387.348 420.14C388.787 420.693 390.038 421.653 391.052 422.986Z" fill="black"/>
        <path opacity="0.1" d="M354.206 445.266C355.906 449.429 353.928 454.194 349.742 455.894C345.491 457.577 340.717 455.601 339.057 451.462C337.373 447.258 339.327 442.542 343.513 440.834C345.549 439.997 347.707 440.013 349.571 440.745C351.615 441.542 353.339 443.128 354.206 445.266Z" fill="black"/>
        <path opacity="0.05" d="M313.256 458.578C313.91 463.026 310.877 467.156 306.438 467.88C301.95 468.587 297.748 465.554 297.053 461.09C296.391 456.642 299.416 452.512 303.904 451.812C305.359 451.56 306.806 451.715 308.139 452.219C310.779 453.243 312.782 455.602 313.256 458.578Z" fill="black"/>
        <path opacity="0.02" d="M270.288 462.122C269.937 466.594 265.988 469.953 261.492 469.611C257.012 469.262 253.627 465.334 253.979 460.862C254.347 456.341 258.271 453.039 262.759 453.381C263.617 453.422 264.369 453.592 265.13 453.893C268.392 455.162 270.615 458.463 270.288 462.122Z" fill="black"/>
        <path d="M86.4898 336.429C82.2655 320.699 83.2042 305.383 87.8997 293.398C92.5959 281.41 100.874 273.076 111.27 270.284C121.667 267.492 133.006 270.558 143.076 278.581C153.144 286.602 161.629 299.387 165.853 315.117C170.077 330.848 169.139 346.163 164.443 358.149C159.747 370.136 151.469 378.47 141.072 381.262C130.676 384.054 119.336 380.988 109.267 372.966C99.1986 364.945 90.714 352.16 86.4898 336.429Z" fill="#FAAD00" stroke="black" stroke-width="10"/>
        <path d="M107.547 258.079L110 266" stroke="black" stroke-width="19" stroke-linecap="round"/>
        <path d="M144.798 315.419C137.042 312.001 117.531 309.54 101.536 327.036M155.61 294.23C147.854 290.813 110.409 275.378 85.7242 312.997" stroke="black" stroke-width="12"/>
        <circle cx="144.287" cy="388.252" r="24.2892" transform="rotate(164.969 144.287 388.252)" fill="#FAAD00" stroke="black" stroke-width="10"/>
        <ellipse cx="140.266" cy="373.276" rx="21.5362" ry="18.0904" transform="rotate(164.969 140.266 373.276)" fill="black" fill-opacity="0.1"/>
        <path d="M127.302 437.936C133.86 437.614 145.351 425.429 142.223 413.782M183.876 422.743C178.04 425.75 161.99 420.961 158.863 409.313" stroke="black" stroke-width="11" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M150.225 309.559C157.663 296.043 167.569 285.829 177.335 280.13C187.203 274.373 196.068 273.63 202.406 277.118C208.745 280.606 212.862 288.492 213.279 299.91C213.691 311.209 210.364 325.043 202.926 338.56C195.489 352.076 185.582 362.29 175.817 367.988C165.949 373.746 157.084 374.488 150.745 371.001C144.407 367.513 140.289 359.626 139.873 348.209C139.46 336.91 142.788 323.075 150.225 309.559Z" fill="white" stroke="black" stroke-width="10"/>
        <path d="M99.7689 323.109C86.5608 315.137 72.8704 311.259 61.5636 311.219C50.139 311.179 42.0946 314.978 38.356 321.172C34.6174 327.366 35.0047 336.254 40.3635 346.344C45.667 356.33 55.477 366.636 68.6851 374.608C81.8931 382.58 95.5836 386.458 106.89 386.498C118.315 386.538 126.359 382.739 130.098 376.545C133.837 370.351 133.449 361.463 128.091 351.373C122.787 341.387 112.977 331.081 99.7689 323.109Z" fill="white" stroke="black" stroke-width="10"/>
        <path d="M169.971 313.947C158.487 324.935 151.028 347.386 153.573 366.463C149.399 363.718 146.817 360.121 145.4 353.623C143.541 345.101 144.37 325.296 159.055 303.991C173.951 282.378 191.288 278.152 198.081 281.053C204.114 283.629 206.166 288.762 207.457 293.57C200.215 293.797 184.325 300.212 169.971 313.947Z" fill="#C4C4C4" fill-opacity="0.4"/>
        <path d="M84.7969 336.806C100.259 340.568 117.985 356.282 125.348 374.09C127.587 369.617 128.021 365.205 125.99 358.863C123.326 350.546 112.674 333.801 89.2624 322.703C65.5132 311.446 48.3699 316.48 43.9383 322.401C40.0028 327.66 40.7995 333.137 42.0924 337.951C48.4816 334.515 65.4691 332.104 84.7969 336.806Z" fill="#C4C4C4" fill-opacity="0.4"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="560" height="557" fill="white"/>
        </clipPath>
      </defs>
    </svg>



  </div>
</div>

