
<div class="main-code-panel-wrapper" [formGroup]="panelFormGroup" *ngIf="categoryName">
    <div class="code-panel-category-selector" >
      <div formGroupName="{{this.panelConfig.category}}">

        <label class="checkbox-container" for="{{panelConfig.category}}">{{panelConfig.category}}:
          <input type="checkbox" #checkbox
                 (click)="!checkbox.checked? uncheckRadios():checkBasic()"
                 id="{{panelConfig.category}}"
                 name="IsSelected"
                 value="{{categoryName}}"
                 formControlName="IsSelected">
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <div class="code-panel-category-types">
      <div class="code-panel-category-types-content" *ngFor="let config of panelConfig.categoryTypes">
        <div formGroupName="{{this.panelConfig.category}}">
          <label  class="radioButton-container" for="{{panelConfig.category + config.name}}">{{config.name}}
            <input type="radio"
                   (click)="checkbox.checked = true; checkCheckbox()"
                   id="{{panelConfig.category + config.name}}"
                   name="Type"
                   value="{{config.name}}"
                   formControlName="Type">
            <span class="checkmark-radio"></span>
            <br>
            <img src="{{config.preview}}" alt="{{config.name}}">
          </label>

        </div>
      </div>
      <hr *ngIf="panelConfig.category === 'Editor'">
    </div>
</div>
