import { TopNavigationComponent } from './components/top-navigation/top-navigation.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ErrorComponent } from './components/error/error.component';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DialogModule } from 'primeng/dialog';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
  declarations: [
    ErrorComponent,
    TopNavigationComponent,
    LoadingScreenComponent,
  ],
  exports: [ErrorComponent, TopNavigationComponent, LoadingScreenComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatToolbarModule,
    DialogModule,
    MatBadgeModule,
  ],
})
export class LayoutModule {}
