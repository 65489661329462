import {Component, Input, OnInit} from '@angular/core';
import {messageModel} from '../../../../core/firebase-handle/message.model';

@Component({
  selector: 'app-inbox-message',
  templateUrl: './inbox-message.component.html',
  styleUrls: ['./inbox-message.component.scss']
})
export class InboxMessageComponent implements OnInit {
  @Input('id') id: string;
  @Input('data') data: messageModel;

  constructor() { }

  ngOnInit(): void {
  }

}
