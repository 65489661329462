import { ErrorsHandlerService } from './../../core/services/errors-handler.service';
import { CourseDataService } from './../../course/course-data.service';
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { ICourseDb, ICourseForm } from 'src/app/course/interfaces-enums/course';
import { CollectionTypes } from 'src/app/course/interfaces-enums/collection-types.enum';
import { LoadingScreenService } from 'src/app/layout/components/loading-screen/loading-screen.service';
import { Router } from '@angular/router';
import { FirestoreHandleService } from 'src/app/core/firebase-handle/firestore-handle.service';
import { MessageTypes } from 'src/app/shared/interfaces-enums/message-types.enum';
import { UserRole } from 'src/app/shared/interfaces-enums/user-role.enum';
import { CourseService } from 'src/app/course/course.service';
import { CourseStructureService } from 'src/app/course/course-panel/components/course-structure/course-structure.service';
import { IUserCourses } from '../user/interfaces-enums/IUser';

@Injectable({
  providedIn: 'root',
})
export class CreateCourseService {
  constructor(
    private userService: UserService,
    private firestoreService: FirestoreHandleService,
    private loading: LoadingScreenService,
    private router: Router,
    private courseDataService: CourseDataService,
    private errorsHandlerService: ErrorsHandlerService,
    private courseService: CourseService,
    private courseStructureService: CourseStructureService
  ) {}

  createCourse(createCourseForm: ICourseForm) {
    const timeStamp = this.firestoreService.getTimeStamp();
    if (!createCourseForm.image) {
      createCourseForm.image =
        'https://images.pexels.com/photos/321552/pexels-photo-321552.jpeg';
    }
    let currentUser = this.userService.getUserDetails();
    let createdCourse = {
      created: timeStamp,
      description: '',
      creator_id: currentUser.uid,
      id: this.firestoreService.generateDocumentId('/courses'),
      last_updated: timeStamp,
      version: '0.1',
      type: CollectionTypes.course,
      creator: currentUser.name,
      deleted: false,
      tags: [],
      ...createCourseForm,
    } as ICourseDb;

    this.loading.setIsActive(true);
    const courseData = {
      ...createdCourse,
      topics: {},
    };
    this.courseService.updateCurrentCourse(courseData);
    this.courseStructureService.addTopic(courseData);
    const { batch, firebaseCourse } = this.courseDataService.createCourse(
      createdCourse,
      this.courseService.getCurrentCourseSync()
    );
    this.courseService.updateCurrentCourse(
      this.courseDataService.dbDataToCourse(firebaseCourse)
    );
    batch.subscribe({
      error: (err) => {
        this.loading.setIsActive(false);
        this.errorsHandlerService.setMessage(
          MessageTypes.error,
          err.code,
          err.message
        );
      },
      complete: () => {
        let userCourse: IUserCourses = {
          id: createdCourse.id,
          title: createdCourse.title,
          last_updated: timeStamp,
          image: createdCourse.image,
          user_type: UserRole.teacher,
          creator: createdCourse.creator,
          created: timeStamp,
          deleted: false,
        };
        currentUser.user_courses.push(userCourse);
        this.userService.updateUser(currentUser).subscribe({
          error: (err) => {
            this.errorsHandlerService.setMessage(
              MessageTypes.error,
              err.code,
              err.message
            );
          },
          complete: () => {
            this.loading.setIsActive(false);
            this.router.navigateByUrl(`/course/${createdCourse.id}/structure`);
          },
        });
      },
    });
  }
}
