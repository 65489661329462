import { TabViewModule } from 'primeng/tabview';
import { CourseStructureTopicComponent } from './course-structure-topic/course-structure-topic.component';
import { CourseStructureLessonComponent } from './course-structure-lesson/course-structure-lesson.component';
import { CourseStructureLayerComponent } from './course-structure-layer/course-structure-layer.component';
import { StructureHeaderManagerComponent } from './structure-header-manager/structure-header-manager.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseStructureComponent } from './course-structure.component';
import { CourseStructureSectionComponent } from './course-structure-section/course-structure-section.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FormsModule } from '@angular/forms';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    SelectButtonModule,
    ColorPickerModule,
    TabViewModule,
  ],
  declarations: [
    CourseStructureComponent,
    StructureHeaderManagerComponent,
    CourseStructureLayerComponent,
    CourseStructureSectionComponent,
    CourseStructureLessonComponent,
    CourseStructureTopicComponent,
  ],
  exports: [
    CourseStructureComponent,
    StructureHeaderManagerComponent,
    CourseStructureLayerComponent,
    CourseStructureSectionComponent,
    CourseStructureLessonComponent,
    CourseStructureTopicComponent,
  ],
})
export class CourseStructureModule {}
