import {Injectable} from '@angular/core';
import {initializeApp} from 'firebase/app';
import { firebaseConfig } from '../../environments/environment';

@Injectable({providedIn: 'root'})

export class globalVariables {
  // public apiUrl: string = "http://api.skillbee.io"
  public apiUrl = 'http://localhost:8000';
  public tokenHeader = {'skillbee-token': localStorage.getItem('token_skillbee')};
  public firebaseConfig = firebaseConfig;
  public firebase_app = initializeApp(this.firebaseConfig);
}
