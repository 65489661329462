import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { UserService } from '../hive/user/user.service';

import { FirestoreHandleService } from '../core/firebase-handle/firestore-handle.service';
import { UserRole } from 'src/app/shared/interfaces-enums/user-role.enum';
import {
  ICourse,
  ICourseDb,
  ICourseLesson,
  ICourseSection,
  ICourseTopic,
  IFirebaseLayersDocuments,
} from './interfaces-enums/course';
import { LoadingScreenService } from '../layout/components/loading-screen/loading-screen.service';
import { CourseDataService } from './course-data.service';
import { CollectionTypes } from './interfaces-enums/collection-types.enum';
import { ErrorsHandlerService } from '../core/services/errors-handler.service';
import { MessageTypes } from '../shared/interfaces-enums/message-types.enum';
import { UnsavedChangesService } from '../shared/unsaved-changes/unsaved-changes.service';

interface CoursePath {
  topic: string;
  lesson?: string;
  section?: string;
  layer?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  private currentCourse: ICourse;
  private courseHistory: ICourse[] = [];
  private courseID: string;
  courseFromDb: ICourse;
  courseHistoryIndex: number;
  courseSub: BehaviorSubject<ICourse> = new BehaviorSubject<ICourse>(null);
  courseIDSub: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  userRuleInCourseSub: BehaviorSubject<UserRole> =
    new BehaviorSubject<UserRole>(null);
  userRuleInCourse: UserRole;
  onStudentView = false;

  constructor(
    private loader: LoadingScreenService,
    private errorsHandlerService: ErrorsHandlerService,
    private userService: UserService,
    private firestoreService: FirestoreHandleService,
    private courseDataService: CourseDataService,
    private unsavedChangesService: UnsavedChangesService
  ) {}

  private _setCurrentCourse(course: ICourse): void {
    this.currentCourse = JSON.parse(JSON.stringify(course));
    this.courseSub.next(this.currentCourse);
    console.log('COURSE UPDATED');
  }

  private _getCourseCopy(): ICourse {
    return { ...this.currentCourse };
  }

  isUserTeacher(): UserRole {
    const userId = this.userService.getUserId();
    userId === this.currentCourse?.creator_id
      ? (this.userRuleInCourse = UserRole.teacher)
      : (this.userRuleInCourse = UserRole.student);

    this.setUserRuleInCourse(this.userRuleInCourse);
    return this.userRuleInCourse;
  }

  setUserRuleInCourse(role: UserRole): void {
    this.userRuleInCourseSub.next(role);
  }

  getCourseHistory(): ICourse[] {
    return this.courseHistory;
  }

  updateCourseHistoryList(course: ICourse): void {
    if (!course) {
      this.courseHistory = [
        JSON.parse(JSON.stringify(this.getCurrentCourseSync())),
      ];
      this.courseHistoryIndex = null;
    } else {
      if (this.courseHistoryIndex !== 0 && !this.courseHistoryIndex) {
        this.courseHistoryIndex = this.courseHistory.length - 1;
      }
      this.courseHistory = this.courseHistory.slice(
        0,
        this.courseHistoryIndex + 1
      );

      if (this.courseHistory.length < 10) {
        this.courseHistory.push(JSON.parse(JSON.stringify(this.currentCourse)));
        this.courseHistoryIndex++;
      } else if (this.courseHistory.length >= 10) {
        this.courseHistory.shift();
        this.courseHistory.push(JSON.parse(JSON.stringify(this.currentCourse)));
      }
    }
  }

  generateID(): string {
    return Math.random().toString(36).substr(2, 9);
  }

  updateCourseID(id: string): void {
    this.courseID = id;
    this.courseIDSub.next(this.courseID);
  }

  getCourseID(): string | null {
    if (this.currentCourse) {
      return this.currentCourse.id;
    } else {
      return null;
    }
  }

  updateCurrentCourseById(id: string): Observable<any> {
    return this.courseDataService.getCourseById(id).pipe(
      map((course) => {
        this.courseDataService.courseDbToCourseUi(course).subscribe({
          next: (data: IFirebaseLayersDocuments) => {
            const { topics, lessons, sections, sectionData } = data;
            this.courseFromDb = this.courseDataService.dbDataToCourse({
              courses: course,
              topics,
              lessons,
              sections,
              sectionData,
            });

            this._setCurrentCourse(this.courseFromDb);
            this.updateCourseID(id);
            return this._getCourseCopy();
          },
        });
      })
    );
  }

  setCourseToCourseFromDB() {
    this._setCurrentCourse(this.courseFromDb);
  }

  getCurrentCourseSync(): ICourse {
    return this._getCourseCopy();
  }

  courseDeleteByID(course: ICourse): void {
    this.loader.setIsActive(true);
    this.courseDataService
      .deleteCourse(course, this.userService.getUserDetails())
      .subscribe({
        error: (err) => {
          this.errorsHandlerService.setMessage(
            MessageTypes.error,
            err.code,
            err.message
          );
        },
        complete: () => {
          this.errorsHandlerService.setMessage(
            MessageTypes.success,
            `Course: ${course.title}`,
            'was successfully deleted!'
          );
          this.loader.setIsActive(false);
        },
      });
  }

  updateCurrentCourseByPath(
    path: CoursePath,
    data: ICourseTopic | ICourseLesson | ICourseSection
  ): void {
    const tempCourse = this._getCourseCopy();
    if (path.section && path.lesson && path.topic) {
      tempCourse.topics[path.topic].lessons[path.lesson].sections[
        path.section
      ] = { ...data } as ICourseSection;
    } else if (path.lesson && path.topic) {
      tempCourse.topics[path.topic].lessons[path.lesson] = {
        ...data,
      } as ICourseLesson;
    } else if (path.topic) {
      tempCourse.topics[path.topic] = { ...data } as ICourseTopic;
    }
    this.unsavedChangesService.setUnsavedChanges(true);
    this._setCurrentCourse(tempCourse);
  }

  removeFromCourseByPath(path: CoursePath): void {
    const tempCourse = this._getCourseCopy();
    if (!tempCourse.metaData) {
      tempCourse.metaData = {
        to_delete_section: [],
      };
    }

    // Utility function to decrement index values after deletion
    const decrementIndexes = (
      items: { [key: string]: ICourseTopic | ICourseLesson | ICourseSection },
      startIndex: number
    ) => {
      Object.values(items).forEach(
        (item: ICourseTopic | ICourseLesson | ICourseSection) => {
          if (item.index > startIndex) {
            item.index--;
          }
        }
      );
    };

    if (path.section && path.lesson && path.topic) {
      // Deleting a section
      const lesson = tempCourse.topics[path.topic].lessons[path.lesson];
      tempCourse.metaData.to_delete_section.push(path.section);

      const sectionIndex = lesson.sections[path.section].index;
      decrementIndexes(lesson.sections, sectionIndex);

      delete lesson.sections[path.section];
    } else if (path.lesson && path.topic) {
      // Deleting a lesson
      const lesson = tempCourse.topics[path.topic].lessons[path.lesson];
      Object.keys(lesson.sections).forEach((sectionId) => {
        tempCourse.metaData.to_delete_section.push(sectionId);
      });

      const lessonIndex = lesson.index;
      decrementIndexes(tempCourse.topics[path.topic].lessons, lessonIndex);

      delete tempCourse.topics[path.topic].lessons[path.lesson];
    } else if (path.topic) {
      // Deleting a topic
      const topic = tempCourse.topics[path.topic];
      Object.values(topic.lessons).forEach((lesson) => {
        Object.keys(lesson.sections).forEach((sectionId) => {
          tempCourse.metaData.to_delete_section.push(sectionId);
        });
      });

      const topicIndex = topic.index;
      decrementIndexes(tempCourse.topics, topicIndex);

      delete tempCourse.topics[path.topic];
    }
    this.unsavedChangesService.setUnsavedChanges(true);
    this._setCurrentCourse(tempCourse);
  }

  updateCurrentCourseDB() {
    const tempCourse = this._getCourseCopy();
    this.courseFromDb = JSON.parse(JSON.stringify(tempCourse));
    return this.courseDataService.initiateCourseDb(
      tempCourse,
      this.convertCourseUiToCourseDB(tempCourse)
    );
  }

  getCurrentTopicColor(topicID: string): string {
    return this.currentCourse.topics[topicID].color;
  }

  updateCurrentCourse(course: ICourse): void {
    this._setCurrentCourse(course);
  }

  undoInCourse(): void {
    if (this.courseHistoryIndex > 0) {
      this.courseHistoryIndex--;
      this._setCurrentCourse(
        JSON.parse(JSON.stringify(this.courseHistory[this.courseHistoryIndex]))
      );
    }
  }
  redoInCourse(): void {
    if (
      this.courseHistoryIndex < this.courseHistory.length - 1 &&
      this.courseHistoryIndex < 10
    ) {
      this.courseHistoryIndex++;
      this._setCurrentCourse(
        JSON.parse(JSON.stringify(this.courseHistory[this.courseHistoryIndex]))
      );
    }
  }

  randomColorGenerator(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  renameCourse(id: string, newName: string): Promise<void> {
    return new Promise<void>((resolve) => {
      const currentUser = this.userService.getUserDetails();
      for (const course of currentUser.user_courses) {
        if (course.id === id) {
          course.title = newName;
        }
      }
      this.currentCourse.title = newName;
      this.courseDataService
        .updateCourse(this.currentCourse, currentUser)
        .subscribe(() => {
          this._setCurrentCourse(this.currentCourse);
          resolve();
        });
    });
  }

  changeCourseImage(id: string, url: string): Promise<void> {
    return new Promise<void>((resolve) => {
      const currentUser = this.userService.getUserDetails();
      for (const course of currentUser.user_courses) {
        if (course.id === id) {
          course.image = url;
        }
      }
      this.currentCourse.image = url;
      this.courseDataService
        .updateCourse(this.currentCourse, currentUser)
        .subscribe(() => {
          this._setCurrentCourse(this.currentCourse);
          resolve();
        });
    });
  }

  createLayer(
    index: number,
    id: string,
    parent: string,
    creator_id: string,
    title = ''
  ) {
    return {
      index,
      title: title,
      id,
      metaData: { open: true, has_data_changed: false },
      parent,
      created: this.firestoreService.getTimeStamp(),
      creator_id,
    };
  }

  convertCourseUiToCourseDB(course: ICourse): ICourseDb {
    const dbCourse = {
      ...course,
    };
    delete dbCourse.topics;
    return { ...dbCourse, type: CollectionTypes.course };
  }

  getSelectedSectionElements(courseID: string, sectionID: string) {
    return this.courseDataService.getSelectedSectionElements(
      courseID,
      sectionID
    );
  }
}
