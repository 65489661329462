<div class="position-center landing-page-creator-container">
  <div class="landing-page-creator-wrapper" >
    <div *ngIf="!landingPageData" class="create-new-landing-page">
  <div class="new-landing-page-text-container">
    <h1 class="new-landing-page-title">Pick your landing page design </h1>
    <p>You can easily customize your colors, images and more.</p>
  </div>
  <div class="new-landing-page-choose-template-container">

    <div class="landing-page-template">
      <div class="first-landing-page-image landing-page-image"></div>
      <div class="template-btns">
          <button class="view-full-template" (click)="showBasicDialog('firstTemplate')">View</button>
          <p-dialog  class="view-full-template-header" appendTo="body"  modal="modal"
                     header="View Full Template" [(visible)]="firstTemplate" [style]="{width: '50vw', left: '25%', top: '12%'}">
            <img style="width: 100%" src="assets/images/landing_page/landing-page-1.jpg">
            <div class="view-full-template-footer" >
              <button class="cancel-btn" (click)="firstTemplate=false" >Cancel</button>
              <button class="choose-btn" (click)="firstTemplate=false ; selectTemplate('firstTemplate')" >Choose</button>
            </div>
          </p-dialog>
          <button class="select-template" (click)="selectTemplate('firstTemplate')">Select</button>
      </div>
    </div>

    <div class="landing-page-template">
      <div class="second-landing-page-image landing-page-image"></div>
        <div class="template-btns">
          <button class="view-full-template" (click)="showBasicDialog('secondTemplate')">View</button>
          <p-dialog  class="view-full-template-header" appendTo="body"  modal="modal"
                     header="View Full Template" [(visible)]="secondTemplate" [style]="{width: '50vw', left: '25%', top: '12%'}">
            <img style="width: 100%" src="assets/images/landing_page/landing-page-2.jpg">
            <div class="view-full-template-footer" >
              <button class="cancel-btn" (click)="secondTemplate=false" >Cancel</button>
              <button class="choose-btn" (click)="secondTemplate=false; selectTemplate('secondTemplate')" >Choose</button>
            </div>
          </p-dialog>
          <button class="select-template" (click)="selectTemplate('secondTemplate')">Select</button>
      </div>
    </div>

    <div class="landing-page-template">
      <div class="third-landing-page-image landing-page-image"></div>
      <div class="template-btns">
        <button class="view-full-template" (click)="showBasicDialog('thirdTemplate')">View</button>
        <p-dialog  class="view-full-template-header" appendTo="body"  modal="modal"
                   header="View Full Template" [(visible)]="thirdTemplate" [style]="{width: '50vw', left: '25%', top: '12%'}">
          <img style="width: 100%" src="assets/images/landing_page/landing-page-3.jpg">
          <div class="view-full-template-footer" >
            <button class="cancel-btn" (click)="thirdTemplate=false" >Cancel</button>
            <button class="choose-btn" (click)="thirdTemplate=false;selectTemplate('thirdTemplate')" >Choose</button>
          </div>
        </p-dialog>
        <button class="select-template" (click)="selectTemplate('thirdTemplate')">Select</button>
      </div>
    </div>
  </div>
</div>
  </div>
</div>
