import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {ActionType, IAction, ISourceEvent, IElementAction, IElementEvent, IActionSubs} from './IElementAction';
import {CourseService} from '../../course.service';

@Injectable({
  providedIn: 'root'
})
export class ElementActionsService {
  actionList: IAction[] = [];
  actionSubs: IActionSubs = {}; // unique subject per element for efficiency

  constructor(private courseService: CourseService) { }

  emitEvent(event: IElementEvent): void {
    // logic to check if event in action list, if so then take action
    for (const action of this.actionList){
      if (action.sourceEvent.eventElementID === event.eventElementID){
        this.actionSubs[action.targetAction.targetElementID].next({...action.targetAction, data: event.data});
      }
    }
  }

  setActionList(actions: IAction[]): void {
    this.actionList = actions;
  }

  createActionSub(elementID: string): Subject<IElementAction> {
    this.actionSubs[elementID] = new Subject<IElementAction>();
    return this.actionSubs[elementID];
  }

  removeActionSub(elementID: string): void {
    delete this.actionSubs[elementID];
  }

  addAction(action: IAction): void {
    this.actionList.push(JSON.parse(JSON.stringify(action)));
  }

  createAction(targetAction: IElementAction, sourceEvent: ISourceEvent, title: string): IAction {
    return {
      targetAction,
      sourceEvent,
      id: this.courseService.generateID(),
      title
    };
  }

  createTargetAction(targetElementID: string, actionType: ActionType, data?: any, targetElementState?: string): IElementAction{
    return {
      data,
      targetElementID,
      targetElementState,
      actionType
    };
  }

  removeAction(action: IAction): void {
    const index = this.actionList.indexOf(action, 0);
    if (index > -1) {
      this.actionList.splice(index, 1);
    }
  }

  getActionSub(elementID: string): Subject<IElementAction>{
    return this.actionSubs[elementID];
  }

}
