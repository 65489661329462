import {Component, OnDestroy, OnInit} from '@angular/core';
import {HivePopupService, PopupTypes} from './hive-popup.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-hive-popup',
  templateUrl: './hive-popup.component.html',
  styleUrls: ['./hive-popup.component.scss']
})
export class HivePopupComponent implements OnInit, OnDestroy {

  popupType: PopupTypes;
  popupTypeSub: Subscription;

  constructor(private popupService: HivePopupService) { }

  ngOnInit(): void {
    this.popupTypeSub = this.popupService.popupTypeSub.subscribe(result => {
      this.popupType = result;
    })
  }

  ngOnDestroy(): void {
  }

}
