import { Injectable } from '@angular/core';
import * as fbDatabase from 'firebase/database';
import { globalVariables } from '../../shared/global-variables';
import { messageModel } from './message.model';
import { UserService } from '../../hive/user/user.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageHandleService {
  database = fbDatabase.getDatabase(this.globals.firebase_app);

  localMessages: BehaviorSubject<{ [key: string]: messageModel }> =
    new BehaviorSubject<{ [key: string]: messageModel }>(null);
  localMessagesList: BehaviorSubject<{ [key: string]: string }> =
    new BehaviorSubject<{ [key: string]: string }>(null);

  notification: Subject<messageModel> = new Subject<messageModel>();

  constructor(
    private globals: globalVariables,
    private userService: UserService
  ) {}

  // listen to message updated.
  messagesListRef = fbDatabase.ref(
    this.database,
    btoa(this.userService.getUserDetails()?.email) +
      '/user_inbox/messages/message_list'
  );
  observer = fbDatabase.onValue(this.messagesListRef, (snapshot) => {
    this.localMessagesList.next(snapshot.val());
  });
  notificationsListRef = fbDatabase.ref(
    this.database,
    btoa(this.userService.getUserDetails()?.email) + '/notification'
  );
  notificationObs = fbDatabase.onValue(
    this.notificationsListRef,
    (snapshot) => {
      if (
        snapshot.exists() &&
        !snapshot.val()['viewed'] &&
        snapshot.val()['type'] === 'notification' &&
        snapshot.val()['to'] === this.userService.getUserDetails()?.email
      ) {
        this.notification.next(snapshot.val());
      }
    }
  );

  syncMessagesAndList() {}

  isInSync(): boolean {
    let localCopy = Object.keys(this.localMessages.value);
    let localListCopy = Object.keys(this.localMessagesList.value);

    if (localCopy.length !== localListCopy.length) {
      return false;
    } else {
      let difference = localCopy.filter((x) => !localListCopy.includes(x));
      return difference.length <= 0;
    }
  }

  getMessagesOnce(): Promise<{ [p: string]: messageModel }> {
    let userEmail = this.userService.getUserDetails().email;
    let data = fbDatabase.ref(
      this.database,
      btoa(userEmail) + '/user_inbox/messages'
    );
    return new Promise((resolve) => {
      if (this.localMessages.value) {
        console.log('messages from local');
        resolve(this.localMessages.value);
      } else {
        fbDatabase
          .get(data)
          .then((messages) => {
            if (messages.exists()) {
              console.log('messages from database');
              let toLocal = messages.val();
              delete toLocal['message_list'];
              resolve(toLocal);
              this.localMessages.next(toLocal);
            } else {
              console.log('No Messages for ' + userEmail);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }

  getNotificationsOnce(): Promise<any> {
    let userEmail = this.userService.getUserDetails().email;
    let data = fbDatabase.ref(
      this.database,
      btoa(userEmail) + '/user_inbox/notifications'
    );
    return new Promise((resolve) => {
      fbDatabase
        .get(data)
        .then((messages) => {
          if (messages.exists()) {
            resolve(messages.val());
          } else {
            console.log('No Notifications for ' + userEmail);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  sendNotification(email: string, data: messageModel) {
    let updates = {};
    let timestamp = fbDatabase.serverTimestamp();
    updates[btoa(email) + '/notification'] = data;

    fbDatabase.update(fbDatabase.ref(this.database), updates).then();
  }

  updateNotificationViewed() {
    let updates = {};
    let timestamp = fbDatabase.serverTimestamp();
    updates[
      btoa(this.userService.getUserDetails().email) + '/notification/viewed'
    ] = true;

    fbDatabase.update(fbDatabase.ref(this.database), updates).then();
  }

  sendMessage(email: string, data: messageModel) {
    let updates = {};
    let timestamp = fbDatabase.serverTimestamp();

    updates[btoa(email) + '/user_inbox/last_update'] = timestamp;
    fbDatabase.update(fbDatabase.ref(this.database), updates).then();

    data.timestamp = timestamp;

    return new Promise((resolve) => {
      fbDatabase
        .push(
          fbDatabase.ref(this.database, btoa(email) + '/user_inbox/messages'),
          data
        )
        .then((resolved) => {
          fbDatabase
            .set(
              fbDatabase.ref(
                this.database,
                btoa(email) +
                  '/user_inbox/messages/message_list/' +
                  resolved.key
              ),
              data.head
            )
            .then(() => {
              resolve(resolved.key);
            });
        });
    });
  }

  updateUserInboxLastUpdate() {
    let updates = {};

    updates[
      btoa(this.userService.getUserDetails().email) + '/user_inbox/last_update'
    ] = fbDatabase.serverTimestamp();
    fbDatabase.update(fbDatabase.ref(this.database), updates).then();
  }

  sendMultiNotifications(email: string[], data: messageModel) {}

  sendMultiMessages(email: string[], data: messageModel) {}

  deleteMessageByID(id: string) {
    let userEmail = this.userService.getUserDetails().email;
    let messages = fbDatabase.ref(
      this.database,
      btoa(userEmail) + '/user_inbox/messages/' + id
    );
    let messagesList = fbDatabase.ref(
      this.database,
      btoa(userEmail) + '/user_inbox/messages/message_list/' + id
    );

    let messagesCopy = this.localMessages.value;
    fbDatabase.remove(messages).then(() => {
      fbDatabase.remove(messagesList).then(() => {
        this.localMessages.next(messagesCopy);
        this.updateUserInboxLastUpdate();
      });
    });
  }

  updateLocalMessagesDB(): Promise<any> {
    let userEmail = this.userService.getUserDetails().email;
    let data = fbDatabase.ref(
      this.database,
      btoa(userEmail) + '/user_inbox/messages'
    );
    return new Promise((resolve) => {
      fbDatabase
        .get(data)
        .then((messages) => {
          if (messages.exists()) {
            console.log('messages from database');
            let toLocal = messages.val();
            delete toLocal['message_list'];
            resolve(toLocal);
            this.localMessages.next(toLocal);
          } else {
            console.log('No Messages for ' + userEmail);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }
}
