import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../core/guards/auth.guard';
import { LoginComponent } from '../auth/login/login.component';
import { SignupComponent } from '../auth/signup/signup.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('../hive/hive.module').then((m) => m.HiveModule),
  },
  {
    path: 'course',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../course/course.module').then((m) => m.CourseModule),
  },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutesModule {}
