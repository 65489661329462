import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CourseStructureService } from '../course-structure.service';
import { ValidatorService } from 'src/app/shared/validator/validator.service';
import { CourseService } from 'src/app/course/course.service';
import { ICourseSection } from 'src/app/course/interfaces-enums/course';

interface Parents {
  topic: string;
  lesson: string;
}

@Component({
  selector: 'app-course-structure-section',
  templateUrl: './course-structure-section.component.html',
  styleUrls: ['./course-structure-section.component.scss'],
})
export class CourseStructureSectionComponent implements OnInit, OnDestroy {
  @Input() section: ICourseSection;
  @Input() id: string;
  @Input() parents: Parents;

  sectionNameEdit: boolean = false;
  @ViewChild('sectionName') sectionName: ElementRef;

  isValid;
  isValidSub: Subscription;

  constructor(
    private courseService: CourseService,
    private validator: ValidatorService,
    private router: Router,
    private structureService: CourseStructureService
  ) {}

  ngOnInit(): void {
    this.isValidSub = this.structureService.canEdit.subscribe((status) => {
      this.isValid = status;
    });
  }

  onSectionNameEdit() {
    if (this.sectionNameEdit === false) {
      this.structureService.canEditStatus(false);
    } else {
      this.structureService.canEditStatus(true);
    }
    this.sectionNameEdit = !this.sectionNameEdit;
    setTimeout(() => {
      if (this.sectionName) {
        this.sectionName.nativeElement.focus();
      }
    }, 100);
  }

  onSectionNameSave(sectionName: string) {
    this.section.title = sectionName;
    this.courseService.updateCurrentCourseByPath(
      { ...this.parents, section: this.id },
      this.section
    );
    this.onSectionNameEdit();
  }

  onToggle() {
    this.section.metaData.open = !this.section.metaData.open;
  }

  onDelete() {
    this.validator.getResult().subscribe((result) => {
      if (result) {
        this.courseService.removeFromCourseByPath({
          ...this.parents,
          section: this.id,
        });
      }
    });
  }

  onEditorNavigate() {
    this.router.navigateByUrl(
      '/hive/' + this.courseService.getCourseID() + '/editor'
    );
  }

  ngOnDestroy(): void {
    this.isValidSub.unsubscribe();
  }
}
