<div class="user-landing-page-editor-container">
  <div *ngIf="selectedSection.section_name === 'header'">
    <div class="selected-section-container">
      <h2>Welcome Section</h2>
      <img [src]="selectedSection.section_image" alt="selected section image">
      <div class="selected-section-text">
        <a [matMenuTriggerFor]="otherSections">See Other Designs </a>
        <span class="material-icons-outlined right-arrow-icon">arrow_right</span>
        <mat-menu #otherSections="matMenu">
          <div class="other-section-container">
            <h3>Choose Welcome Section design</h3>
            <div class="first-design" >
              <div class="image-container"
                   [ngClass]="selectedSection.welcome.type === 'header_first_template'? 'selected-frame':'not-selected'">
                <div *ngIf="selectedSection.welcome.type === 'header_first_template'" class="already-selected-section">
                  <p>Selected Design</p>
                </div>
                <img class="section-image" (click)="selectHeaderSectionDesign('header_first_template')"
                     src="assets/images/landing_page/landing-page-1-header.jpg">
                <button (click)="selectHeaderSectionDesign('header_first_template')" class="select-section-btn"
                        *ngIf="selectedSection.welcome.type !== 'header_first_template'">Select</button>
              </div>
              <p class="image-description"
                 [ngClass]="selectedSection.welcome.type === 'header_first_template'? 'selected-frame-text':'selected-frame-text'">
                Text amd image next to each other</p>
            </div>
            <div class="second-design" >
              <div class="image-container"
                   [ngClass]="selectedSection.welcome.type === 'header_second_template'? 'selected-frame':'not-selected'">
                <div *ngIf="selectedSection.welcome.type === 'header_second_template'" class="already-selected-section">
                  <p>Selected Design</p>
                </div>
                <img class="section-image"  (click)="selectHeaderSectionDesign('header_second_template')"
                     src="assets/images/landing_page/landing-page-2-header.jpg">
                <button *ngIf="selectedSection.welcome.type !== 'header_second_template'"
                        (click)="selectHeaderSectionDesign('header_second_template')" class="select-section-btn"
                        >Select</button>
              </div>
              <p [ngClass]="selectedSection.welcome.type !== 'header_second_template'? '':'selected-frame-text'" class="image-description">Image without text</p>
            </div>
            <div class="third-design" >
              <div class="image-container"
                   [ngClass]="selectedSection.welcome.type === 'header_third_template'? 'selected-frame':'not-selected'">
                <div  *ngIf="selectedSection.welcome.type === 'header_third_template'" class="already-selected-section">
                  <p>Selected Design</p>
                </div>
                <img class="section-image"  (click)="selectHeaderSectionDesign('header_third_template')"
                     src="assets/images/landing_page/landing-page-3-header.jpg">
                <button *ngIf="selectedSection.welcome.type !== 'header_third_template'"
                        (click)="selectHeaderSectionDesign('header_third_template')" class="select-section-btn">Select</button>
              </div>
              <p [ngClass]="selectedSection.welcome.type !== 'header_third_template'? '':'selected-frame-text'" class="image-description">Image background and text in the center</p>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
    <div class="selected-section-background">
      <div class="selected-section-title">
        <h3>Background</h3>
      </div>
      <div class="background-style sidebar-container-contnet">
        <div class="background-style-selector">
          <h3>Style:</h3>
          <a class="image-style-background" (click)="backgroundStyleSelected = 'background_url';"
             [ngClass]="backgroundStyleSelected === 'background_url'? 'under-line': ''">Image</a>
          <a class="solid-color-style-background" (click)="backgroundStyleSelected = 'background_color';"
             [ngClass]="backgroundStyleSelected === 'background_color'? 'under-line': ''">Solid-color</a>
        </div>
        <div *ngIf="backgroundStyleSelected === 'background_color'" class="solid-color-style-background-selected">
          <div class="color-picker-background">
            <h3>Color:</h3>
            <div class="color-picker-shape">
              <div class="color-picker-show-color-part" [style]="{background: selectedSection.welcome.style.background_color}"
                   [cpAlphaChannel]="'disabled'" (click)="$event.stopPropagation()" [(colorPicker)]="selectedSection.welcome.style.background_color"
                   (colorPickerClose)="updateCurrentCourse()">

              </div>
              <div class="color-picker-color-number-part">
                <input type="text" [(ngModel)]="selectedSection.welcome.style.background_color">
              </div>
            </div>
          </div>
          <div class="color-opacity-background">
            <h3>Opacity:</h3>
            <div class="opacity-slider">
              <mat-slider
                class="opacity-picker"
                [disabled]="disabled"
                [invert]="invert"
                [max]="max"
                [min]="min"
                [step]="step"
                (input)="value = $event.value; upDateBackgroundColor(value)"
                [thumbLabel]="thumbLabel"
                [tickInterval]="getSliderTickInterval()"
                [(ngModel)]="value"
                [vertical]="vertical"
                color="primary"
                (change)="upDateBackgroundColor(value)"
                aria-labelledby="example-name-label">
              </mat-slider>
              <label class="opacity-picker-value">{{value}}%</label>
            </div>
          </div>
        </div>
        <div *ngIf="backgroundStyleSelected === 'background_url'" class="image-style-background-selected"></div>

      </div>
    </div>
    <div class="selected-section-direction">
      <div class="selected-section-title">
        <h3>Direction</h3>
      </div>
      <div class="page-direction sidebar-container-contnet">
        <div class="page-direction-wrapper">
          <div class="LTR" [ngClass]="selectedSection.welcome.style.direction==='ltr'? 'under-line-direction':''">
            <a (click)="selectedSection.welcome.style.direction='ltr'">
              <span class="material-icons-outlined direction-Icons">format_textdirection_l_to_r </span> Left to Right</a>
          </div>
          <div class="RTL" [ngClass]="selectedSection.welcome.style.direction==='rtl'? 'under-line-direction':''">
            <a (click)="selectedSection.welcome.style.direction='rtl'">
              <span class="material-icons-outlined direction-Icons">format_textdirection_r_to_l </span> Right to Left</a>
          </div>
        </div>
      </div>
    </div>
    <div class="selected-section-logo">
      <div class="selected-section-title">
        <h3>Logo</h3>
      </div>
      <div class="logo-section sidebar-container-contnet">
        <div class="input-checkbox">
          <input type="checkbox" id="logo" name="logo" value="logo" [(ngModel)]="logoIsChecked" (click)="onLogoSection()" checked>
          <label for="logo"> With Logo\Name</label>
        </div>
        <div *ngIf="logoIsChecked">
          <div class="logo-style-selector">
            <h3>Style:</h3>
            <a class="image-style-background" (click)="logoSectionStyle=!logoSectionStyle;"
               [ngClass]="!logoSectionStyle? 'under-line': ''">Image</a>
            <a class="solid-color-style-background" (click)="logoSectionStyle=!logoSectionStyle;"
               [ngClass]="logoSectionStyle? 'under-line': ''">Solid-color</a>
          </div>
          <div *ngIf="logoSectionStyle" class="solid-color-style-background-selected">
            <div class="color-picker-text-color">
              <h3>Color:</h3>
              <div class="color-picker-shape">
                <div class="color-picker-show-color-part" [cpAlphaChannel]="'disabled'" (click)="$event.stopPropagation()"
                     [(colorPicker)]="selectedSection.logo.style.text_color"
                     (colorPickerClose)="updateCurrentCourse()" [style]="{background: selectedSection.logo.style.text_color}"></div>
                <div class="color-picker-color-number-part">
                  <input type="text" value="{{selectedSection.logo.style.text_color}} ">
                </div>
              </div>
            </div>
            <div class="color-picker-background-color">
              <h3>background Color:</h3>
              <div class="color-picker-shape logo-footer-color-picker" >
                <div class="color-picker-show-color-part" [cpAlphaChannel]="'disabled'" (click)="$event.stopPropagation()"
                     [(colorPicker)]="selectedSection.logo.style.background_color"
                     (colorPickerClose)="updateCurrentCourse()" [style]="{background: selectedSection.logo.style.background_color}"></div>
                <div class="color-picker-color-number-part">
                  <input type="text" value="{{selectedSection.logo.style.background_color}}">
                </div>
              </div>
            </div>
            <div class="logo-footer-alignment">
              <h3>Alignment: </h3>
              <a [ngClass]="selectedSection.logo.style.alignment === 'left'? 'alignment-selected': ''"
                 (click)="selectedSection.logo.style.alignment = 'left'">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
                  <g id="align-left" transform="translate(-2 -5)">
                    <line id="Line_43" data-name="Line 43" x1="14" transform="translate(3 10)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <line id="Line_44" data-name="Line 44" x1="18" transform="translate(3 6)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <line id="Line_45" data-name="Line 45" x1="18" transform="translate(3 14)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <line id="Line_46" data-name="Line 46" x1="14" transform="translate(3 18)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  </g>
                </svg></a>
              <a [ngClass]="selectedSection.logo.style.alignment === 'center'? 'alignment-selected': ''"
                 (click)="selectedSection.logo.style.alignment = 'center'">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
                  <g id="align-center" transform="translate(-2 -5)">
                    <line id="Line_47" data-name="Line 47" x1="12" transform="translate(6 10)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <line id="Line_48" data-name="Line 48" x1="18" transform="translate(3 6)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <line id="Line_49" data-name="Line 49" x1="18" transform="translate(3 14)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <line id="Line_50" data-name="Line 50" x1="12" transform="translate(6 18)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  </g>
                </svg></a>
              <a [ngClass]="selectedSection.logo.style.alignment === 'right'? 'alignment-selected': ''"
                 (click)="selectedSection.logo.style.alignment = 'right'">
                <svg id="align-left" xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
                  <g id="Line_43" data-name="Line 43" transform="translate(4 4)" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M1,1H15" stroke="none"/>
                    <path d="M 1 2 L 15 2 C 15.13807010650635 2 15.26960468292236 1.972017526626587 15.38924312591553 1.921414375305176 C 15.50888156890869 1.870811223983765 15.61662292480469 1.797587513923645 15.70710468292236 1.707104921340942 C 15.79758739471436 1.616622447967529 15.87081146240234 1.508881211280823 15.92141437530518 1.389243125915527 C 15.97201728820801 1.269604921340942 16 1.138069987297058 16 1 C 16 0.8619300127029419 15.97201728820801 0.7303950190544128 15.92141437530518 0.6107568740844727 C 15.87081146240234 0.4911187887191772 15.79758739471436 0.3833775520324707 15.70710468292236 0.2928950190544128 C 15.61662292480469 0.202412486076355 15.50888156890869 0.1291887760162354 15.38924312591553 0.07858562469482422 C 15.26960468292236 0.02798247337341309 15.13807010650635 0 15 0 L 1 0 C 0.8619298934936523 0 0.7303943634033203 0.02798247337341309 0.6107559204101562 0.07858562469482422 C 0.4911184310913086 0.1291887760162354 0.3833770751953125 0.202412486076355 0.2928943634033203 0.2928950190544128 C 0.2024116516113281 0.3833775520324707 0.1291885375976562 0.4911187887191772 0.07858562469482422 0.6107568740844727 C 0.02798271179199219 0.7303950190544128 0 0.8619300127029419 0 1 C 0 1.138069987297058 0.02798271179199219 1.269604921340942 0.07858562469482422 1.389243125915527 C 0.1291885375976562 1.508881211280823 0.2024116516113281 1.616622447967529 0.2928943634033203 1.707104921340942 C 0.3833770751953125 1.797587513923645 0.4911184310913086 1.870811223983765 0.6107559204101562 1.921414375305176 C 0.7303943634033203 1.972017526626587 0.8619298934936523 2 1 2 Z" stroke="none" fill="#3c3c3c"/>
                  </g>
                  <g id="Line_44" data-name="Line 44" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M1,1H19" stroke="none"/>
                    <path d="M 1 2 L 19 2 C 19.13806915283203 2 19.26960563659668 1.972017526626587 19.38924217224121 1.921414375305176 C 19.50888061523438 1.870811223983765 19.61662292480469 1.797587513923645 19.70710563659668 1.707104921340942 C 19.79758834838867 1.616622447967529 19.87081146240234 1.508881211280823 19.92141342163086 1.389243125915527 C 19.97201728820801 1.269604921340942 20 1.138069987297058 20 1 C 20 0.8619300127029419 19.97201728820801 0.7303950190544128 19.92141342163086 0.6107568740844727 C 19.87081146240234 0.4911187887191772 19.79758834838867 0.3833775520324707 19.70710563659668 0.2928950190544128 C 19.61662292480469 0.202412486076355 19.50888061523438 0.1291887760162354 19.38924217224121 0.07858562469482422 C 19.26960563659668 0.02798247337341309 19.13806915283203 0 19 0 L 1 0 C 0.8619308471679688 0 0.7303962707519531 0.02798247337341309 0.6107578277587891 0.07858562469482422 C 0.491119384765625 0.1291887760162354 0.3833770751953125 0.202412486076355 0.2928962707519531 0.2928950190544128 C 0.2024135589599609 0.3833775520324707 0.1291885375976562 0.4911187887191772 0.07858657836914062 0.6107568740844727 C 0.02798271179199219 0.7303950190544128 0 0.8619300127029419 0 1 C 0 1.138069987297058 0.02798271179199219 1.269604921340942 0.07858657836914062 1.389243125915527 C 0.1291885375976562 1.508881211280823 0.2024135589599609 1.616622447967529 0.2928962707519531 1.707104921340942 C 0.3833770751953125 1.797587513923645 0.491119384765625 1.870811223983765 0.6107578277587891 1.921414375305176 C 0.7303962707519531 1.972017526626587 0.8619308471679688 2 1 2 Z" stroke="none" fill="#3c3c3c"/>
                  </g>
                  <g id="Line_45" data-name="Line 45" transform="translate(0 8)" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M1,1H19" stroke="none"/>
                    <path d="M 1 2 L 19 2 C 19.13806915283203 2 19.26960563659668 1.972017526626587 19.38924217224121 1.921414375305176 C 19.50888061523438 1.870811223983765 19.61662292480469 1.797587513923645 19.70710563659668 1.707104921340942 C 19.79758834838867 1.616622447967529 19.87081146240234 1.508881211280823 19.92141342163086 1.389243125915527 C 19.97201728820801 1.269604921340942 20 1.138069987297058 20 1 C 20 0.8619300127029419 19.97201728820801 0.7303950190544128 19.92141342163086 0.6107568740844727 C 19.87081146240234 0.4911187887191772 19.79758834838867 0.3833775520324707 19.70710563659668 0.2928950190544128 C 19.61662292480469 0.202412486076355 19.50888061523438 0.1291887760162354 19.38924217224121 0.07858562469482422 C 19.26960563659668 0.02798247337341309 19.13806915283203 0 19 0 L 1 0 C 0.8619308471679688 0 0.7303962707519531 0.02798247337341309 0.6107578277587891 0.07858562469482422 C 0.491119384765625 0.1291887760162354 0.3833770751953125 0.202412486076355 0.2928962707519531 0.2928950190544128 C 0.2024135589599609 0.3833775520324707 0.1291885375976562 0.4911187887191772 0.07858657836914062 0.6107568740844727 C 0.02798271179199219 0.7303950190544128 0 0.8619300127029419 0 1 C 0 1.138069987297058 0.02798271179199219 1.269604921340942 0.07858657836914062 1.389243125915527 C 0.1291885375976562 1.508881211280823 0.2024135589599609 1.616622447967529 0.2928962707519531 1.707104921340942 C 0.3833770751953125 1.797587513923645 0.491119384765625 1.870811223983765 0.6107578277587891 1.921414375305176 C 0.7303962707519531 1.972017526626587 0.8619308471679688 2 1 2 Z" stroke="none" fill="#3c3c3c"/>
                  </g>
                  <g id="Line_46" data-name="Line 46" transform="translate(4 12)" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M1,1H15" stroke="none"/>
                    <path d="M 1 2 L 15 2 C 15.13807010650635 2 15.26960468292236 1.972017526626587 15.38924312591553 1.921414375305176 C 15.50888156890869 1.870811223983765 15.61662292480469 1.797587513923645 15.70710468292236 1.707104921340942 C 15.79758739471436 1.616622447967529 15.87081146240234 1.508881211280823 15.92141437530518 1.389243125915527 C 15.97201728820801 1.269604921340942 16 1.138069987297058 16 1 C 16 0.8619300127029419 15.97201728820801 0.7303950190544128 15.92141437530518 0.6107568740844727 C 15.87081146240234 0.4911187887191772 15.79758739471436 0.3833775520324707 15.70710468292236 0.2928950190544128 C 15.61662292480469 0.202412486076355 15.50888156890869 0.1291887760162354 15.38924312591553 0.07858562469482422 C 15.26960468292236 0.02798247337341309 15.13807010650635 0 15 0 L 1 0 C 0.8619298934936523 0 0.7303943634033203 0.02798247337341309 0.6107559204101562 0.07858562469482422 C 0.4911184310913086 0.1291887760162354 0.3833770751953125 0.202412486076355 0.2928943634033203 0.2928950190544128 C 0.2024116516113281 0.3833775520324707 0.1291885375976562 0.4911187887191772 0.07858562469482422 0.6107568740844727 C 0.02798271179199219 0.7303950190544128 0 0.8619300127029419 0 1 C 0 1.138069987297058 0.02798271179199219 1.269604921340942 0.07858562469482422 1.389243125915527 C 0.1291885375976562 1.508881211280823 0.2024116516113281 1.616622447967529 0.2928943634033203 1.707104921340942 C 0.3833770751953125 1.797587513923645 0.4911184310913086 1.870811223983765 0.6107559204101562 1.921414375305176 C 0.7303943634033203 1.972017526626587 0.8619298934936523 2 1 2 Z" stroke="none" fill="#3c3c3c"/>
                  </g>
                </svg>
              </a>
            </div>
          </div>
          <div *ngIf="!logoSectionStyle" class="image-style-background-selected"></div>
        </div>

      </div>
    </div>
  </div>
  <div *ngIf=" selectedSection.section_name ==='about' || selectedSection.section_name === 'info bullets'">
    <div class="selected-section-container">
      <h2>{{selectedSection.section_name}} Section</h2>
      <img [src]="selectedSection.section_image" alt="selected section image">
      <div *ngIf="selectedSection.section_name ==='about'" class="selected-section-text">
        <a [matMenuTriggerFor]="otherSections">See Other Designs </a>
        <span class="material-icons-outlined right-arrow-icon">arrow_right</span>
        <mat-menu #otherSections="matMenu">
          <div class="other-section-container">
            <h3>Choose Welcome Section design</h3>
            <div class="first-design" >
              <div class="image-container"
                   [ngClass]="selectedSection.type === 'about_first_template'? 'selected-frame':'not-selected'">
                <div *ngIf="selectedSection.type === 'about_first_template'" class="already-selected-section">
                  <p>Selected Design</p>
                </div>
                <img (click)="selectAboutSectionDesign('about_first_template')" class="section-image"
                     src="assets/images/landing_page/landing-page-1-about-me.jpg">
                <button (click)="selectAboutSectionDesign('about_first_template')" class="select-section-btn"
                        *ngIf="selectedSection.type !== 'about_first_template'">Select</button>
              </div>
              <p class="image-description"
                 [ngClass]="selectedSection.type === 'about_first_template'? 'selected-frame-text':'selected-frame-text'">
                Text amd image next to each other</p>
            </div>
            <div class="second-design" >
              <div class="image-container"
                   [ngClass]="selectedSection.type === 'about_second_template'? 'selected-frame':'not-selected'">
                <div *ngIf="selectedSection.type === 'about_second_template'" class="already-selected-section">
                  <p>Selected Design</p>
                </div>
                <img (click)="selectAboutSectionDesign('about_second_template')" class="section-image"
                     src="assets/images/landing_page/landing-page-2-about-me.jpg">
                <button *ngIf="selectedSection.type !== 'about_second_template'"
                        (click)="selectAboutSectionDesign('about_second_template')" class="select-section-btn"
                >Select</button>
              </div>
              <p [ngClass]="selectedSection.type !== 'about_second_template'? '':'selected-frame-text'" class="image-description">Image without text</p>
            </div>
            <div class="third-design" >
              <div class="image-container"
                   [ngClass]="selectedSection.type === 'about_third_template'? 'selected-frame':'not-selected'">
                <div  *ngIf="selectedSection.type === 'about_third_template'" class="already-selected-section">
                  <p>Selected Design</p>
                </div>
                <img (click)="selectAboutSectionDesign('about_third_template')" class="section-image"
                     src="assets/images/landing_page/landing-page-3-about-me.jpg">
                <button *ngIf="selectedSection.type !== 'about_third_template'"
                        (click)="selectAboutSectionDesign('about_third_template')" class="select-section-btn">Select</button>
              </div>
              <p [ngClass]="selectedSection.type !== 'about_third_template'? '':'selected-frame-text'" class="image-description">Image background and text in the center</p>
            </div>
          </div>
        </mat-menu>
      </div>

      <div *ngIf="selectedSection.section_name ==='info bullets'" class="selected-section-text">
        <a [matMenuTriggerFor]="otherSections">See Other Designs </a>
        <span class="material-icons-outlined right-arrow-icon">arrow_right</span>
        <mat-menu #otherSections="matMenu">
          <div class="other-section-container">
            <h3>Choose Welcome Section design</h3>
            <div class="first-design" >
              <div class="image-container"
                   [ngClass]="selectedSection.type === 'info_bullets_first_template'? 'selected-frame':'not-selected'">
                <div *ngIf="selectedSection.type === 'info_bullets_first_template'" class="already-selected-section">
                  <p>Selected Design</p>
                </div>
                <img class="section-image"
                     src="assets/images/landing_page/landing-page-1-icons.jpg">
                <button (click)="selectBulletSectionDesign('info_bullets_first_template')" class="select-section-btn"
                        *ngIf="selectedSection.type !== 'info_bullets_first_template'">Select</button>
              </div>
              <p class="image-description"
                 [ngClass]="selectedSection.type === 'info_bullets_first_template'? 'selected-frame-text':'selected-frame-text'">
                Text amd image next to each other</p>
            </div>
            <div class="second-design" >
              <div class="image-container"
                   [ngClass]="selectedSection.type === 'info_bullets_second_template'? 'selected-frame':'not-selected'">
                <div *ngIf="selectedSection.type === 'info_bullets_second_template'" class="already-selected-section">
                  <p>Selected Design</p>
                </div>
                <img class="section-image"
                     src="assets/images/landing_page/landing-page-2-icons.jpg">
                <button *ngIf="selectedSection.type !== 'info_bullets_second_template'"
                        (click)="selectBulletSectionDesign('info_bullets_second_template')" class="select-section-btn"
                >Select</button>
              </div>
              <p [ngClass]="selectedSection.type !== 'info_bullets_second_template'? '':'selected-frame-text'" class="image-description">Image without text</p>
            </div>
            <div class="third-design" >
              <div class="image-container"
                   [ngClass]="selectedSection.type === 'info_bullets_third_template'? 'selected-frame':'not-selected'">
                <div  *ngIf="selectedSection.type === 'info_bullets_third_template'" class="already-selected-section">
                  <p>Selected Design</p>
                </div>
                <img class="section-image"
                     src="assets/images/landing_page/landing-page-3-icons.jpg">
                <button *ngIf="selectedSection.type !== 'info_bullets_third_template'"
                        (click)="selectBulletSectionDesign('info_bullets_third_template')" class="select-section-btn">Select</button>
              </div>
              <p [ngClass]="selectedSection.type !== 'info_bullets_third_template'? '':'selected-frame-text'" class="image-description">Image background and text in the center</p>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
    <div class="selected-section-background">
      <div class="selected-section-title">
        <h3>Background</h3>
      </div>
      <div class="background-style sidebar-container-contnet">
        <div class="background-style-selector">
          <h3>Style:</h3>
          <a class="image-style-background" (click)="backgroundStyleSelected = 'background_url';"
             [ngClass]="backgroundStyleSelected === 'background_url'? 'under-line': ''">Image</a>
          <a class="solid-color-style-background" (click)="backgroundStyleSelected = 'background_color';"
             [ngClass]="backgroundStyleSelected === 'background_color'? 'under-line': ''">Solid-color</a>
        </div>
        <div *ngIf="backgroundStyleSelected === 'background_color'" class="solid-color-style-background-selected">
          <div class="color-picker-background">
            <h3>Color:</h3>
            <div class="color-picker-shape">
              <div class="color-picker-show-color-part" [style]="{background: selectedSection.style.background_color}"
                   [cpAlphaChannel]="'disabled'" (click)="$event.stopPropagation()" [(colorPicker)]="selectedSection.style.background_color"
                   (colorPickerClose)="updateCurrentCourse()">

              </div>
              <div class="color-picker-color-number-part">
                <input type="text" [(ngModel)]="selectedSection.style.background_color">
              </div>
            </div>
          </div>
          <div class="color-opacity-background">
            <h3>Opacity:</h3>
            <div class="opacity-slider">
              <mat-slider
                class="opacity-picker"
                [disabled]="disabled"
                [invert]="invert"
                [max]="max"
                [min]="min"
                [step]="step"
                (input)="value = $event.value; upDateBackgroundColor(value)"
                [thumbLabel]="thumbLabel"
                [tickInterval]="getSliderTickInterval()"
                [(ngModel)]="value"
                [vertical]="vertical"
                color="primary"
                (change)="upDateBackgroundColor(value)"
                aria-labelledby="example-name-label">
              </mat-slider>
              <label class="opacity-picker-value">{{value}}%</label>
            </div>
          </div>
        </div>
        <div *ngIf="backgroundStyleSelected === 'background_url'" class="image-style-background-selected"></div>

      </div>
    </div>
    <div class="selected-section-direction">
      <div class="selected-section-title">
        <h3>Direction</h3>
      </div>
      <div class="page-direction sidebar-container-contnet">
        <div class="page-direction-wrapper">
          <div class="LTR" [ngClass]="selectedSection.style.direction==='ltr'? 'under-line-direction':''">
            <a (click)="selectedSection.style.direction='ltr'">
              <span class="material-icons-outlined direction-Icons">format_textdirection_l_to_r </span> Left to Right</a>
          </div>
          <div class="RTL" [ngClass]="selectedSection.style.direction==='rtl'? 'under-line-direction':''">
            <a (click)="selectedSection.style.direction='rtl'">
              <span class="material-icons-outlined direction-Icons">format_textdirection_r_to_l </span> Right to Left</a>
          </div>
        </div>
      </div>
    </div>
      <div class="selected-section-accessorizes">
        <div class="selected-section-title">
          <h3>Accessorizes</h3>
        </div>
        <div class="accessorizes-color sidebar-container-contnet">
          <div class="color-picker-background">
            <h3>Color:</h3>
            <div class="color-picker-shape">
              <div class="color-picker-show-color-part" [cpAlphaChannel]="'disabled'" (click)="$event.stopPropagation()"
                   [(colorPicker)]="selectedSection.style.accessorizes_color"
                   (colorPickerClose)="updateCurrentCourse()" [style]="{background: selectedSection.style.accessorizes_color}"></div>
              <div class="color-picker-color-number-part" >
                <input type="text" value="{{selectedSection.style.accessorizes_color}} ">
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  <div *ngIf="selectedSection.section_name === 'courses'">
    <div class="selected-section-container">
      <h2>Footer Section</h2>
      <img [src]="selectedSection.section_image" alt="selected section image">
      <div class="selected-section-text">
        <a [matMenuTriggerFor]="otherSections">See Other Designs </a>
        <span class="material-icons-outlined right-arrow-icon">arrow_right</span>
        <mat-menu #otherSections="matMenu">
          <div class="other-section-container">
            <h3>Choose Welcome Section design</h3>
            <div class="first-design" >
              <div class="image-container"
                   [ngClass]="selectedSection.courses_cards.type === 'courses_lengthwise'? 'selected-frame':'not-selected'">
                <div *ngIf="selectedSection.courses_cards.type === 'courses_lengthwise'" class="already-selected-section">
                  <p>Selected Design</p>
                </div>
                <img (click)="selectCoursesSectionDesign('courses_lengthwise')" class="section-image"
                     src="assets/images/landing_page/landing-page-1-courses.jpg">
                <button (click)="selectCoursesSectionDesign('courses_lengthwise')" class="select-section-btn"
                        *ngIf="selectedSection.courses_cards.type !== 'courses_lengthwise'">Select</button>
              </div>
              <p class="image-description"
                 [ngClass]="selectedSection.courses_cards.type === 'courses_lengthwise'? 'selected-frame-text':'selected-frame-text'">
                Text amd image next to each other</p>
            </div>
            <div class="second-design" >
              <div class="image-container"
                   [ngClass]="selectedSection.courses_cards.type === 'courses_widthwise'? 'selected-frame':'not-selected'">
                <div *ngIf="selectedSection.courses_cards.type === 'courses_widthwise'" class="already-selected-section">
                  <p>Selected Design</p>
                </div>
                <img (click)="selectCoursesSectionDesign('courses_widthwise')" class="section-image"
                     src="assets/images/landing_page/landing-page-2-courses.jpg">
                <button *ngIf="selectedSection.type !== 'courses_widthwise'"
                        (click)="selectCoursesSectionDesign('courses_widthwise')" class="select-section-btn"
                >Select</button>
              </div>
              <p [ngClass]="selectedSection.courses_cards.type !== 'courses_widthwise'? '':'selected-frame-text'" class="image-description">Image without text</p>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
    <div class="selected-section-background">
      <div class="selected-section-title">
        <h3>Background</h3>
      </div>
      <div class="background-style sidebar-container-contnet">
        <div class="background-style-selector">
          <h3>Style:</h3>
          <a class="image-style-background" (click)="backgroundStyleSelected = 'background_url';"
             [ngClass]="backgroundStyleSelected === 'background_url'? 'under-line': ''">Image</a>
          <a class="solid-color-style-background" (click)="backgroundStyleSelected = 'background_color';"
             [ngClass]="backgroundStyleSelected === 'background_color'? 'under-line': ''">Solid-color</a>
        </div>
        <div *ngIf="backgroundStyleSelected === 'background_color'" class="solid-color-style-background-selected">
          <div class="color-picker-background">
            <h3>Color:</h3>
            <div class="color-picker-shape">
              <div class="color-picker-show-color-part" [style]="{background: selectedSection.courses_cards.style.background_color}"
                   [cpAlphaChannel]="'disabled'" (click)="$event.stopPropagation()" [(colorPicker)]="selectedSection.courses_cards.style.background_color"
                   (colorPickerClose)="updateCurrentCourse()">

              </div>
              <div class="color-picker-color-number-part">
                <input type="text" [(ngModel)]="selectedSection.courses_cards.style.background_color">
              </div>
            </div>
          </div>
          <div class="color-opacity-background">
            <h3>Opacity:</h3>
            <div class="opacity-slider">
              <mat-slider
                class="opacity-picker"
                [disabled]="disabled"
                [invert]="invert"
                [max]="max"
                [min]="min"
                [step]="step"
                (input)="value = $event.value; upDateBackgroundColor(value)"
                [thumbLabel]="thumbLabel"
                [tickInterval]="getSliderTickInterval()"
                [(ngModel)]="value"
                [vertical]="vertical"
                color="primary"
                (change)="upDateBackgroundColor(value)"
                aria-labelledby="example-name-label">
              </mat-slider>
              <label class="opacity-picker-value">{{value}}%</label>
            </div>
          </div>
        </div>
        <div *ngIf="backgroundStyleSelected === 'background_url'" class="image-style-background-selected"></div>

      </div>
    </div>
    <div class="selected-section-direction">
      <div class="selected-section-title">
        <h3>Direction</h3>
      </div>
      <div class="page-direction sidebar-container-contnet">
        <div class="page-direction-wrapper">
          <div class="LTR" [ngClass]="selectedSection.courses_cards.style.direction==='ltr'? 'under-line-direction':''">
            <a (click)="selectedSection.courses_cards.style.direction='ltr'">
              <span class="material-icons-outlined direction-Icons">format_textdirection_l_to_r </span> Left to Right</a>
          </div>
          <div class="RTL" [ngClass]="selectedSection.courses_cards.style.direction==='rtl'? 'under-line-direction':''">
            <a (click)="selectedSection.courses_cards.style.direction='rtl'">
              <span class="material-icons-outlined direction-Icons">format_textdirection_r_to_l </span> Right to Left</a>
          </div>
        </div>
      </div>
    </div>
      <div class="selected-section-accessorizes">
        <div class="selected-section-title">
          <h3>Accessorizes</h3>
        </div>
        <div class="accessorizes-color sidebar-container-contnet">
          <div class="color-picker-background">
            <h3>Color:</h3>
            <div class="color-picker-shape">
              <div class="color-picker-show-color-part" [cpAlphaChannel]="'disabled'" (click)="$event.stopPropagation()"
                   [(colorPicker)]="selectedSection.courses_cards.style.accessorizes_color"
                   (colorPickerClose)="updateCurrentCourse()" [style]="{background: selectedSection.courses_cards.style.accessorizes_color}"></div>
              <div class="color-picker-color-number-part" >
                <input type="text" value="{{selectedSection.courses_cards.style.accessorizes_color}} ">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="selected-section-footer">
        <div class="selected-section-title">
          <h3>Footer</h3>
        </div>

        <div class="footer-section sidebar-container-contnet">
          <div class="input-checkbox">
            <input type="checkbox" id="footer" name="logo" value="logo"  [(ngModel)]="footerIsChecked" (click)="onFooterSection()" checked>
            <label for="footer"> With Footer</label>
          </div>

          <div *ngIf="footerIsChecked">
            <div class="footer-style-selector">
              <h3>Style:</h3>
              <a class="image-style-background" (click)="logoSectionStyle=!logoSectionStyle;"
                 [ngClass]="!logoSectionStyle? 'under-line': ''">Image</a>
              <a class="solid-color-style-background" (click)="logoSectionStyle=!logoSectionStyle;"
                 [ngClass]="logoSectionStyle? 'under-line': ''">Solid-color</a>
            </div>
            <div *ngIf="logoSectionStyle" class="solid-color-style-background-selected">
              <div class="color-picker-text-color">
                <h3>Color:</h3>
                <div class="color-picker-shape ">
                  <div class="color-picker-show-color-part" [cpAlphaChannel]="'disabled'" (click)="$event.stopPropagation()"
                       [(colorPicker)]="selectedSection.footer.style.text_color"
                       (colorPickerClose)="updateCurrentCourse()" [style]="{background: selectedSection.footer.style.text_color}"></div>
                  <div class="color-picker-color-number-part">
                    <input type="text" value="{{selectedSection.footer.style.text_color}} ">
                  </div>
                </div>
              </div>
              <div class="color-picker-background-color">
                <h3>background Color:</h3>
                <div class="color-picker-shape logo-footer-color-picker">
                  <div class="color-picker-show-color-part" [cpAlphaChannel]="'disabled'" (click)="$event.stopPropagation()"
                       [(colorPicker)]="selectedSection.footer.style.background_color"
                       (colorPickerClose)="updateCurrentCourse()" [style]="{background: selectedSection.footer.style.background_color}"></div>
                  <div class="color-picker-color-number-part">
                    <input type="text" value="{{selectedSection.footer.style.background_color}} ">
                  </div>
                </div>
              </div>
              <div class="logo-footer-alignment" style="margin-bottom: 20px">
                <h3>Alignment: </h3>
                <a [ngClass]="selectedSection.footer.style.alignment === 'left'? 'alignment-selected': ''"
                   (click)="selectedSection.footer.style.alignment = 'left'">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
                    <g id="align-left" transform="translate(-2 -5)">
                      <line id="Line_43" data-name="Line 43" x1="14" transform="translate(3 10)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                      <line id="Line_44" data-name="Line 44" x1="18" transform="translate(3 6)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                      <line id="Line_45" data-name="Line 45" x1="18" transform="translate(3 14)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                      <line id="Line_46" data-name="Line 46" x1="14" transform="translate(3 18)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </g>
                  </svg></a>
                <a [ngClass]="selectedSection.footer.style.alignment === 'center'? 'alignment-selected': ''"
                   (click)="selectedSection.footer.style.alignment = 'center'">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
                    <g id="align-center" transform="translate(-2 -5)">
                      <line id="Line_47" data-name="Line 47" x1="12" transform="translate(6 10)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                      <line id="Line_48" data-name="Line 48" x1="18" transform="translate(3 6)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                      <line id="Line_49" data-name="Line 49" x1="18" transform="translate(3 14)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                      <line id="Line_50" data-name="Line 50" x1="12" transform="translate(6 18)" fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </g>
                  </svg></a>
                <a [ngClass]="selectedSection.footer.style.alignment === 'right'? 'alignment-selected': ''"
                   (click)="selectedSection.footer.style.alignment = 'right'">
                  <svg id="align-left" xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
                    <g id="Line_43" data-name="Line 43" transform="translate(4 4)" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M1,1H15" stroke="none"/>
                      <path d="M 1 2 L 15 2 C 15.13807010650635 2 15.26960468292236 1.972017526626587 15.38924312591553 1.921414375305176 C 15.50888156890869 1.870811223983765 15.61662292480469 1.797587513923645 15.70710468292236 1.707104921340942 C 15.79758739471436 1.616622447967529 15.87081146240234 1.508881211280823 15.92141437530518 1.389243125915527 C 15.97201728820801 1.269604921340942 16 1.138069987297058 16 1 C 16 0.8619300127029419 15.97201728820801 0.7303950190544128 15.92141437530518 0.6107568740844727 C 15.87081146240234 0.4911187887191772 15.79758739471436 0.3833775520324707 15.70710468292236 0.2928950190544128 C 15.61662292480469 0.202412486076355 15.50888156890869 0.1291887760162354 15.38924312591553 0.07858562469482422 C 15.26960468292236 0.02798247337341309 15.13807010650635 0 15 0 L 1 0 C 0.8619298934936523 0 0.7303943634033203 0.02798247337341309 0.6107559204101562 0.07858562469482422 C 0.4911184310913086 0.1291887760162354 0.3833770751953125 0.202412486076355 0.2928943634033203 0.2928950190544128 C 0.2024116516113281 0.3833775520324707 0.1291885375976562 0.4911187887191772 0.07858562469482422 0.6107568740844727 C 0.02798271179199219 0.7303950190544128 0 0.8619300127029419 0 1 C 0 1.138069987297058 0.02798271179199219 1.269604921340942 0.07858562469482422 1.389243125915527 C 0.1291885375976562 1.508881211280823 0.2024116516113281 1.616622447967529 0.2928943634033203 1.707104921340942 C 0.3833770751953125 1.797587513923645 0.4911184310913086 1.870811223983765 0.6107559204101562 1.921414375305176 C 0.7303943634033203 1.972017526626587 0.8619298934936523 2 1 2 Z" stroke="none" fill="#3c3c3c"/>
                    </g>
                    <g id="Line_44" data-name="Line 44" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M1,1H19" stroke="none"/>
                      <path d="M 1 2 L 19 2 C 19.13806915283203 2 19.26960563659668 1.972017526626587 19.38924217224121 1.921414375305176 C 19.50888061523438 1.870811223983765 19.61662292480469 1.797587513923645 19.70710563659668 1.707104921340942 C 19.79758834838867 1.616622447967529 19.87081146240234 1.508881211280823 19.92141342163086 1.389243125915527 C 19.97201728820801 1.269604921340942 20 1.138069987297058 20 1 C 20 0.8619300127029419 19.97201728820801 0.7303950190544128 19.92141342163086 0.6107568740844727 C 19.87081146240234 0.4911187887191772 19.79758834838867 0.3833775520324707 19.70710563659668 0.2928950190544128 C 19.61662292480469 0.202412486076355 19.50888061523438 0.1291887760162354 19.38924217224121 0.07858562469482422 C 19.26960563659668 0.02798247337341309 19.13806915283203 0 19 0 L 1 0 C 0.8619308471679688 0 0.7303962707519531 0.02798247337341309 0.6107578277587891 0.07858562469482422 C 0.491119384765625 0.1291887760162354 0.3833770751953125 0.202412486076355 0.2928962707519531 0.2928950190544128 C 0.2024135589599609 0.3833775520324707 0.1291885375976562 0.4911187887191772 0.07858657836914062 0.6107568740844727 C 0.02798271179199219 0.7303950190544128 0 0.8619300127029419 0 1 C 0 1.138069987297058 0.02798271179199219 1.269604921340942 0.07858657836914062 1.389243125915527 C 0.1291885375976562 1.508881211280823 0.2024135589599609 1.616622447967529 0.2928962707519531 1.707104921340942 C 0.3833770751953125 1.797587513923645 0.491119384765625 1.870811223983765 0.6107578277587891 1.921414375305176 C 0.7303962707519531 1.972017526626587 0.8619308471679688 2 1 2 Z" stroke="none" fill="#3c3c3c"/>
                    </g>
                    <g id="Line_45" data-name="Line 45" transform="translate(0 8)" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M1,1H19" stroke="none"/>
                      <path d="M 1 2 L 19 2 C 19.13806915283203 2 19.26960563659668 1.972017526626587 19.38924217224121 1.921414375305176 C 19.50888061523438 1.870811223983765 19.61662292480469 1.797587513923645 19.70710563659668 1.707104921340942 C 19.79758834838867 1.616622447967529 19.87081146240234 1.508881211280823 19.92141342163086 1.389243125915527 C 19.97201728820801 1.269604921340942 20 1.138069987297058 20 1 C 20 0.8619300127029419 19.97201728820801 0.7303950190544128 19.92141342163086 0.6107568740844727 C 19.87081146240234 0.4911187887191772 19.79758834838867 0.3833775520324707 19.70710563659668 0.2928950190544128 C 19.61662292480469 0.202412486076355 19.50888061523438 0.1291887760162354 19.38924217224121 0.07858562469482422 C 19.26960563659668 0.02798247337341309 19.13806915283203 0 19 0 L 1 0 C 0.8619308471679688 0 0.7303962707519531 0.02798247337341309 0.6107578277587891 0.07858562469482422 C 0.491119384765625 0.1291887760162354 0.3833770751953125 0.202412486076355 0.2928962707519531 0.2928950190544128 C 0.2024135589599609 0.3833775520324707 0.1291885375976562 0.4911187887191772 0.07858657836914062 0.6107568740844727 C 0.02798271179199219 0.7303950190544128 0 0.8619300127029419 0 1 C 0 1.138069987297058 0.02798271179199219 1.269604921340942 0.07858657836914062 1.389243125915527 C 0.1291885375976562 1.508881211280823 0.2024135589599609 1.616622447967529 0.2928962707519531 1.707104921340942 C 0.3833770751953125 1.797587513923645 0.491119384765625 1.870811223983765 0.6107578277587891 1.921414375305176 C 0.7303962707519531 1.972017526626587 0.8619308471679688 2 1 2 Z" stroke="none" fill="#3c3c3c"/>
                    </g>
                    <g id="Line_46" data-name="Line 46" transform="translate(4 12)" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M1,1H15" stroke="none"/>
                      <path d="M 1 2 L 15 2 C 15.13807010650635 2 15.26960468292236 1.972017526626587 15.38924312591553 1.921414375305176 C 15.50888156890869 1.870811223983765 15.61662292480469 1.797587513923645 15.70710468292236 1.707104921340942 C 15.79758739471436 1.616622447967529 15.87081146240234 1.508881211280823 15.92141437530518 1.389243125915527 C 15.97201728820801 1.269604921340942 16 1.138069987297058 16 1 C 16 0.8619300127029419 15.97201728820801 0.7303950190544128 15.92141437530518 0.6107568740844727 C 15.87081146240234 0.4911187887191772 15.79758739471436 0.3833775520324707 15.70710468292236 0.2928950190544128 C 15.61662292480469 0.202412486076355 15.50888156890869 0.1291887760162354 15.38924312591553 0.07858562469482422 C 15.26960468292236 0.02798247337341309 15.13807010650635 0 15 0 L 1 0 C 0.8619298934936523 0 0.7303943634033203 0.02798247337341309 0.6107559204101562 0.07858562469482422 C 0.4911184310913086 0.1291887760162354 0.3833770751953125 0.202412486076355 0.2928943634033203 0.2928950190544128 C 0.2024116516113281 0.3833775520324707 0.1291885375976562 0.4911187887191772 0.07858562469482422 0.6107568740844727 C 0.02798271179199219 0.7303950190544128 0 0.8619300127029419 0 1 C 0 1.138069987297058 0.02798271179199219 1.269604921340942 0.07858562469482422 1.389243125915527 C 0.1291885375976562 1.508881211280823 0.2024116516113281 1.616622447967529 0.2928943634033203 1.707104921340942 C 0.3833770751953125 1.797587513923645 0.4911184310913086 1.870811223983765 0.6107559204101562 1.921414375305176 C 0.7303943634033203 1.972017526626587 0.8619298934936523 2 1 2 Z" stroke="none" fill="#3c3c3c"/>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
            <div *ngIf="!logoSectionStyle" class="image-style-background-selected"></div>
          </div>


        </div>
      </div>
  </div>
</div>
