import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { globalVariables } from 'src/app/shared/global-variables';
import {GoogleLoginProvider, SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  user: SocialUser;
  loggedIn: boolean;
  user_token: string;
  apiUrl: string = this.globals.apiUrl;
  userExists: boolean = false;

  constructor(
    private authService: SocialAuthService,
    private http: HttpClient,
    private globals: globalVariables,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
      this.user = user;
    });
  }

  signInWithGoogle(): void {
    this.loggedIn = false;
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data) => {
      console.log(this.user);
      console.log(data);
      this.getSkillBeeToken(data.idToken).subscribe((data) => {
        localStorage.setItem('token_skillbee', data.toString());
        this.globals.tokenHeader['skillbee-token'] = data.toString();
        this.user_token = data.toString();
        this.loggedIn = data != null;
        this.getUserData();
      });
    });
  }

  signOut(): void {
    this.authService.signOut();
  }

  getSkillBeeToken(token: string) {
    return this.http.post(this.apiUrl + '/token', { token: token });
  }

  getUserData() {
    let token = localStorage.getItem('token_skillbee');
    // console.log(token)
    if (token && token != '') {
      this.http
        .get(this.apiUrl + '/user', { headers: { 'skillbee-token': token } })
        .subscribe((data) => {
          // console.log(data)
          this.userExists = true;
        });
    } else {
      this.userExists = false;
      this.router.navigateByUrl('/dashboard');
      return false;
    }
  }
  onHive() {
    this.userExists = false;
    this.router.navigateByUrl('/dashboard');
  }
}
