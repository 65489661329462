import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CourseService } from 'src/app/course/course.service';
import { ValidatorService } from 'src/app/shared/validator/validator.service';
import { CourseStructureService } from '../course-structure.service';
import {
  ICourseLesson,
  ICourseSection,
  ICourseTopic,
} from 'src/app/course/interfaces-enums/course';
import { UnsavedChangesService } from 'src/app/shared/unsaved-changes/unsaved-changes.service';
import { CourseEditorService } from 'src/app/course/course-editor/course-editor.service';

interface StructurePath {
  topic: string;
  lesson?: string;
  section?: string;
}

@Component({
  selector: 'app-structure-header-manager',
  templateUrl: './structure-header-manager.component.html',
  styleUrls: ['./structure-header-manager.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StructureHeaderManagerComponent implements OnInit, OnDestroy {
  @Input() element: ICourseTopic | ICourseLesson | ICourseSection;
  @Input() path: StructurePath;
  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  element_type: string;
  course_element: {
    [id: string]: ICourseTopic | ICourseLesson | ICourseSection;
  };

  canEdit: boolean;
  isValidSub: Subscription;
  nameEdit: boolean = false;

  isNameValid: boolean = true;

  color: string;
  showColor: boolean = false;

  lessonIisOpenFolder: string;
  topicIsOpenFolder: string;

  titleValue: string;

  checkNameValiditySub: Subscription;
  closeEditSub: Subscription;

  TOPIC = $localize`:@@TOPIC:TOPIC`;
  LESSON = $localize`:@@LESSON:LESSON`;
  SECTION = $localize`:@@SECTION:SECTION`;

  inValidName = $localize`:@@inValidName:Please enter a title`;
  finishName = $localize`:@@finishName:Please finish the title`;

  ifNoName = $localize`:@@ifNoName:Please enter a title`;

  elementTypeList = {
    TOPIC: this.TOPIC,
    LESSON: this.LESSON,
    SECTION: this.SECTION,
  };

  isHebrew: boolean = false;

  isTeacher: boolean;

  isLast: boolean;

  @ViewChild('elementName') elementName: ElementRef;

  constructor(
    private courseService: CourseService,
    private validator: ValidatorService,
    private structureService: CourseStructureService,
    private courseEditorService: CourseEditorService,
    private unsavedChangesService: UnsavedChangesService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  ngOnInit(): void {
    this.isTeacher = this.structureService.isTeacher();
    if (this.locale === 'he') {
      this.isHebrew = true;
    }
    this.closeEditSub = this.structureService.saveAndCloseEdit.subscribe(
      (trigger) => {
        if (this.titleValue) {
          this.onNameSave(this.titleValue);
        }
      }
    );

    this.checkNameValiditySub =
      this.structureService.checkNameValidity.subscribe((trigger) => {
        if (this.nameEdit && !this.checkNameValidity(this.titleValue)) {
          this.isNameValid = false;
        } else if (this.nameEdit) {
          this.isNameValid = false;
        }
      });

    this.isValidSub = this.structureService.canEdit.subscribe((status) => {
      this.canEdit = status;
    });

    if (this.path.section) {
      this.course_element = { [this.path.section]: this.element };
      this.element_type = 'SECTION';
    } else if (this.path.lesson) {
      this.course_element = { [this.path.lesson]: this.element };
      this.element_type = 'LESSON';
    } else {
      this.course_element = { [this.path.topic]: this.element };
      this.element_type = 'TOPIC';
      if ('color' in this.element) {
        this.color = this.element.color;
      }
    }

    if (!this.element.title && this.isTeacher) {
      this.element.metaData.open = true;
    }

    this.setIsLast();
  }

  setIsLast() {
    let selectedData: ICourseTopic | ICourseLesson | ICourseSection =
      this.element;
    let selectedPath = this.path;
    let toCompareList;

    let currentCourse = this.courseService.getCurrentCourseSync();

    let lastIndex: number = 0;

    switch (this.element_type) {
      case 'TOPIC':
        toCompareList = currentCourse.topics;
        selectedData = currentCourse.topics[selectedPath.topic];
        break;
      case 'LESSON':
        toCompareList = currentCourse.topics[selectedPath.topic].lessons;
        selectedData =
          currentCourse.topics[selectedPath.topic].lessons[selectedPath.lesson];
        break;
      case 'SECTION':
        toCompareList =
          currentCourse.topics[selectedPath.topic].lessons[selectedPath.lesson]
            .sections;
        selectedData =
          currentCourse.topics[selectedPath.topic].lessons[selectedPath.lesson]
            .sections[selectedPath.section];
        break;
    }

    for (let item in toCompareList) {
      if (toCompareList[item].index > lastIndex) {
        lastIndex = toCompareList[item].index;
      }
    }
    this.isLast = lastIndex === this.element.index;
  }

  ngOnDestroy() {
    this.isValidSub.unsubscribe();
    this.closeEditSub.unsubscribe();
  }

  print(event) {
    console.log(event);
  }

  getElementTypeForTranslation() {
    for (let item in this.elementTypeList) {
      if (item === this.element_type) {
        return this.elementTypeList[item];
      }
    }
  }

  getElementName() {
    if (this.element.title) {
      return this.element.title;
    } else {
      return this.ifNoName;
    }
  }

  onNameEdit() {
    this.titleValue = this.element.title;
    if (this.nameEdit === false) {
      this.structureService.canEditStatus(false);
    } else {
      this.structureService.canEditStatus(true);
    }
    this.nameEdit = !this.nameEdit;
    setTimeout(() => {
      if (this.elementName) {
        this.elementName.nativeElement.focus();
        this.elementName.nativeElement.select();
      }
    }, 0);
  }

  onNameSave(elementName: string) {
    if (!this.checkNameValidation(elementName)) {
    } else {
      this.element.title = elementName;
      this.courseService.updateCurrentCourseByPath(this.path, this.element);
      this.onNameEdit();
      this.unsavedChangesService.setUnsavedChanges(true);
    }
  }

  checkNameValidation(name: string): boolean {
    if (name === '' || name.length <= 0) {
      this.isNameValid = false;
      this.structureService.canEditStatus(false);
      return false;
    } else {
      this.isNameValid = true;
      this.structureService.canEditStatus(true);
      return true;
    }
  }

  checkNameValidity(name: string) {
    return !(name === '' || name.length <= 3);
  }

  onDelete() {
    this.validator.getResult().subscribe((result) => {
      if (result) {
        this.courseService.removeFromCourseByPath(this.path);
      }
    });
  }

  onToggle() {
    this.isNameValid = true;
    if (!this.element.title && this.canEdit) {
      this.isNameValid = false;
      this.onNameEdit();
    } else {
      this.element.metaData.open = !this.element.metaData.open;
      this.toggle.emit(this.element.metaData.open);
    }
  }

  onToggleUser() {
    this.element.metaData.open = !this.element.metaData.open;
    this.toggle.emit(this.element.metaData.open);
    // this.courseService.updateCurrentCourseByPath(this.path, this.element);
  }

  checkIfValid(event) {
    this.isNameValid = true;
    if (event) {
      this.isNameValid = true;
    } else {
      this.isNameValid = false;
    }
  }

  updateColor(color: string) {
    if (this.element_type === 'TOPIC' && 'color' in this.element) {
      this.element.color = color;
    }
  }
  updateCurrentCourse() {
    this.courseService.updateCurrentCourseByPath(this.path, this.element);
  }

  showColorPicker() {
    this.showColor = !this.showColor;
  }

  onGoToEditor() {
    this.courseEditorService.setSelectedSection(<ICourseSection>this.element);
    this.courseEditorService.changeSelectedSectionUrlParam(
      'editor',
      this.element.id
    );
  }

  onGoToViewer() {
    this.courseEditorService.setSelectedSection(<ICourseSection>this.element);
    this.courseEditorService.changeSelectedSectionUrlParam(
      'viewer',
      this.element.id
    );
  }

  setCanEdit(canEdit: boolean) {
    this.structureService.canEditStatus(canEdit);
  }

  checkNameForAll() {
    this.structureService.checkNameForAll();
  }

  getErrorMessage() {
    if (this.titleValue === '') {
      return this.inValidName;
    } else if (this.checkNameValidity(this.titleValue)) {
      return this.finishName;
    } else if (!this.checkNameValidity(this.titleValue)) {
      return this.inValidName;
    }
  }

  romanize(num) {
    let lookup = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1,
      },
      roman = '',
      i;
    for (let i in lookup) {
      while (num >= lookup[i]) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
  }

  addItemToBottom() {
    if (this.element_type === 'TOPIC') {
      // pointer
      let currentTopics = this.courseService.getCurrentCourseSync().topics;

      for (let topic in currentTopics) {
        if (currentTopics[topic].index > this.element.index) {
          currentTopics[topic].index++;
        }
      }
      this.structureService.addTopic(this.courseService.getCurrentCourseSync());
    } else if (this.element_type === 'LESSON') {
      let currentLessons =
        this.courseService.getCurrentCourseSync().topics[this.path.topic]
          .lessons;

      for (let lesson in currentLessons) {
        if (currentLessons[lesson].index > this.element.index) {
          currentLessons[lesson].index++;
        }
      }
      this.structureService.addLesson(
        this.courseService.getCurrentCourseSync().topics[this.path.topic],
        this.path.topic
      );
    }
  }

  addNewLesson() {
    if (this.element_type === 'TOPIC') {
      this.structureService.addLesson(
        this.courseService.getCurrentCourseSync().topics[this.path.topic],
        this.path.topic
      );
      this.element.metaData.open = true;
      this.updateCurrentCourse();
    }
  }

  checkIfDone() {
    // let sectionsDone = false;
    if (this.element_type === 'SECTION') {
      if (this.element.metaData.done) {
        return true;
      } else {
        return false;
      }
    }
    if (this.element_type === 'LESSON') {
      if (this.element.metaData.done) {
        return true;
      } else {
        return false;
      }
    }
    if (this.element_type === 'TOPIC') {
      if (this.element.metaData.done) {
        return true;
      } else {
        return false;
      }
    }
  }

  indexUp() {
    let selectedData: ICourseTopic | ICourseLesson | ICourseSection =
      this.element;
    let selectedPath = this.path;
    let toCompareList;

    let currentCourse = this.courseService.getCurrentCourseSync();

    let lastIndex: number = 0;

    switch (this.element_type) {
      case 'TOPIC':
        toCompareList = currentCourse.topics;
        selectedData = currentCourse.topics[selectedPath.topic];
        break;
      case 'LESSON':
        toCompareList = currentCourse.topics[selectedPath.topic].lessons;
        selectedData =
          currentCourse.topics[selectedPath.topic].lessons[selectedPath.lesson];
        break;
      case 'SECTION':
        toCompareList =
          currentCourse.topics[selectedPath.topic].lessons[selectedPath.lesson]
            .sections;
        selectedData =
          currentCourse.topics[selectedPath.topic].lessons[selectedPath.lesson]
            .sections[selectedPath.section];
        break;
    }

    for (let item in toCompareList) {
      if (toCompareList[item].index > lastIndex) {
        lastIndex = toCompareList[item].index;
      }
    }

    if (selectedData.index !== lastIndex) {
      for (let item in toCompareList) {
        if (toCompareList[item].index - 1 === selectedData.index) {
          toCompareList[item].index--;
        }
      }

      for (let item in toCompareList) {
        if (toCompareList[item].id === selectedData.id) {
          toCompareList[item].index++;
        }
      }
    }
    this.updateCurrentCourse();
  }

  indexDown() {
    if (this.element.index > 1) {
      let selectedData: ICourseTopic | ICourseLesson | ICourseSection =
        this.element;
      let selectedPath = this.path;
      let toCompareList;

      let currentCourse = this.courseService.getCurrentCourseSync();

      switch (this.element_type) {
        case 'TOPIC':
          toCompareList = currentCourse.topics;
          selectedData = currentCourse.topics[selectedPath.topic];
          break;
        case 'LESSON':
          toCompareList = currentCourse.topics[selectedPath.topic].lessons;
          selectedData =
            currentCourse.topics[selectedPath.topic].lessons[
              selectedPath.lesson
            ];
          break;
        case 'SECTION':
          toCompareList =
            currentCourse.topics[selectedPath.topic].lessons[
              selectedPath.lesson
            ].sections;
          selectedData =
            currentCourse.topics[selectedPath.topic].lessons[
              selectedPath.lesson
            ].sections[selectedPath.section];
          break;
      }

      for (let item in toCompareList) {
        if (toCompareList[item].index + 1 === selectedData.index) {
          toCompareList[item].index++;
        }
      }

      for (let item in toCompareList) {
        if (toCompareList[item].id === selectedData.id) {
          toCompareList[item].index = toCompareList[item].index - 1;
        }
      }
    }
    this.updateCurrentCourse();
  }
}
