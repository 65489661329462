import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-forum',
  templateUrl: './course-forum.component.html',
  styleUrls: ['./course-forum.component.scss']
})
export class CourseForumComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
