import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IElement } from '../element-content-types/interfaces-enums/IElement';

@Injectable({ providedIn: 'root' })
export class EditorCanvasService {

  updateHeader = new Subject();

  isSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isClickOnFrame: boolean;

  focusedElement: BehaviorSubject<IElement> = new BehaviorSubject<IElement>(
    null
  );

  // selected elements list
  currentElementList: BehaviorSubject<IElement[]> = new BehaviorSubject<
    IElement[]
  >(null);

  // currentSelectedGroup: IElement[];

  constructor() {}

  setSelected(state: boolean): void {
    this.isSelected.next(state);
  }

  setIsClickOnFrame(data: boolean): void {
    this.isClickOnFrame = data;
  }

  getIsClickOnFrame(): boolean {
    return this.isClickOnFrame;
  }

  getIsSelected(): boolean {
    return this.isSelected.value;
  }

  setFocusedElement(element: IElement): void {
    if (!element) {
      this.resetElementList();
    }
    this.focusedElement.next(element);
  }

  getFocusedElement(): IElement {
    return this.focusedElement.value;
  }

  resetElementList(element?: IElement) {
    this.currentElementList.next(null);
    if (element) {
      this.addToElementList(element);
    }
  }

  isEditMode(): boolean {
    return !!this.focusedElement.value;
  }

  resetFocusedElements() {
    if (!this.getIsClickOnFrame()) {
      this.setSelected(false);
      this.resetElementList();
    }
  }

  addToElementList(element: IElement) {
    const list = this.currentElementList.value
      ? this.currentElementList.value
      : [];
    list.push(element);
    this.currentElementList.next(list);
  }

  setElementList(elements: IElement[]) {
    this.currentElementList.next(elements);
  }

  removeFromElementList(element: IElement) {
    const list = this.currentElementList.value;
    list.forEach((value, index) => {
      if (element.id === value.id) {
        list.splice(index, 1);
      }
    });
    if (list.length > 0) {
      this.currentElementList.next(list);
    } else {
      this.resetElementList();
    }
  }

  getCurrentListSpecs(paramList?: IElement[]) {
    let list = this.currentElementList.value;
    if (paramList) {
      list = paramList;
    }
    if (list) {
      const finalSpecs = {
        width: list[0].specs.width,
        height: list[0].specs.height,
        left: list[0].specs.left,
        top: list[0].specs.top,
        z_index: list[0].specs.z_index,
      };
      let lowestElementTop = 0;
      let lowestElementHeight = 0;

      let rightestElement = list[0].specs.left;
      let rightestElementWidth = list[0].specs.width;

      let leftestElement = list[0].specs.left;
      let leftestElementWidth = list[0].specs.width;

      for (const item of list) {
        for (const key in item.specs) {
          const value = item.specs[key];
          if (finalSpecs[key] < value && (key === 'width' || key === 'index')) {
            finalSpecs[key] = value;
          }
          if (
            key === 'left' &&
            rightestElement + rightestElementWidth < value + item.specs.width
          ) {
            rightestElement = value;
            rightestElementWidth = item.specs.width;
          }
          if (key === 'left' && leftestElement > value) {
            leftestElement = value;
            leftestElementWidth = item.specs.width;
          }
          if (finalSpecs[key] > value && key === 'top') {
            finalSpecs[key] = value;
          }
          if (
            key === 'top' &&
            lowestElementTop + lowestElementHeight < value + item.specs.height
          ) {
            lowestElementTop = value;
            lowestElementHeight = item.specs.height;
          }
        }
      }

      finalSpecs.height =
        lowestElementHeight + lowestElementTop - finalSpecs.top;
      finalSpecs.width =
        rightestElement + rightestElementWidth - leftestElement;
      finalSpecs.left = leftestElement;
      return finalSpecs;
    }
  }

  resettingGroupAfterAlignment(list: IElement[]) {
    const newList = [...list];
    this.resetElementList();
    newList.forEach((value) => {
      this.addToElementList(value);
    });
  }

  resetSelectedElement() {
    this.setSelected(false);
    this.resetElementList();
    this.setFocusedElement(null);
  }

  // setSelectedGroup(group: ElementGroupModel): void {
  //   this.currentSelectedGroup = group;
  // }

  // getSelectedGroup(): ElementGroupModel {
  //   return this.currentSelectedGroup;
  // }

  // groupElements(list: IElement[]) {
  //   let newGroup: ElementGroupModel = {
  //     specs: this.getCurrentListSpecs(list),
  //     type: 'group',
  //     id: this.courseService.generateID(),
  //     elements: list,
  //   };
  //   newGroup.specs.z_index = 10;
  //   this.resetElementList();
  //   // this.editorService.deleteMultipleElements(list);
  //   list.forEach((value) => {
  //     this.editorService.deleteElement(value);
  //   });
  //   this.editorService.addElementToSection(newGroup);
  //   this.setFocusedElement(null);
  //   let tempCourse = this.courseService.getCurrentCourseSync();
  //   this.courseService.updateCourseHistoryList(tempCourse);
  // }

  // unGroupElements(group: ElementGroupModel) {
  //   this.editorService.addMultipleElementsToSection(group.elements);
  //   this.resetElementList();
  //   this.editorService.deleteElement(group);
  //   this.setFocusedElement(null);
  //   let tempCourse = this.courseService.getCurrentCourseSync();
  //   this.courseService.updateCourseHistoryList(tempCourse);
  // }

  //
  // getAllElementsFromGroup(group: IElement): {
  //   elements: IElement[];
  //   groups: ElementGroupModel[];
  // } {
  //   const elements: ElementModel[] = [];
  //   const groups: ElementGroupModel[] = [];
  //
  //   group.elements.forEach((value) => {
  //     if (value.type === 'group') {
  //       groups.push(value as ElementGroupModel);
  //       const tmpValue = this.getAllElementsFromGroup(value as ElementGroupModel);
  //       tmpValue.elements.forEach((value) => {
  //         elements.push(value);
  //       });
  //       tmpValue.groups.forEach((value1) => {
  //         groups.push(value1);
  //       });
  //     } else {
  //       elements.push(value as ElementModel);
  //     }
  //   });
  //
  //   return { elements, groups };
  // }
}
