<div class="sighup-container">
  <div class="signup-wrapper">
    <div class="user-info">
      <div class="logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24.82"
          height="44.304"
          viewBox="0 0 24.82 44.304"
        >
          <g
            id="Group_377"
            data-name="Group 377"
            transform="translate(-548.973 -374.947)"
          >
            <g
              id="Group_376"
              data-name="Group 376"
              transform="translate(548.973 374.947)"
            >
              <g id="Group_375" data-name="Group 375">
                <path
                  id="Path_243"
                  data-name="Path 243"
                  d="M571.727,439.062H551.039l-2.066,3.909h2v13.754l2.02,2.105h0l1.161,1.21,7.233,7.539,7.233-7.539,1.161-1.21h0l2.02-2.105V442.972h2Z"
                  transform="translate(-548.973 -423.288)"
                  fill="#fa0"
                />
                <g
                  id="Group_373"
                  data-name="Group 373"
                  transform="translate(1.259)"
                >
                  <path
                    id="Path_244"
                    data-name="Path 244"
                    d="M612.842,374.947v.956l-6.93,3.581v6.656h.711v-6.223l6.219-3.214v.865h2.621v-2.621Zm1.91,1.91h-1.2v-1.2h1.2Z"
                    transform="translate(-593.162 -374.947)"
                    fill="#3c3c3c"
                  />
                  <path
                    id="Path_245"
                    data-name="Path 245"
                    d="M554.091,374.947v2.621h2.621V376.7l6.22,3.214v6.223h.711v-6.656l-6.93-3.581v-.956Zm.711.711H556v1.2h-1.2Z"
                    transform="translate(-554.091 -374.947)"
                    fill="#3c3c3c"
                  />
                </g>
                <path
                  id="Path_246"
                  data-name="Path 246"
                  d="M579.343,414.664h-9.35l-3.623,5.292h16.6Z"
                  transform="translate(-562.09 -404.892)"
                  fill="#3c3c3c"
                />
                <path
                  id="Path_247"
                  data-name="Path 247"
                  d="M573.793,442.972l-2.066-3.909H551.039l-2.066,3.91h2v13.754l2.02,2.105h0l1.161,1.21,7.233,7.539,7.233-7.539,1.161-1.21h0l2.02-2.105V442.972Zm-22.469,0h9.7v2.8h-9.7Zm0,6.293h9.7v3.006h-9.7Zm0,7.318v-.817h9.7v3.065h-7.547Zm5.465,5.7h4.239V466.7Zm4.95,0h4.239l-4.239,4.418Zm9.7-5.7-2.157,2.248h-7.547v-3.065h9.7Zm0-4.312h-9.7v-3.006h9.7Zm0-6.5h-9.7v-2.8h9.7Z"
                  transform="translate(-548.973 -423.287)"
                  fill="#3c3c3c"
                />
                <g
                  id="Group_374"
                  data-name="Group 374"
                  transform="translate(9.83 15.777)"
                  opacity="0.56"
                  style="mix-blend-mode: multiply; isolation: isolate"
                >
                  <path
                    id="Path_248"
                    data-name="Path 248"
                    d="M591.5,439.072h-2.577v19.764h5.153V439.072Z"
                    transform="translate(-588.927 -439.072)"
                    fill="#fbb040"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_249"
              data-name="Path 249"
              d="M579.162,532.832h9.868l-4.9,5.458Z"
              transform="translate(-22.761 -119.039)"
              fill="#3c3c3c"
            />
          </g>
        </svg>
        <span class="logo-text" routerLink="/dashboard"
          ><span class="logoThin">Skill</span>BEE</span
        >
      </div>

      <div class="signup-with-google">
        <h2>Create your account</h2>
        <div class="google-btn" (click)="signInWithGoogle()">
          <div class="google-logo">
            <img
              class="logo-google"
              src="/assets/Google-logo.png"
              alt="google logo"
            />
          </div>
          <div class="google-btn-text">
            <p>Sign up with Google</p>
          </div>
        </div>
      </div>

      <div class="or-group">
        <p>Or</p>
      </div>

      <form class="form">
        <div class="row">
          <div class="left-column column">
            <label for="name">Full Name</label>
            <input type="text" id="name" required name="name" />

            <label for="email">Email Address</label>
            <input
              type="email"
              minlength="10"
              required
              name="email"
              id="email"
            />

            <label for="password">Password</label>
            <input
              type="password"
              minlength="6"
              required
              name="email"
              id="password"
            />

            <button class="form-btn" type="submit">LOGIN</button>
          </div>

          <div class="right-column column">
            <label for="user-name">User Name</label>
            <input type="text" id="user-name" required name="user-name" />

            <label for="confirm-email">Confirm Email Address</label>
            <input
              type="email"
              minlength="10"
              required
              name="email"
              id="confirm-email"
            />

            <label for="confirm-password">Confirm Password</label>
            <input
              type="password"
              minlength="6"
              required
              name="email"
              id="confirm-password"
            />
          </div>
        </div>
      </form>

      <div class="login">
        <p>
          Have an account?
          <span routerLink="/login" class="text-link"> Log in now</span>
        </p>
      </div>
    </div>

    <div class="signup-img">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="766.557"
        height="658.056"
        viewBox="0 0 766.557 658.056"
      >
        <g
          id="Group_384"
          data-name="Group 384"
          transform="translate(-97.487 -34.711)"
        >
          <g id="background" transform="translate(97.487 34.711)">
            <g id="window" transform="translate(0 37.441)">
              <rect
                id="eltbx4a9w2vx"
                width="139.354"
                height="243.807"
                transform="translate(12.913 9.289)"
                fill="#fafafa"
              />
              <path
                id="elnpgc090ifr"
                d="M127.394,329.652s-15.282-109.681-23.268-121.741c0,0,45.657-34.274,47.049-127.181H81.9V329.652Z"
                transform="translate(-71.068 -74.224)"
                fill="#f5f5f5"
              />
              <path
                id="elfne75bk6rin"
                d="M164.894,329.272s15.257-109.693,23.255-121.741c0,0-45.645-34.287-47.049-127.181h69.276V329.272Z"
                transform="translate(-56.054 -74.32)"
                fill="#f5f5f5"
              />
              <path
                id="elwcw6zh4ci3a"
                d="M229.187,75.54H73.26V336.7h162.4V75.54ZM114.179,211.221v62.105H86.172V211.221Zm-28.006-4.488V142.459h28.006v64.274Zm70.642,0V142.459h35.1v64.274Zm35.1,4.488v62.105h-35.1V211.221Zm-76.472-4.488V142.459h36.895v64.274Zm36.895,4.488v62.105H115.432V211.221Zm-36.907-69.915V84.892h36.895v56.414Zm36.895,133.136v54.22H115.432v-54.22Zm4.488,0h35.1v54.22h-35.1Zm0-133.1V84.892h35.1v56.414Zm36.28,69.878h29.636v62.105H193.1Zm0-4.488V142.459h29.636v64.274Zm29.636-65.427H193.1V84.892h29.636ZM114.179,84.892v56.414H86.172V84.892ZM86.172,274.442h28.006v54.22H86.172ZM193.1,328.662v-54.22h29.636v54.22Z"
                transform="translate(-73.26 -75.54)"
                fill="#ebebeb"
              />
            </g>
            <g id="screen" transform="translate(171.478 110.265)">
              <rect
                id="elmufjt0dj5hm"
                width="456.024"
                height="305.427"
                fill="#f5f5f5"
              />
              <rect
                id="elerjr0iu30q4"
                width="456.024"
                height="30.513"
                fill="#ebebeb"
              />
              <path
                id="els9zw8kkcs4e"
                d="M251.546,133.675a3.694,3.694,0,1,1-3.681-3.425,3.56,3.56,0,0,1,3.681,3.425Z"
                transform="translate(-207.675 -118.427)"
                fill="#fafafa"
              />
              <path
                id="elwyu237s94wn"
                d="M260.366,133.675a3.694,3.694,0,1,1-3.681-3.425,3.56,3.56,0,0,1,3.681,3.425Z"
                transform="translate(-204.603 -118.427)"
                fill="#fafafa"
              />
              <path
                id="elz5sely54naq"
                d="M269.186,133.675a3.694,3.694,0,1,1-3.681-3.425,3.56,3.56,0,0,1,3.681,3.425Z"
                transform="translate(-201.53 -118.427)"
                fill="#fafafa"
              />
            </g>
            <g id="pictures" transform="translate(672.708 84.956)">
              <path
                id="elhuz0jdr8rea"
                d="M700.41,166.24H608.79V285.717l91.62,2.306Z"
                transform="translate(-608.79 -80.58)"
                fill="#ebebeb"
              />
              <path
                id="elcsrqalny7t"
                d="M692.983,169.4H612.81V277.753l80.173,2.71Z"
                transform="translate(-607.391 -79.479)"
                fill="#fafafa"
              />
              <path
                id="elejqclu3d5i7"
                d="M675.606,184.523,622.4,182.5v75.737h53.206Z"
                transform="translate(-604.049 -74.917)"
                fill="#ebebeb"
              />
              <path
                id="ele1hprlwes9s"
                d="M622.4,258.237l53.206-38.913L622.4,182.5Z"
                transform="translate(-604.049 -74.917)"
                fill="#f5f5f5"
              />
              <path
                id="el6bibl5t0xmx"
                d="M682.424,104.3,631.43,102.71v66.5l50.994,1.281Z"
                transform="translate(-600.903 -102.71)"
                fill="#ebebeb"
              />
              <path
                id="elcwxl800k0wi"
                d="M678.287,106.587,633.67,104.47v60.3l44.617,1.51Z"
                transform="translate(-600.124 -102.098)"
                fill="#fafafa"
              />
              <path
                id="elmk6jyha3bij"
                d="M668.62,112.879l-29.61-1.119v42.149h29.61Z"
                transform="translate(-598.264 -99.559)"
                fill="#ebebeb"
              />
              <path
                id="elcocfdn6h0t7"
                d="M639.01,153.909l29.61-21.654L639.01,111.76Z"
                transform="translate(-598.264 -99.559)"
                fill="#f5f5f5"
              />
            </g>
            <g id="shelfs" transform="translate(383.34)">
              <path
                id="eltbidavrzyuc"
                d="M410.321,62.12l-4.881,45.331h62.577l5.191-45.331Z"
                transform="translate(-363.359 -46.735)"
                fill="#ebebeb"
              />
              <path
                id="el2fbgr9h6tns"
                d="M412.2,64.33,407.44,104h56.482l4.571-39.668Z"
                transform="translate(-362.661 -45.966)"
                fill="#fafafa"
              />
              <path
                id="eliosk3fpkbtd"
                d="M418.776,69.48,415.85,95.813h35.637l1.834-26.333Z"
                transform="translate(-359.732 -44.172)"
                fill="#ebebeb"
              />
              <path
                id="elt62ekzzmpqk"
                d="M471.313,50.71l-6.553,60.716H548.6l6.957-60.716Z"
                transform="translate(-342.695 -50.71)"
                fill="#ebebeb"
              />
              <path
                id="elhgttf3v6a8t"
                d="M473.814,53.67l-6.364,53.138h75.669l6.122-53.138Z"
                transform="translate(-341.757 -49.68)"
                fill="#fafafa"
              />
              <path
                id="el52tp5pk7uor"
                d="M482.634,60.57,478.71,95.829h47.745l2.454-35.259Z"
                transform="translate(-337.835 -47.275)"
                fill="#ebebeb"
              />
              <rect
                id="elkfd527f4ub"
                width="244.158"
                height="6.984"
                transform="translate(0 60.716)"
                fill="#ebebeb"
              />
              <path
                id="elzfjzkrh4rm"
                d="M407.972,117.909,397.28,100.92h15.883v16.989Z"
                transform="translate(-366.201 -33.219)"
                fill="#ebebeb"
              />
              <path
                id="elc9p5hp2zwrv"
                d="M401.11,100.92l9.452,16.989h1.267V100.92Z"
                transform="translate(-364.866 -33.219)"
                fill="#e0e0e0"
              />
              <path
                id="el7tmrsi28p5w"
                d="M512.452,117.909,501.76,100.92h15.884v16.989Z"
                transform="translate(-329.806 -33.219)"
                fill="#ebebeb"
              />
              <path
                id="elt46mp49ykss"
                d="M505.59,100.92l9.452,16.989h1.267V100.92Z"
                transform="translate(-328.472 -33.219)"
                fill="#e0e0e0"
              />
            </g>
          </g>
          <g id="Plant" transform="translate(138.339 421.381)">
            <path
              id="el1qe6leeoim2"
              d="M170.381,425.55c19.9-13.01,24.945-37.943,24.718-60.453,0-3.76.477-7.71-1.253-11.052s-5.9-5.681-9.345-4.189c-2.853,1.253-4.165,4.464-5.813,7.161a22.117,22.117,0,0,1-9.131,8.2c-2.447,1.193-5.466,1.862-7.83.5-3.234-1.862-3.509-6.35-3.4-10.085l.645-20.875a34.087,34.087,0,0,0-.871-11.124c-1.086-3.581-3.581-6.923-7.161-8.009s-8.08.859-8.928,4.5a33.505,33.505,0,0,0-.215,4.655c-.143,1.54-.919,3.246-2.387,3.581s-3.008-.692-4.118-1.814c-3.807-3.867-6.35-8.784-8.7-13.678s-4.691-9.883-8.152-14.084-8.259-7.579-13.678-8.009-11.267,2.733-12.7,7.949,1.48,10.658,4.774,15a84.109,84.109,0,0,0,17.175,16.96,6.851,6.851,0,0,1,2.864,3.2c.764,2.506-1.683,4.917-4.2,5.681-2.841.859-5.9.5-8.856.764s-6.183,1.48-7.364,4.213c-1.647,3.784,1.492,7.853,4.607,10.551a72.507,72.507,0,0,0,21.126,12.723c2.757,1.062,5.657,2.017,7.853,4s3.473,5.383,2.053,7.973-4.917,3.366-7.83,2.757-5.466-2.22-8.223-3.33c-5.156-2.065-11.935-1.862-15.313,2.494a11.7,11.7,0,0,0-1.54,10.42A27.332,27.332,0,0,0,104.7,401.5a72.805,72.805,0,0,0,30.543,22.3c11.935,4.416,23.119,5.8,35.209,1.755"
              transform="translate(-91.686 -290.737)"
              fill="#fa0"
            />
            <path
              id="elr1p6hiimbup"
              d="M96.63,293.25l.358.358,1.1,1.026c.979.883,2.387,2.2,4.2,3.927A140.365,140.365,0,0,1,116.359,314.3c2.733,3.581,5.562,7.519,8.522,11.864s6.087,9,9.178,14.024a169.58,169.58,0,0,1,8.952,16.184c1.384,2.888,2.614,5.908,3.867,8.987l3.784,9.357a338.255,338.255,0,0,1,12.6,36.272,164.708,164.708,0,0,1,5.562,30.793,120.392,120.392,0,0,1-.167,21.114c-.251,2.483-.489,4.4-.7,5.705-.084.621-.155,1.11-.215,1.48a3.993,3.993,0,0,0,0,.5,1.754,1.754,0,0,0,.119-.489l.286-1.468c.251-1.3.537-3.223.835-5.705a115.024,115.024,0,0,0,.382-21.173,161.854,161.854,0,0,0-5.454-30.924,330.314,330.314,0,0,0-12.58-36.355l-3.784-9.369c-1.253-3.067-2.495-6.111-3.9-9.011a163.91,163.91,0,0,0-9.011-16.232c-3.127-5.025-6.266-9.68-9.25-14.024s-5.836-8.355-8.605-11.828a131.731,131.731,0,0,0-14.322-15.635c-1.826-1.707-3.282-3-4.285-3.843l-1.194-.967A2.387,2.387,0,0,0,96.63,293.25Z"
              transform="translate(-90.73 -290.251)"
              fill="#263238"
            />
            <path
              id="elsgftnnxfy1"
              d="M135.841,352.512a7.255,7.255,0,0,0,.406-1.587c.227-1.026.5-2.518.812-4.368.621-3.712,1.313-8.856,2.053-14.537s1.492-10.8,2.172-14.49c.334-1.85.621-3.33.835-4.368a7.472,7.472,0,0,0,.251-1.611,7.162,7.162,0,0,0-.513,1.552c-.286,1.015-.656,2.494-1.05,4.333-.812,3.676-1.647,8.808-2.387,14.49s-1.361,10.682-1.85,14.549c-.239,1.754-.43,3.223-.585,4.4A6.97,6.97,0,0,0,135.841,352.512Z"
              transform="translate(-83.142 -286.71)"
              fill="#263238"
            />
            <path
              id="el7rldxs7kezj"
              d="M95.25,337.388a10.743,10.743,0,0,0,1.934.275c1.194.143,3.055.334,5.275.633,4.44.585,10.563,1.6,17.247,3.008s12.7,2.972,17.008,4.213c2.16.6,3.891,1.134,5.1,1.5a9.545,9.545,0,0,0,1.886.5,11.306,11.306,0,0,0-1.8-.764c-1.194-.442-2.888-1.05-5.037-1.719-4.285-1.361-10.288-2.984-17.008-4.416a161.633,161.633,0,0,0-17.33-2.817c-2.232-.239-4.058-.358-5.311-.418a10.382,10.382,0,0,0-1.957,0Z"
              transform="translate(-90.997 -281.718)"
              fill="#263238"
            />
            <path
              id="elewogsqd7ak"
              d="M152.5,400.843a2.255,2.255,0,0,0,.346-.585l.871-1.719c.764-1.492,1.79-3.676,3.032-6.409,2.483-5.443,5.669-13.069,9.071-21.484s6.517-16.113,8.892-21.6c1.194-2.674,2.1-4.87,2.817-6.493l.752-1.778a3.257,3.257,0,0,0,.227-.633,2.509,2.509,0,0,0-.358.573c-.227.466-.525,1.038-.871,1.719-.752,1.492-1.778,3.688-3.02,6.409-2.483,5.443-5.669,13.129-9.083,21.543s-6.517,16.113-8.88,21.6l-2.817,6.469-.752,1.766A2.387,2.387,0,0,0,152.5,400.843Z"
              transform="translate(-79.917 -281.176)"
              fill="#263238"
            />
            <path
              id="ellnr9y6od8p"
              d="M98.75,372.48a2.388,2.388,0,0,0,.633.275l1.862.668,6.875,2.387c5.8,2.017,13.773,4.882,22.582,8.068s16.829,5.968,22.677,7.865c2.924.955,5.3,1.695,6.946,2.172l1.9.549a3.111,3.111,0,0,0,.68.143,2.64,2.64,0,0,0-.633-.286l-1.862-.656-6.875-2.387c-5.8-2.005-13.773-4.87-22.594-8.056s-16.817-5.968-22.677-7.877c-2.924-.943-5.3-1.683-6.946-2.172l-1.9-.537A2.484,2.484,0,0,0,98.75,372.48Z"
              transform="translate(-90.319 -274.917)"
              fill="#263238"
            />
          </g>
          <g id="Floor" transform="translate(125.456 576.215)">
            <path
              id="elp0zygtdhlko"
              d="M786.083,441.661c0,.2-151.2.351-337.693.351s-337.72-.148-337.72-.351,151.177-.351,337.72-.351S786.083,441.472,786.083,441.661Z"
              transform="translate(-110.67 -441.31)"
              fill="#263238"
            />
          </g>
          <g id="Device" transform="translate(202.986 170.943)">
            <path
              id="el6o8e27obfy7"
              d="M641.507,140.74H182.489a14.319,14.319,0,0,0-14.319,14.333v324.21a14.319,14.319,0,0,0,14.319,14.333H358.664s11.2,34.653-23.542,52.586H499.85s-39.21-13.484-29.124-52.586H641.507a14.333,14.333,0,0,0,14.333-14.333V155.073A14.333,14.333,0,0,0,641.507,140.74Z"
              transform="translate(-168.17 -140.74)"
              fill="#263238"
            />
            <path
              id="elawu29ujrpz"
              d="M357.836,377.777c6.243,8.926-3.533,18.7-12.459,12.459a3.2,3.2,0,0,1-.769-.769c-6.229-8.926,3.533-18.688,12.459-12.459A3.2,3.2,0,0,1,357.836,377.777Z"
              transform="translate(-107.394 -59.123)"
              fill="#455a64"
            />
            <rect
              id="elpvufh4m63hc"
              width="466.002"
              height="276.411"
              rx="3.27"
              transform="translate(10.827 16.382)"
              fill="#e0e0e0"
            />
            <rect
              id="elwbztggmwm7d"
              width="466.002"
              height="256.159"
              transform="translate(10.827 36.635)"
              fill="#fafafa"
            />
            <path
              id="elgqdmy4deg7d"
              d="M206.473,161.761a3.721,3.721,0,1,1-3.721-3.721A3.721,3.721,0,0,1,206.473,161.761Z"
              transform="translate(-157.42 -134.714)"
              fill="#fa0"
            />
            <path
              id="elx0pxa4e9x09"
              d="M189.743,161.761a3.721,3.721,0,1,1-3.721-3.721A3.721,3.721,0,0,1,189.743,161.761Z"
              transform="translate(-163.248 -134.714)"
              fill="#fa0"
            />
            <path
              id="eldan8egki7m"
              d="M197.773,161.761a3.721,3.721,0,1,1-3.721-3.721,3.721,3.721,0,0,1,3.721,3.721Z"
              transform="translate(-160.451 -134.714)"
              fill="#fa0"
            />
            <path
              id="elhga3j5ft5a"
              d="M366.189,256.468c0,.2-17.07.378-38.118.378s-38.131-.175-38.131-.378,17.07-.378,38.131-.378S366.189,256.252,366.189,256.468Z"
              transform="translate(-125.752 -100.558)"
              fill="#455a64"
            />
            <path
              id="eleftyb19gne6"
              d="M366.189,264.041c0,.2-17.07.378-38.118.378s-38.131-.175-38.131-.378,17.07-.391,38.131-.391S366.189,263.825,366.189,264.041Z"
              transform="translate(-125.752 -97.925)"
              fill="#455a64"
            />
            <path
              id="elvb3r9uobaff"
              d="M335.635,271.6c0,.216-10.22.391-22.841.391s-22.854-.175-22.854-.391,10.234-.378,22.854-.378S335.635,271.4,335.635,271.6Z"
              transform="translate(-125.752 -95.288)"
              fill="#455a64"
            />
            <rect
              id="elubegf43nrld"
              width="36.257"
              height="4.598"
              transform="translate(164.188 51.628)"
              fill="#455a64"
            />
            <rect
              id="ely8a294m9nvd"
              width="79.674"
              height="77.085"
              transform="translate(164.188 66.446)"
              fill="#e0e0e0"
            />
            <path
              id="eluaz4p7z7sc8"
              d="M294.52,252.538l22.045-39.668,18.027,25.362,10.949-13.47,16.3,27.776Z"
              transform="translate(-124.157 -115.614)"
              fill="#fa0"
            />
            <circle
              id="elmb3g94nbxkj"
              cx="5.73"
              cy="5.73"
              r="5.73"
              transform="translate(206.647 83.813)"
              fill="#fa0"
            />
            <path
              id="elx0ymo145h5d"
              d="M361.927,259.162a1.1,1.1,0,0,1,0-.31v-4.369c0-3.061,0-7.483-.081-13.092,0-11.259-.094-27.291-.162-46.545l.324.324H294.7c-.094.081.7-.715.364-.364h0v64.289l-.337-.337,48.675.175,13.726.081h.094l-13.645.081-48.891.175h-.324v-64.4h0c-.337.324.458-.485.391-.391h67.633v.324c-.067,19.335-.121,35.435-.162,46.747,0,5.582-.067,9.978-.094,13.025v4.261a2.246,2.246,0,0,1-.2.324Z"
              transform="translate(-124.241 -122.022)"
              fill="#455a64"
            />
            <path
              id="el3e0on5rkdf8"
              d="M438.229,256.468c0,.2-17.07.378-38.131.378s-38.118-.175-38.118-.378,17.057-.378,38.118-.378S438.229,256.252,438.229,256.468Z"
              transform="translate(-100.657 -100.558)"
              fill="#455a64"
            />
            <path
              id="elkj7qenc40bk"
              d="M438.229,264.041c0,.2-17.07.378-38.131.378s-38.118-.175-38.118-.378,17.057-.391,38.118-.391S438.229,263.825,438.229,264.041Z"
              transform="translate(-100.657 -97.925)"
              fill="#455a64"
            />
            <path
              id="elifiavjgvrwq"
              d="M407.668,271.6c0,.216-10.234.391-22.854.391S362,271.813,362,271.6s10.22-.378,22.841-.378S407.668,271.4,407.668,271.6Z"
              transform="translate(-100.65 -95.288)"
              fill="#455a64"
            />
            <rect
              id="el4ny1j10f2er"
              width="36.257"
              height="4.598"
              transform="translate(261.323 51.628)"
              fill="#455a64"
            />
            <rect
              id="eliakr3279mq"
              width="79.674"
              height="77.085"
              transform="translate(261.323 66.446)"
              fill="#e0e0e0"
            />
            <path
              id="el08sidmfzw4by"
              d="M366.56,252.538l22.032-39.668,18.027,25.362,10.962-13.47,16.288,27.776Z"
              transform="translate(-99.062 -115.614)"
              fill="#fa0"
            />
            <path
              id="elej5pp0iqymc"
              d="M404.968,208.63a5.744,5.744,0,1,1-5.73-5.73,5.744,5.744,0,0,1,5.73,5.73Z"
              transform="translate(-89.684 -119.087)"
              fill="#fa0"
            />
            <path
              id="elyci7y8aaqz"
              d="M433.959,259.162v-4.679c0-3.061,0-7.483-.081-13.092,0-11.259-.108-27.291-.175-46.545l.324.324H366.716c-.094.081.715-.715.378-.364h0v64.289l-.324-.337,48.675.175,13.726.081h.094l-13.659.081-48.878.175h-.337v-64.4h0c-.351.324.458-.485.378-.391h67.646v.324c-.081,19.335-.135,35.435-.175,46.747,0,5.582-.067,9.978-.081,13.025v4.261A1.147,1.147,0,0,1,433.959,259.162Z"
              transform="translate(-99.151 -122.022)"
              fill="#455a64"
            />
            <rect
              id="el85s25oxfbcv"
              width="36.257"
              height="4.598"
              transform="translate(166.736 190.535)"
              fill="#455a64"
            />
            <rect
              id="elartnlf7dbmv"
              width="79.674"
              height="77.085"
              transform="translate(166.736 205.34)"
              fill="#e0e0e0"
            />
            <path
              id="elgni744z70ih"
              d="M296.41,355.558l22.045-39.668,18.027,25.349,10.949-13.456,16.3,27.776Z"
              transform="translate(-123.498 -79.727)"
              fill="#fa0"
            />
            <path
              id="elbzh9lczsgbe"
              d="M334.8,311.654a5.73,5.73,0,1,1-1.678-4.066A5.744,5.744,0,0,1,334.8,311.654Z"
              transform="translate(-114.128 -83.204)"
              fill="#fa0"
            />
            <path
              id="eleu5i1m1ls3"
              d="M363.785,362.187v-4.692c0-3.047,0-7.47-.094-13.092,0-11.259-.094-27.291-.162-46.531l.324.324H296.543l.378-.378v64.357l-.337-.337,48.675.175,13.726.081h4.6a1.642,1.642,0,0,1,.324,0h-4.773l-13.659.094-48.891.162h-.324V297.871l.391-.391h67.633v.337c-.067,19.335-.121,35.435-.162,46.747,0,5.582,0,9.978-.081,13.012v4.274a.9.9,0,0,1-.256.337Z"
              transform="translate(-123.55 -86.14)"
              fill="#455a64"
            />
            <rect
              id="elrie0a1suybq"
              width="36.257"
              height="4.598"
              transform="translate(263.939 190.535)"
              fill="#455a64"
            />
            <rect
              id="eljdupas2wivi"
              width="79.674"
              height="77.085"
              transform="translate(263.939 205.34)"
              fill="#e0e0e0"
            />
            <path
              id="ela0ctybyibc"
              d="M368.5,355.558l22.045-39.668,18.027,25.349,10.949-13.456,16.3,27.776Z"
              transform="translate(-98.386 -79.727)"
              fill="#fa0"
            />
            <path
              id="elfr4j4sl03v"
              d="M406.871,311.654a5.73,5.73,0,1,1-1.674-4.061A5.73,5.73,0,0,1,406.871,311.654Z"
              transform="translate(-89.012 -83.204)"
              fill="#fa0"
            />
            <path
              id="el0y4jx7ewhhfm"
              d="M435.879,362.187a1.819,1.819,0,0,1,0-.324c0-.229,0-.526,0-.9v-3.465c0-3.047,0-7.47-.081-13.092,0-11.259-.094-27.291-.162-46.531l.324.324H368.637l.378-.378v64.316l-.337-.337,48.675.175,13.726.081h.135l-13.645.094-48.891.162h-.337V297.871l.378-.391h67.646v.337c-.067,19.335-.121,35.435-.162,46.747,0,5.582-.067,9.978-.094,13.012v4.274a2.24,2.24,0,0,1-.229.337Z"
              transform="translate(-98.442 -86.14)"
              fill="#455a64"
            />
            <rect
              id="el4zjuiswp4vr"
              width="36.257"
              height="4.598"
              transform="translate(364.229 190.535)"
              fill="#455a64"
            />
            <rect
              id="el4jmkkz1g9nh"
              width="79.674"
              height="77.085"
              transform="translate(364.229 205.34)"
              fill="#e0e0e0"
            />
            <path
              id="elu5rmnwcxymn"
              d="M442.88,355.558l22.046-39.668,18.027,25.349L493.9,327.782l16.3,27.776Z"
              transform="translate(-72.476 -79.727)"
              fill="#fa0"
            />
            <path
              id="elhns51rvy6tb"
              d="M481.268,311.654a5.731,5.731,0,1,1-1.678-4.066A5.744,5.744,0,0,1,481.268,311.654Z"
              transform="translate(-63.105 -83.204)"
              fill="#fa0"
            />
            <path
              id="elfo4mr8b5nyd"
              d="M510.255,362.187v-4.692c0-3.047,0-7.47-.094-13.092,0-11.259-.094-27.291-.162-46.531l.324.324H443.013l.378-.378v64.316l-.337-.337,48.675.175,13.726.081h4.584a1.716,1.716,0,0,1,.337,0H505.6l-13.659.094-48.891.162h-.324V297.871l.391-.391h67.633v.337c-.067,19.335-.121,35.435-.162,46.747,0,5.582-.067,9.978-.081,13.012v4.274A.9.9,0,0,1,510.255,362.187Z"
              transform="translate(-72.528 -86.14)"
              fill="#455a64"
            />
            <g id="frame" transform="translate(0 0)">
              <path
                id="elnv75v0z2asq"
                d="M491.86,249.43a51.64,51.64,0,0,1,8.737-.378l-.378.378h0v-3.371c0-.957,0-1.888.081-2.778a8.492,8.492,0,0,1,.27-2.562,9.438,9.438,0,0,1,.256,2.562c0,.89,0,1.82.081,2.778v3.371h0l-.391.391A49.161,49.161,0,0,1,491.86,249.43Z"
                transform="translate(-55.414 -105.912)"
                fill="#455a64"
              />
              <path
                id="el6j96nsh1mpt"
                d="M462.39,247.278a130.974,130.974,0,0,1,19.875,0A126.468,126.468,0,0,1,462.39,247.278Z"
                transform="translate(-65.68 -103.76)"
                fill="#455a64"
              />
              <path
                id="elluhbuefjgc9"
                d="M441.279,240.7a50,50,0,0,1,.378,8.737l-.378-.378h3.411l2.778.081a9.855,9.855,0,0,1,2.562.27,8.588,8.588,0,0,1-2.562.27l-2.778.081h-3.411l-.378-.391A51.64,51.64,0,0,1,441.279,240.7Z"
                transform="translate(-73.18 -105.92)"
                fill="#455a64"
              />
              <path
                id="el182zdvbvyphh"
                d="M441.279,211.23a64.618,64.618,0,0,1,.378,9.937,64.363,64.363,0,0,1-.378,9.924,66.455,66.455,0,0,1-.378-9.924A66.723,66.723,0,0,1,441.279,211.23Z"
                transform="translate(-73.18 -116.186)"
                fill="#455a64"
              />
              <path
                id="elas11d7wg618"
                d="M450,190.109a50.222,50.222,0,0,1-8.751.378l.378-.378h0v3.384c0,.957,0,1.874-.081,2.778a8.588,8.588,0,0,1-.27,2.562,8.593,8.593,0,0,1-.27-2.562c0-.9,0-1.82-.081-2.778v-3.384h0l.378-.378a51.866,51.866,0,0,1,8.7.378Z"
                transform="translate(-73.154 -123.689)"
                fill="#455a64"
              />
              <path
                id="elpwe8pf8avkg"
                d="M482.265,190.1a130.957,130.957,0,0,1-19.875,0,130.973,130.973,0,0,1,19.875,0Z"
                transform="translate(-65.68 -123.678)"
                fill="#455a64"
              />
              <path
                id="elz4e10616d7b"
                d="M500.625,198.844a49.379,49.379,0,0,1-.391-8.751c.769.755.216.216.378.378h-3.452l-2.778-.081a8.591,8.591,0,0,1-2.562-.27,8.589,8.589,0,0,1,2.562-.27l2.778-.081h3.4l.391.378a51.861,51.861,0,0,1-.324,8.7Z"
                transform="translate(-55.428 -123.66)"
                fill="#455a64"
              />
              <path
                id="eli1l29nqoxeo"
                d="M498.464,231.131a63.266,63.266,0,0,1-.391-9.924,63.527,63.527,0,0,1,.391-9.937,66.721,66.721,0,0,1,.377,9.937,66.46,66.46,0,0,1-.378,9.924Z"
                transform="translate(-53.266 -116.17)"
                fill="#455a64"
              />
            </g>
            <path
              id="elt9vb7cbd8ki"
              d="M517.222,256.388c0,.2-17.084.378-38.131.378s-38.131-.175-38.131-.378,17.07-.378,38.131-.378S517.222,256.172,517.222,256.388Z"
              transform="translate(-73.145 -100.586)"
              fill="#455a64"
            />
            <path
              id="elyao8a8opcto"
              d="M517.209,263.961c0,.2-17.084.378-38.131.378S441,264.15,441,263.961s17.07-.391,38.131-.391S517.209,263.745,517.209,263.961Z"
              transform="translate(-73.131 -97.953)"
              fill="#455a64"
            />
            <rect
              id="elo58zb9zojs"
              width="36.257"
              height="4.598"
              transform="translate(367.815 51.52)"
              fill="#455a64"
            />
            <path
              id="elwvy3cim1wi"
              d="M282.654,233.542a39.1,39.1,0,1,1-39.1-39.1A39.1,39.1,0,0,1,282.654,233.542Z"
              transform="translate(-155.532 -122.034)"
              fill="#e0e0e0"
            />
            <path
              id="elu2ogaurlbub"
              d="M291.335,286.7c0,.2-20.063.378-44.806.378s-44.819-.175-44.819-.378,20.063-.391,44.819-.391S291.335,286.485,291.335,286.7Z"
              transform="translate(-156.486 -90.031)"
              fill="#455a64"
            />
            <path
              id="ellarjjayf3fj"
              d="M291.335,294.1c0,.216-20.063.378-44.806.378s-44.819-.162-44.819-.378,20.063-.378,44.819-.378S291.335,293.814,291.335,294.1Z"
              transform="translate(-156.486 -87.45)"
              fill="#455a64"
            />
            <path
              id="elbnyhh6gybga"
              d="M291.335,301.4c0,.216-20.063.378-44.806.378s-44.819-.162-44.819-.378,20.063-.4,44.886-.4S291.335,301.2,291.335,301.4Z"
              transform="translate(-156.486 -84.914)"
              fill="#455a64"
            />
            <path
              id="el2rcgzgacpx7"
              d="M253.329,308.908c0,.216-11.636.378-25.983.378s-26-.162-26-.378,11.636-.378,26-.378S253.329,308.705,253.329,308.908Z"
              transform="translate(-156.612 -82.291)"
              fill="#455a64"
            />
            <g id="el5burin3psiv" transform="translate(45.224 176.445)">
              <rect
                id="Rectangle_160"
                data-name="Rectangle 160"
                width="61.754"
                height="4.517"
                fill="#455a64"
              />
            </g>
            <rect
              id="eljyv9p7va19"
              width="34.626"
              height="4.22"
              transform="translate(45.224 242.096)"
              fill="#fa0"
            />
            <g id="bee" transform="translate(-95.915 55.598)">
              <path
                id="bee-left_wing"
                data-name="bee-left wing"
                d="M173.931,55.268a26.434,26.434,0,0,1-4.9-6.56c-1.824-3.917-.032-12.209,4.353-13.368a8.656,8.656,0,0,1,10.271,5.9c1.209,4.228,1.341,10.286.315,11.8S173.931,55.268,173.931,55.268Z"
                transform="translate(-0.92 1)"
                fill="#fff"
                stroke="#3c3c3c"
                stroke-linecap="square"
                stroke-miterlimit="2"
                stroke-width="1"
              />
              <path
                id="bee-right-wing"
                d="M176.045,45.85a13.428,13.428,0,0,1,3.48-7.442c5.1-5.082,11.289-.293,10.76,6.727-.534,7.059-4.853,10.1-4.853,10.1s-5.138-3.588-9.185-2.965A27.9,27.9,0,0,1,176.045,45.85Z"
                transform="translate(7.679 1.362)"
                fill="#fff"
                stroke="#3c3c3c"
                stroke-linecap="square"
                stroke-miterlimit="2"
                stroke-width="1"
              />
              <g id="bee-body" transform="translate(168.257 52.947)">
                <g
                  id="Group_385"
                  data-name="Group 385"
                  transform="translate(0.941 0.869)"
                >
                  <g id="Layer0_18_FILL" data-name="Layer0 18 FILL">
                    <path
                      id="Path_252"
                      data-name="Path 252"
                      d="M168.7,53.5a9.959,9.959,0,0,0,3.826,7.971,11.147,11.147,0,0,0,4.783,2.657q.956-2.232.425-7.227a30.4,30.4,0,0,0-1.8-8.29l.317-4.039c-.848.283-1.63.565-2.338.85a6.382,6.382,0,0,0-1.807.957,8.952,8.952,0,0,0-3.4,7.121m22-8.077a11.9,11.9,0,0,0-3.08-1.275q.953,3.826,1.7,8.715a78.677,78.677,0,0,1,.638,10.415,11.83,11.83,0,0,0,3.082-1.807,9.14,9.14,0,0,0,3.188-8.183,8.582,8.582,0,0,0-3.188-6.483A18.272,18.272,0,0,0,190.7,45.42Z"
                      transform="translate(-168.7 -43.614)"
                      fill="#3c3c3c"
                    />
                    <path
                      id="Path_253"
                      data-name="Path 253"
                      d="M183.792,44.426a12.655,12.655,0,0,0-2.553-.531q-1.275.105-2.444.106-3.931.424-6.377.85L172.1,48.89a30.4,30.4,0,0,1,1.8,8.29q.533,5-.425,7.227a14.014,14.014,0,0,0,3.4.425,28.213,28.213,0,0,0,9.244-1.275,78.677,78.677,0,0,0-.638-10.415Q184.747,48.254,183.792,44.426Z"
                      transform="translate(-164.872 -43.895)"
                      fill="#fa0"
                    />
                  </g>
                </g>
                <g id="Group_387" data-name="Group 387">
                  <g id="Layer0_18_1_STROKES" data-name="Layer0 18 1 STROKES">
                    <g id="Group_386" data-name="Group 386">
                      <path
                        id="Path_254"
                        data-name="Path 254"
                        d="M193.317,48.206c3,1.569,2.944,7.289,1.9,9.863a9.788,9.788,0,0,1-6.511,5.418c-4.5,1.326-10.252,1.622-14.192-1.141-5.018-3.518-6.13-11.287-.676-14.813,2.8-1.815,6.574-1.907,9.761-2.266,1.137-.13-.07-1.826-.965-1.724-5.947.674-13.149,1.509-14.263,8.764-.861,5.616,3.067,10.938,8.2,12.921,5.722,2.211,15.506,1.161,19.315-4.087,2.842-3.92,2.621-11.89-2.132-14.377-1.241-.648-1.471.9-.44,1.443Z"
                        transform="translate(-168.257 -43.428)"
                        fill="#3c3c3c"
                      />
                      <path
                        id="Path_255"
                        data-name="Path 255"
                        d="M180.709,44.062a12.607,12.607,0,0,0-2.621-.561c-.859-.06-1.749.085-2.614.089-1.3.011-.417,1.749.523,1.743.71,0,1.416-.055,2.126-.077a8.429,8.429,0,0,1,2.631.453c1.337.393.931-1.36-.045-1.647Z"
                        transform="translate(-160.871 -43.486)"
                        fill="#3c3c3c"
                      />
                      <path
                        id="Path_256"
                        data-name="Path 256"
                        d="M183.735,46.539a22.225,22.225,0,0,0-5.663-2.759c-.559-.166-1.029.147-.884.748a82.437,82.437,0,0,1,2.3,18.913c.006.9,1.9,1.756,1.888.585A84.411,84.411,0,0,0,179,44.68l-.884.748a20.468,20.468,0,0,1,5.178,2.555c1.182.759,1.4-.829.44-1.443Z"
                        transform="translate(-158.235 -43.205)"
                        fill="#3c3c3c"
                      />
                      <path
                        id="Path_257"
                        data-name="Path 257"
                        d="M171.889,44.644a13.069,13.069,0,0,0,.31,5.648c1.178,3.839,2.2,9.27.829,13.164-.308.869,1.482,1.992,1.834.988,1.214-3.435.519-7.516-.138-11-.317-1.671-1.107-3.392-1.216-5.05a22.142,22.142,0,0,1,.268-3.167c.077-.9-1.788-1.764-1.888-.587Z"
                        transform="translate(-164.345 -42.828)"
                        fill="#3c3c3c"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="Character" transform="translate(603.889 315.734)">
            <path
              id="eluqbl160uwg"
              d="M599.659,211.66l5.906,19.807a9.286,9.286,0,0,1,.526,2.764c0,2.791-2.7,4.989-5.393,5.5a18.579,18.579,0,0,1-8.238-.971c-.728,2.845-4.045,4.045-6.944,4.584a63.48,63.48,0,0,1-38.185-4.76,104.172,104.172,0,0,0,12.31-23.542l30.122,1.348a13.9,13.9,0,0,0,5.285-.445,4.706,4.706,0,0,0,3.29-3.816"
              transform="translate(-436.995 -188.474)"
              fill="#fa0"
            />
            <path
              id="elyoa3dfvebh"
              d="M563.058,254.873c.378-2.211-.526-11.515-.526-11.515s-8.481-.9-8.993-9.317,0-27.83,0-27.83l15.452-7.551,17.016,12.877-2.575,42.082-18.4,16.423Z"
              transform="translate(-434.911 -193.003)"
              fill="#ffbe9d"
            />
            <path
              id="ely9x3tvry3h"
              d="M554.73,215.025a1.079,1.079,0,1,0,1.079-1.065,1.065,1.065,0,0,0-1.079,1.065Z"
              transform="translate(-434.417 -187.673)"
              fill="#263238"
            />
            <path
              id="eltbtywnrq9p8"
              d="M554.013,214.216c.148.148.944-.526,2.1-.593s2.023.5,2.144.337-.081-.324-.458-.58a2.818,2.818,0,0,0-1.739-.458,2.764,2.764,0,0,0-1.658.661C554.013,213.892,553.878,214.162,554.013,214.216Z"
              transform="translate(-434.685 -188.037)"
              fill="#263238"
            />
            <path
              id="elkjnb3obfyaa"
              d="M564,215.025a1.079,1.079,0,1,0,1.065-1.065A1.065,1.065,0,0,0,564,215.025Z"
              transform="translate(-431.188 -187.673)"
              fill="#263238"
            />
            <path
              id="elbedu3313c4"
              d="M563.495,214.216c.135.148.93-.526,2.09-.593s2.036.5,2.157.337-.081-.324-.472-.58a2.845,2.845,0,0,0-3.384.2C563.535,213.892,563.428,214.162,563.495,214.216Z"
              transform="translate(-431.371 -188.037)"
              fill="#263238"
            />
            <path
              id="elvr8a4a92p7"
              d="M564.679,222a21.463,21.463,0,0,1-5.609,1.942,3.276,3.276,0,0,0,3.843,1.092A2.413,2.413,0,0,0,564.679,222Z"
              transform="translate(-432.905 -184.872)"
              fill="#fafafa"
            />
            <path
              id="elhg0hq6y3n1t"
              d="M560.15,232.782A20.994,20.994,0,0,0,571.3,229.02s-2.521,6.175-10.962,5.825Z"
              transform="translate(-432.529 -182.427)"
              fill="#eb996e"
            />
            <path
              id="eli6r8ufb2cvf"
              d="M611.384,237.2a6.081,6.081,0,0,1-3.047-.688,8.616,8.616,0,0,1-3.33-3.236,33.174,33.174,0,0,1-2.481-5.326c-1.577-3.91-2.7-7.551-3.573-10.194-.121-.418-.243-.8-.351-1.16a10.786,10.786,0,0,0-.216-1.16c-4.6-17.529-20.023-20.818-23.07-20.818-3.276,0-9.007-1.807-17.353,6.877a18.576,18.576,0,0,0-3.169,4.369,18.876,18.876,0,0,0-3.708,7.928,9.655,9.655,0,0,0-.189,4.166c.162,0,.189-1.578.836-4.045a20.462,20.462,0,0,1,1.618-4.139,16.059,16.059,0,0,0,.85,9.695l2.993-.836,10.787-3.641c-.984-3.317.458-7.443,1.267-9.438h.081c2.454,12.594,16.328,13.915,16.328,13.915l-.688,23.906c14.252-3.047,14.441-18.607,14.346-22.477.647,2.184,1.483,4.692,2.535,7.321a32.632,32.632,0,0,0,2.616,5.393,8.616,8.616,0,0,0,3.694,3.344,5.717,5.717,0,0,0,3.317.485,2.373,2.373,0,0,0,1.16-.431,9.755,9.755,0,0,0-1.254.189Z"
              transform="translate(-435.802 -194.464)"
              fill="#fa0"
            />
            <path
              id="elkqlelriraql"
              d="M561.149,223.752a8,8,0,0,0-1.874-.2c-.3,0-.593,0-.647-.27a1.631,1.631,0,0,1,.162-.944c.256-.755.512-1.551.8-2.4.553-1.712,1.025-3.263,1.348-4.4a7.022,7.022,0,0,0,.418-1.861,7.685,7.685,0,0,0-.728,1.78c-.4,1.106-.93,2.7-1.483,4.369l-.769,2.414a1.914,1.914,0,0,0-.108,1.214.768.768,0,0,0,.5.418,2.253,2.253,0,0,0,.5,0,7.872,7.872,0,0,0,1.888-.121Z"
              transform="translate(-433.205 -187.771)"
              fill="#263238"
            />
            <path
              id="eleadp6ewkh"
              d="M583.721,218.415c-2.616-.512-4.827-.876-5.582-.93a6.614,6.614,0,0,0,.162-1.348,6.916,6.916,0,0,0-1.348-4.045,6.635,6.635,0,0,0-3.182-2.36,6.81,6.81,0,0,0-3.573-.27,6.459,6.459,0,0,0-2.832,1.348,6.567,6.567,0,0,0-2.386,3.735,5.39,5.39,0,0,0-.148,1.213,1.345,1.345,0,0,0,0,.418,6.174,6.174,0,0,1,.3-1.578,6.594,6.594,0,0,1,2.414-3.533,6.284,6.284,0,0,1,2.7-1.173,6.391,6.391,0,0,1,6.31,10.045,6.3,6.3,0,0,1-2.966,2.238,6.391,6.391,0,0,1-3.344.3,6.3,6.3,0,0,1-2.7-1.187,6.526,6.526,0,0,1-2.414-3.519,7.136,7.136,0,0,1-.229-1.214c.2,0,.337-.108.337-.162s-.162-.135-.391-.175h0a4.54,4.54,0,0,0-.809,0,4.2,4.2,0,0,0-.971.108v-.081a6.823,6.823,0,0,0-1.348-4.045,6.66,6.66,0,0,0-3.169-2.36,6.863,6.863,0,0,0-3.587-.27,6.742,6.742,0,0,0-5.218,5.029,5.4,5.4,0,0,0-.148,1.213,1.348,1.348,0,0,0,0,.418,6.173,6.173,0,0,1,.3-1.578,6.594,6.594,0,0,1,2.413-3.533,6.283,6.283,0,0,1,2.7-1.173,6.392,6.392,0,1,1,0,12.58,6.3,6.3,0,0,1-2.7-1.187,6.526,6.526,0,0,1-2.414-3.519c-.27-1-.243-1.591-.3-1.591a1.543,1.543,0,0,0,0,.431,5.262,5.262,0,0,0,.148,1.2,6.58,6.58,0,0,0,2.387,3.748,6.742,6.742,0,0,0,2.831,1.348,6.863,6.863,0,0,0,3.587-.27,6.742,6.742,0,0,0,3.169-2.36,6.849,6.849,0,0,0,1.348-3.708,3.72,3.72,0,0,0,1,.108,6.1,6.1,0,0,0,.782,0h0a5.255,5.255,0,0,0,.148,1.2,6.674,6.674,0,0,0,5.218,5.043,6.794,6.794,0,0,0,6.755-2.629,6.54,6.54,0,0,0,1.133-2.535c.715.229,2.9.742,5.542,1.254a40.129,40.129,0,0,0,5.879.93A35.786,35.786,0,0,0,583.721,218.415Z"
              transform="translate(-436.212 -189.283)"
              fill="#263238"
            />
            <path
              id="elfojrxr6g6o"
              d="M578.232,222.64a10.259,10.259,0,0,1,.458-4.153,3.923,3.923,0,0,1,3.02-2.616,3.317,3.317,0,0,1,3.425,2.7,4.045,4.045,0,0,1-2.09,4.045,5.394,5.394,0,0,1-4.679.135"
              transform="translate(-426.246 -187.009)"
              fill="#ffbe9d"
            />
            <path
              id="el0pjnjwpy88jd"
              d="M580,220.96c0-.094.458.108,1.052,0a1.874,1.874,0,0,0,1.254-1.793,1.348,1.348,0,0,0-.3-1.119,1.254,1.254,0,0,0-.917-.27c-.634,0-1.079.216-1.106.108s.283-.391,1.038-.593a1.6,1.6,0,0,1,1.348.27,1.914,1.914,0,0,1,.58,1.658,2.507,2.507,0,0,1-.62,1.564,1.955,1.955,0,0,1-1.24.661A1.457,1.457,0,0,1,580,220.96Z"
              transform="translate(-425.618 -186.529)"
              fill="#eb996e"
            />
            <path
              id="elo29rek1rxxa"
              d="M606.843,464.343l-10.153-6.89,12.8-19.093,14.953,9.924-.715,1.227c-3.236,5.474-16.841,27.56-20.225,30.378C599.643,483.017,606.843,464.343,606.843,464.343Z"
              transform="translate(-419.801 -109.504)"
              fill="#fa0"
            />
            <path
              id="elt8isdlg663"
              d="M600.8,477.207c-.189-.121,4.49-7.335,10.45-16.1s10.922-15.789,11.11-15.668-4.5,7.335-10.463,16.18S600.977,477.356,600.8,477.207Z"
              transform="translate(-418.37 -107.038)"
              fill="#263238"
            />
            <path
              id="elrpjya2pt56"
              d="M606.305,459.022c0,.216-.634.337-1.348.391s-1.348,0-1.416-.216.553-.526,1.348-.566S606.332,458.807,606.305,459.022Z"
              transform="translate(-417.416 -102.445)"
              fill="#263238"
            />
            <path
              id="elg3p2upn7q87"
              d="M606.278,460.315c0,.216-.742.243-1.564.189s-1.51-.162-1.524-.391.688-.445,1.564-.391S606.318,460.1,606.278,460.315Z"
              transform="translate(-417.536 -102.066)"
              fill="#263238"
            />
            <path
              id="el0rexeuofdh7"
              d="M614.207,451.663c-.094,0-.351-.364-.661-1.052a8.089,8.089,0,0,1-.715-2.926,4.652,4.652,0,0,1,.863-2.939c.5-.647.984-.823,1.038-.742s-.256.418-.58,1.038a5.474,5.474,0,0,0-.539,2.616,14.825,14.825,0,0,0,.458,2.778C614.247,451.151,614.315,451.623,614.207,451.663Z"
              transform="translate(-414.18 -107.544)"
              fill="#263238"
            />
            <path
              id="el0erqvrd7u5nf"
              d="M604.154,458.37c-.094-.189.27-.566.769-.93s.957-.607,1.119-.458-.094.674-.661,1.092S604.235,458.573,604.154,458.37Z"
              transform="translate(-417.206 -103.033)"
              fill="#263238"
            />
            <path
              id="else0uvrp8z9"
              d="M603.587,457.876c-.175-.135,0-.742.458-1.348s.93-1.011,1.106-.9,0,.715-.458,1.348S603.749,458.011,603.587,457.876Z"
              transform="translate(-417.421 -103.496)"
              fill="#263238"
            />
            <path
              id="elpxwu0kof0gl"
              d="M519.489,472.478v-12.27l22.922-.148.189,17.96-1.429.094c-6.337.391-32.252,1.551-36.513.3C499.978,477.009,519.489,472.478,519.489,472.478Z"
              transform="translate(-452.112 -101.945)"
              fill="#fa0"
            />
            <path
              id="elsl1xcc5rxte"
              d="M504.12,472.931c0-.216,8.6-.391,19.2-.391s19.2.175,19.2.391-8.589.4-19.2.4S504.12,473.16,504.12,472.931Z"
              transform="translate(-452.047 -97.598)"
              fill="#263238"
            />
            <path
              id="elrbkwnzvc8z"
              d="M515.622,471.734c-.189.108-.634-.337-1.092-.93s-.769-1.133-.62-1.348.755.175,1.24.823S515.811,471.626,515.622,471.734Z"
              transform="translate(-448.65 -98.693)"
              fill="#263238"
            />
            <path
              id="elej5qsx877lw"
              d="M514.5,472.426c-.2.081-.607-.472-1.025-1.187s-.715-1.348-.539-1.483.755.324,1.214,1.092S514.7,472.345,514.5,472.426Z"
              transform="translate(-448.995 -98.576)"
              fill="#263238"
            />
            <path
              id="elxr5m3edarlm"
              d="M527.465,473.885c-.081,0,.108-.5.5-1.133a8.373,8.373,0,0,1,2.009-2.238,4.786,4.786,0,0,1,2.926-.944c.809,0,1.24.364,1.2.445s-.5,0-1.187.108a5.649,5.649,0,0,0-2.467,1.025,14.356,14.356,0,0,0-2.036,1.942C527.91,473.629,527.559,473.952,527.465,473.885Z"
              transform="translate(-443.921 -98.633)"
              fill="#263238"
            />
            <path
              id="elwn8s6qfhsb"
              d="M515.129,469.446c.108-.189.634-.094,1.2.108s1.052.458,1.011.674-.607.3-1.267.067S514.967,469.635,515.129,469.446Z"
              transform="translate(-448.224 -98.711)"
              fill="#263238"
            />
            <path
              id="elh7n4tqglnj8"
              d="M515.39,468.638c0-.216.634-.391,1.348-.378s1.348.2,1.348.418-.593.378-1.348.378S515.363,468.854,515.39,468.638Z"
              transform="translate(-448.121 -99.089)"
              fill="#263238"
            />
            <path
              id="el1vp2oeyyb11"
              d="M588.666,261.292,563.762,253s-10.989,31.659-14.36,31.969c-18.661,1.7-38.63-4.49-45.642-6.93-.7-.8-1.51-1.753-2.414-2.926a11.137,11.137,0,0,1-1.766-3.357,17.529,17.529,0,0,1-1-4.611,1.47,1.47,0,0,0-2.521-1.16c-.8,1.052-1.011,5.043.836,9.358s-4.679.876-7.079-.337-9.991-4.584-10.949-4.139c-1.348.661-.957,2.279,1.47,3.425s9.438,5.1,8.751,6.337-12.823-5.083-12.823-5.083-2.373-1.766-3.223-.364c-1.537,2.521,11.839,8.279,13.133,8.791.89.351.189,1.726-.755,1.348s-11.946-6.566-13.551-4.045c-1.214,1.955,10.976,4.827,12.553,7.362s-9.034-3.115-10.113-1.187c-.4.715-.62,1.348,5.933,3.668a138.214,138.214,0,0,0,14.643,3.681v.067s65.462,29.273,79.552,1.065C589.38,266,588.666,261.292,588.666,261.292Z"
              transform="translate(-463.31 -174.074)"
              fill="#ffbe9d"
            />
            <path
              id="elzl9dyobonnb"
              d="M550.739,313.011s-15.87,105.468-13.591,110.564,83.894,91.688,83.894,91.688l19.093-32.711s-64.383-71.867-63.9-73.795,22.814-104.5,22.814-104.5Z"
              transform="translate(-440.62 -156.217)"
              fill="#455a64"
            />
            <path
              id="elr9nm5ijnbl"
              d="M549.74,422.583l13.416-29.461,13.416-29.461-22.6,39.169Z"
              transform="translate(-436.155 -135.526)"
              fill="#263238"
            />
            <path
              id="elpbbaeeclr9k"
              d="M555.7,387.87a6.568,6.568,0,0,1,.081,1.16c0,.836,0,1.955.081,3.344v-.108c5.11,5.771,16.436,18.877,29.515,34.194l22.72,26.778,6.823,8.184c.755.917,1.348,1.672,1.82,2.238a4.215,4.215,0,0,1,.593.822,5.4,5.4,0,0,1-.715-.715l-1.928-2.157c-1.726-1.955-4.045-4.679-7.011-8.009l-22.922-26.63c-13.065-15.3-24.27-28.544-29.165-34.491h0c0-1.348,0-2.508.067-3.344a4.647,4.647,0,0,1,.041-1.267Z"
              transform="translate(-434.118 -127.092)"
              fill="#263238"
            />
            <path
              id="elomherp1l4e"
              d="M614.24,431.47c.189.121-4.274,7.2-9.951,15.8s-10.423,15.492-10.6,15.371,4.274-7.2,9.951-15.8S614.065,431.349,614.24,431.47Z"
              transform="translate(-420.847 -111.905)"
              fill="#263238"
            />
            <path
              id="el63oo5oxxxa9"
              d="M542.91,350.1c.148-1.106,12.823-29.664,12.823-29.664l-6.148-8.67-1.092-.243Z"
              transform="translate(-438.535 -153.688)"
              fill="#263238"
            />
            <path
              id="elq3a47zxoscl"
              d="M572.179,312l-12.594,2.1,7.241,8.913c-11.69,26.455-35.839,82.465-38.738,100.519-3.856,23.92-18.378,98.632-16.922,99.778s34.936,0,34.936,0l21.344-99.778,47.192-81.737a45.6,45.6,0,0,0,4.787-33.641l-.944-3.883"
              transform="translate(-449.628 -156.214)"
              fill="#455a64"
            />
            <path
              id="eln8m2y0rp5dd"
              d="M528.41,511.473a3.4,3.4,0,0,1,.067-.553,13.722,13.722,0,0,1,.243-1.631l1.038-6.243c.957-5.461,2.319-13.295,4.045-22.922s3.789-21.209,6.068-33.992c1.187-6.391,2.427-13.106,3.708-20.063.324-1.726.674-3.479,1.011-5.245.175-.89.337-1.766.539-2.7.108-.445.189-.9.324-1.348l.5-1.348c1.348-3.425,2.885-6.742,4.4-10.234,12.2-27.412,23.556-52.087,31.767-69.939l9.8-21.129c1.146-2.454,2.063-4.369,2.7-5.717.3-.62.539-1.119.728-1.483a2.7,2.7,0,0,1,.27-.5l-.2.539-.647,1.51-2.575,5.784c-2.306,5.07-5.6,12.351-9.614,21.25-8.09,17.92-19.362,42.621-31.551,70.033-1.51,3.425-3.034,6.823-4.382,10.18l-.485,1.241c-.135.431-.2.876-.31,1.348-.2.863-.364,1.766-.553,2.7-.337,1.766-.674,3.519-1.011,5.245-1.348,6.944-2.548,13.659-3.748,20.036-2.346,12.782-4.463,24.27-6.243,33.965s-3.223,17.448-4.22,22.922c-.512,2.7-.917,4.746-1.187,6.216-.148.674-.256,1.214-.337,1.618a2.169,2.169,0,0,1-.135.458Z"
              transform="translate(-443.586 -155.462)"
              fill="#263238"
            />
            <path
              id="eluoenwd2dgvr"
              d="M547.8,459.832c0,.216-8.09.243-17.947,0s-17.946-.5-17.946-.715,8.09-.243,17.96-.067S547.816,459.616,547.8,459.832Z"
              transform="translate(-449.333 -102.337)"
              fill="#263238"
            />
            <path
              id="el6gy96bdr7os"
              d="M547.05,314.089l.485,1.146,60.5-4.894-2.13-6.081Z"
              transform="translate(-437.092 -156.217)"
              fill="#263238"
            />
            <path
              id="elv5nfs565py"
              d="M578.2,239.432l-4.045,16.342-.148-11.758.31-2.6.364-1.982-5.393-1.82-2.778,4.328H565.7s-21.776-2.171-29.394,26.6L529.34,285.6l21.1,10.517v1.348l1.348,40.707,60.945-9.87c.243-9.142-9.088-25.7-7.025-33.978l7.025-28.315-9.209-26.387-6.378.2h0L593.6,235.63l-15.331,3.8"
              transform="translate(-443.262 -180.124)"
              fill="#263238"
            />
            <path
              id="el3xk4wi71fka"
              d="M546.728,288.785a3.814,3.814,0,0,1-1.348-.688,10.206,10.206,0,0,1-2.818-2.912,9.2,9.2,0,0,1-1.443-5.811,12.958,12.958,0,0,1,2.373-6.957,12.688,12.688,0,0,1,4.315-3.856l-.189.243c.512-3.735.971-6.944,1.348-9.223a23.073,23.073,0,0,1,.674-3.6,24.809,24.809,0,0,1-.27,3.668c-.229,2.279-.593,5.515-1.052,9.25v.162l-.162.094a12.58,12.58,0,0,0-6.337,10.261,8.913,8.913,0,0,0,1.214,5.515,11.179,11.179,0,0,0,2.562,2.939A7.429,7.429,0,0,1,546.728,288.785Z"
              transform="translate(-439.171 -173.036)"
              fill="#455a64"
            />
            <path
              id="elsxjg237mxl"
              d="M547.546,261.085c-.175.081-.768-.782-1.348-1.942s-.89-2.171-.715-2.252.769.782,1.348,1.942S547.68,260.923,547.546,261.085Z"
              transform="translate(-437.655 -172.72)"
              fill="#455a64"
            />
            <g id="pencil">
              <path
                id="elnuutwxijtv"
                d="M513.043,251.42,704.75,288.243c13.672,2.629,22.275,15.115,20.724,29.34a4.2,4.2,0,0,1-.108.593,25.821,25.821,0,0,1-30.553,20.481L506.935,300.742,465.5,267.627Z"
                transform="translate(-465.5 -174.623)"
                fill="#fa0"
              />
              <path
                id="elntgtjlzlf1i"
                d="M513.043,251.42s10.463,3.924,8.306,13.955-12.283,9.614-12.283,9.614,9.843,2.184,7.119,16.248c-1.766,9.061-9.25,9.533-9.25,9.533L465.5,267.627Z"
                transform="translate(-465.5 -174.623)"
                fill="#f5f0e1"
              />
              <path
                id="elosa5etnvah8"
                d="M474.5,271.786c4.045-2.009,5.461-7.362,4.045-11.636l-13.011,4.436Z"
                transform="translate(-465.49 -171.582)"
                fill="#455a64"
              />
              <path
                id="elgit1xilajqj"
                d="M690.89,308.029l-.5-.067-1.483-.243-5.65-1.011c-4.908-.89-11.987-2.225-20.751-3.91-17.529-3.371-41.65-8.211-68.294-13.713l-68.2-14.185-20.67-4.328-5.609-1.2-1.456-.337a2.147,2.147,0,0,1-.5-.135,2.787,2.787,0,0,1,.512.067l1.47.256L505.4,270.3l20.751,4.126L594.4,288.411c26.63,5.5,50.765,10.423,68.253,13.915l20.7,4.126,5.623,1.146,1.47.31A1.875,1.875,0,0,1,690.89,308.029Z"
                transform="translate(-454.256 -168.535)"
                fill="#455a64"
              />
              <path
                id="el197wgjdz9sq"
                d="M635.57,329.145a25.821,25.821,0,0,0,30.554-20.481,4.224,4.224,0,0,0,.108-.593c1.551-14.225-7.052-26.711-20.724-29.34Z"
                transform="translate(-406.258 -165.109)"
                fill="#455a64"
              />
            </g>
            <path
              id="elxrfw5m16cj"
              d="M604.274,257.11s-11.663,36.8-14.913,37.605c-18.176,4.638-38.873,1.685-46.167.378-.822-.674-1.793-1.51-2.858-2.508a11.365,11.365,0,0,1-2.265-3.034,16.99,16.99,0,0,1-1.712-4.4,1.47,1.47,0,0,0-2.7-.742c-.634,1.16-.2,5.137,2.292,9.1,0,0-14.616,9.277-17.812,9.2s-12.351-.31-11.394.957,11.394,3.007,11.394,3.007l18.877,6.4s69.251,18.634,78.73-11.461c5.8-18.445,9.816-29.3,10.719-33.709Z"
              transform="translate(-451.156 -172.642)"
              fill="#ffbe9d"
            />
            <path
              id="eloen6lswhm3s"
              d="M589.732,238.633s15.236,2.7,15.83,27.641a97.3,97.3,0,0,1-9.546,43.875L569.21,296.073a165.593,165.593,0,0,0,13.484-37.929l6.957-19.524Z"
              transform="translate(-429.373 -179.083)"
              fill="#263238"
            />
            <path
              id="eln7fzzj9p4dr"
              d="M581.908,254.13a6.744,6.744,0,0,1-.175,1.537c-.148.971-.4,2.373-.782,4.045a119.563,119.563,0,0,1-3.735,13.254,117.5,117.5,0,0,1-5.272,12.728c-.769,1.591-1.429,2.858-1.915,3.708a7.393,7.393,0,0,1-.809,1.348,5.68,5.68,0,0,1,.566-1.429c.459-1.038,1.038-2.306,1.713-3.789,1.429-3.209,3.29-7.7,5.056-12.755s3.047-9.735,3.91-13.146q.593-2.387,1.011-4.045A6.281,6.281,0,0,1,581.908,254.13Z"
              transform="translate(-429.37 -173.68)"
              fill="#455a64"
            />
          </g>
        </g>
      </svg>
    </div>
  </div>
</div>

<div *ngIf="userExists" class="user-exists">
  <div class="card-background">
    <div class="message-card">
      <div class="logo-card">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.82"
          height="38.304"
          viewBox="0 0 24.82 44.304"
        >
          <g
            id="Group_377"
            data-name="Group 377"
            transform="translate(-548.973 -374.947)"
          >
            <g
              id="Group_376"
              data-name="Group 376"
              transform="translate(548.973 374.947)"
            >
              <g id="Group_375" data-name="Group 375">
                <path
                  id="Path_243"
                  data-name="Path 243"
                  d="M571.727,439.062H551.039l-2.066,3.909h2v13.754l2.02,2.105h0l1.161,1.21,7.233,7.539,7.233-7.539,1.161-1.21h0l2.02-2.105V442.972h2Z"
                  transform="translate(-548.973 -423.288)"
                  fill="#fa0"
                />
                <g
                  id="Group_373"
                  data-name="Group 373"
                  transform="translate(1.259)"
                >
                  <path
                    id="Path_244"
                    data-name="Path 244"
                    d="M612.842,374.947v.956l-6.93,3.581v6.656h.711v-6.223l6.219-3.214v.865h2.621v-2.621Zm1.91,1.91h-1.2v-1.2h1.2Z"
                    transform="translate(-593.162 -374.947)"
                    fill="#3c3c3c"
                  />
                  <path
                    id="Path_245"
                    data-name="Path 245"
                    d="M554.091,374.947v2.621h2.621V376.7l6.22,3.214v6.223h.711v-6.656l-6.93-3.581v-.956Zm.711.711H556v1.2h-1.2Z"
                    transform="translate(-554.091 -374.947)"
                    fill="#3c3c3c"
                  />
                </g>
                <path
                  id="Path_246"
                  data-name="Path 246"
                  d="M579.343,414.664h-9.35l-3.623,5.292h16.6Z"
                  transform="translate(-562.09 -404.892)"
                  fill="#3c3c3c"
                />
                <path
                  id="Path_247"
                  data-name="Path 247"
                  d="M573.793,442.972l-2.066-3.909H551.039l-2.066,3.91h2v13.754l2.02,2.105h0l1.161,1.21,7.233,7.539,7.233-7.539,1.161-1.21h0l2.02-2.105V442.972Zm-22.469,0h9.7v2.8h-9.7Zm0,6.293h9.7v3.006h-9.7Zm0,7.318v-.817h9.7v3.065h-7.547Zm5.465,5.7h4.239V466.7Zm4.95,0h4.239l-4.239,4.418Zm9.7-5.7-2.157,2.248h-7.547v-3.065h9.7Zm0-4.312h-9.7v-3.006h9.7Zm0-6.5h-9.7v-2.8h9.7Z"
                  transform="translate(-548.973 -423.287)"
                  fill="#3c3c3c"
                />
                <g
                  id="Group_374"
                  data-name="Group 374"
                  transform="translate(9.83 15.777)"
                  opacity="0.56"
                  style="mix-blend-mode: multiply; isolation: isolate"
                >
                  <path
                    id="Path_248"
                    data-name="Path 248"
                    d="M591.5,439.072h-2.577v19.764h5.153V439.072Z"
                    transform="translate(-588.927 -439.072)"
                    fill="#fbb040"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_249"
              data-name="Path 249"
              d="M579.162,532.832h9.868l-4.9,5.458Z"
              transform="translate(-22.761 -119.039)"
              fill="#3c3c3c"
            />
          </g>
        </svg>
        <span class="logo-text" routerLink="/dashboard"
          ><span class="logoThin">Skill</span>BEE</span
        >
      </div>
      <p class="message-text">
        Your user already exists, <br />
        so we've loged you in
      </p>
      <button class="btn-message" (click)="onHive()">OK</button>
    </div>
  </div>
</div>
