import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HivePopupService } from 'src/app/shared/hive-popup/hive-popup.service';

@Component({
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.scss'],
})
export class VideoGalleryComponent implements OnInit, AfterViewInit {
  youtubeReady: boolean = false;
  videoUrl: string;

  constructor(private popupService: HivePopupService) {}

  ngOnInit(): void {
    if (this.popupService.getPrePopupData()) {
      if (this.popupService.getPrePopupData()['format'] === 'video/youtube') {
        this.videoUrl =
          'https://www.youtube.com/watch?v=' +
          this.popupService.getPrePopupData()['url'];
      } else {
        this.videoUrl = this.popupService.getPrePopupData()['url'];
      }
    }
    // setTimeout(()=> {this.updateCurrentResult()},100)
    if (!this.youtubeReady) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.youtubeReady = true;
    }
  }

  ngAfterViewInit(): void {
    if (this.popupService.getPrePopupData()) {
      this.updateCurrentResult();
    }
  }

  findFormat(url: string) {
    let split = url.split('.');

    switch (split[split.length - 1]) {
      case 'mp4':
        return 'video/mp4';
      case 'mkv':
        return 'video/mkv';
    }
  }

  findYoutube(url: string) {
    if (url.indexOf('?v=') >= 0 && url.indexOf('youtube.com') >= 0) {
      let videoSplit = url.split('?v=')[url.split('?v=').length - 1];
      let videoId =
        videoSplit.indexOf('&') < 0 ? videoSplit : videoSplit.split('&')[0];
      return { url: videoId, format: 'video/youtube' };
    }
    return false;
  }

  findWhatKindOfFormat(url) {
    if (url.indexOf('?v=') >= 0 && url.indexOf('youtube.com') >= 0) {
      console.log('youtube video');
      return true;
    } else {
      console.log('not a youtube video');
      return false;
    }
  }

  updateCurrentResult() {
    if (this.videoUrl) {
      if (this.findFormat(this.videoUrl)) {
        this.popupService.updateCurrentResult({
          url: this.videoUrl,
          format: this.findFormat(this.videoUrl),
        });
      }
      if (this.findYoutube(this.videoUrl)) {
        this.popupService.updateCurrentResult(this.findYoutube(this.videoUrl));
      }
    } else {
      this.popupService.updateCurrentResult(false);
    }
  }
}
