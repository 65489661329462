import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserLandingPageService } from '../../user-landing-page.service';
import { UserLandingPageModel } from '../../user-landing-page.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-editor-landing-page-sidebar',
  templateUrl: './user-editor-landing-page-sidebar.component.html',
  styleUrls: ['./user-editor-landing-page-sidebar.component.scss'],
})
export class UserEditorLandingPageSidebarComponent
  implements OnInit, OnDestroy
{
  landingPageData: UserLandingPageModel;
  landingPageDataSub: Subscription;

  selectedSection;
  selectedSectionSub: Subscription;

  backgroundStyleSelected: any;
  logoSectionStyle: boolean;

  autoTicks = false;
  disabled = false;
  invert = false;
  max = 100;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value = 100;
  vertical = false;
  tickInterval = 1;

  logoIsChecked = true;
  footerIsChecked = true;

  showColor: boolean = false;

  backgroundColor: string;

  constructor(private userLandingPageService: UserLandingPageService) {}

  ngOnInit(): void {
    // conflicts that must be resolved (current changes)
    this.landingPageDataSub =
      this.userLandingPageService.selectedLandingPage.subscribe((data) => {
        this.landingPageData = data;
      });

    this.selectedSectionSub =
      this.userLandingPageService.selectedSection.subscribe((data) => {
        this.selectedSection = data;
        console.log(this.selectedSection.section_name);
      });

    this.backgroundStyleSelected =
      this.landingPageData.header.welcome.style.selected_background_type;

    // conflicts that must be resolved (other changes)

    // this.sidebarService.setCurrentComponentPath('default');
    // this.landingPageDataSub = this.userLandingPageService.selectedLandingPage.subscribe( data => {
    //   this.landingPageData = data;

    // })

    // this.selectedSectionSub = this.userLandingPageService.selectedSection.subscribe( data => {
    //   this.selectedSection = data;
    //   console.log(this.selectedSection.section_name);
    //   if(this.selectedSection.section_name === 'header'){
    //     this.backgroundStyleSelected = this.selectedSection.welcome.style.selected_background_type;
    //   }else if(this.selectedSection.section_name === 'courses'){
    //     this.backgroundStyleSelected = this.selectedSection.courses_cards.style.selected_background_type;
    //   }else{
    //     this.backgroundStyleSelected = this.selectedSection.style.selected_background_type;
    //   }
    // })

    // conflicts that must be resolved (other changes)

    this.logoSectionStyle = this.landingPageData.header.logo.is_text;
    this.backgroundColor =
      this.landingPageData.header.welcome.style.background_color;
  }

  onLogoSection() {
    this.landingPageData.header.logo.is_visible = !this.logoIsChecked;
  }

  onFooterSection() {
    this.landingPageData.courses.footer.is_visible = !this.footerIsChecked;
  }

  updateCurrentCourse() {}

  showColorPicker() {
    this.showColor = !this.showColor;
  }

  // conflicts that must be resolved (current changes)
  upDateBackgroundColor(value) {
    let temp =
      this.landingPageData.header.welcome.style.background_color.substr(0, 7);
    if (value === 0) {
      value = '00';
    } else if (value === 100) {
      value = '';
    }
    this.landingPageData.header.welcome.style.background_color = temp + value;

    // conflicts that must be resolved (other changes)

    // upDateBackgroundColor(value){
    //   let temp;
    //   if(value=== 0){
    //     value = "00";
    //   }else if( value===100){
    //     value = '';
    //   }
    //   if( this.selectedSection.section_name === 'header'){
    //     temp = this.selectedSection.welcome.style.background_color.substr(0,7);
    //     this.selectedSection.welcome.style.background_color = temp+value;
    //   }else if(this.selectedSection.section_name === 'courses'){
    //     temp = this.selectedSection.courses_cards.style.background_color.substr(0,7);
    //     this.selectedSection.courses_cards.style.background_color = temp+value;
    //   }else{
    //   temp = this.selectedSection.style.background_color.substr(0,7);
    //     this.selectedSection.style.background_color = temp+value;
    //   }

    // conflicts that must be resolved (other changes)
  }

  ngOnDestroy(): void {
    this.landingPageDataSub.unsubscribe();
  }

  print(event) {
    console.log(event);
  }

  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }

    return 0;
  }

  selectHeaderSectionDesign(sectionName) {
    this.landingPageData.header.welcome.type = sectionName;
    this.selectedSection.welcome.type = sectionName;
    if (sectionName === 'header_second_template') {
      this.landingPageData.header.section_image =
        'assets/images/landing_page/landing-page-2-header.jpg';
      this.landingPageData.header.welcome.style.background_url =
        'assets/images/landing_page/landing-page-2-header.jpg';
      this.landingPageData.header.welcome.style.text_color = '#3C3C3C';
    } else if (sectionName === 'header_third_template') {
      if (this.landingPageData.header.welcome.text.title === '') {
        this.landingPageData.header.welcome.text.title =
          'Lorem ipsum dolor sit amet consectetur';
      }
      if (this.landingPageData.header.welcome.text.sub_title === '') {
        this.landingPageData.header.welcome.text.sub_title =
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod';
      }

      this.landingPageData.header.section_image =
        'assets/images/landing_page/landing-page-3-header.jpg';
      this.landingPageData.header.welcome.style.background_url =
        'assets/images/landing_page/landing-page-3-header.jpg';
      this.landingPageData.header.welcome.style.text_color = 'white';
    } else if (sectionName === 'header_first_template') {
      if (this.landingPageData.header.welcome.text.title === '') {
        this.landingPageData.header.welcome.text.title =
          'Lorem ipsum dolor sit amet consectetur';
      }
      if (this.landingPageData.header.welcome.text.sub_title === '') {
        this.landingPageData.header.welcome.text.sub_title =
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod';
      }
      this.landingPageData.header.section_image =
        'assets/images/landing_page/landing-page-1-header.jpg';
      this.landingPageData.header.welcome.style.background_color = '#FFFAE9';
      this.landingPageData.header.welcome.style.text_color = '#3C3C3C';
      this.landingPageData.header.welcome.image.image_url =
        'assets/images/landing_page/landing-page-image-png.png';
    }
  }

  // conflicts that must be resolved (current changes)

  selectAboutSectionDesign(sectionName) {
    this.landingPageData.header.welcome.type = sectionName;
    this.selectedSection.welcome.type = sectionName;
    if (sectionName === 'header_first_template') {
      if (this.landingPageData.header.welcome.text.title === '') {
        this.landingPageData.header.welcome.text.title =
          'Lorem ipsum dolor sit amet consectetur';
      }
      if (this.landingPageData.header.welcome.text.sub_title === '') {
        this.landingPageData.header.welcome.text.sub_title =
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod';
      }
      this.landingPageData.header.section_image =
        'assets/images/landing_page/landing-page-1-header.jpg';
      this.landingPageData.header.welcome.style.background_color = '#FFFAE9';
      this.landingPageData.header.welcome.style.text_color = '#3C3C3C';
      this.landingPageData.header.welcome.image.image_url =
        'assets/images/landing_page/landing-page-image-png.png';
    } else if (sectionName === 'header_second_template') {
      this.landingPageData.header.section_image =
        'assets/images/landing_page/landing-page-2-header.jpg';
      this.landingPageData.header.welcome.style.background_url =
        'assets/images/landing_page/landing-page-2-header.jpg';
      this.landingPageData.header.welcome.style.text_color = '#3C3C3C';
    } else if (sectionName === 'header_third_template') {
      if (this.landingPageData.header.welcome.text.title === '') {
        this.landingPageData.header.welcome.text.title =
          'Lorem ipsum dolor sit amet consectetur';
      }
      if (this.landingPageData.header.welcome.text.sub_title === '') {
        this.landingPageData.header.welcome.text.sub_title =
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod';
      }

      this.landingPageData.header.section_image =
        'assets/images/landing_page/landing-page-3-header.jpg';
      this.landingPageData.header.welcome.style.background_url =
        'assets/images/landing_page/landing-page-3-header.jpg';
      this.landingPageData.header.welcome.style.text_color = 'white';

      // conflicts that must be resolved (other changes)

      // selectAboutSectionDesign(sectionName){
      //   this.landingPageData.about.type = sectionName;
      //   this.selectedSection.type = sectionName;
      //   if(sectionName === 'about_first_template'){
      //     this.landingPageData.about.section_image = 'assets/images/landing_page/landing-page-1-about-me.jpg'
      //     // this.landingPageData.about.style.background_color='#F8F8F8';
      //     // this.landingPageData.about.style.text_color='#3C3C3C';
      //     // this.landingPageData.about.style.accessorizes_color='#F6EFD9';
      //   }
      //   else if(sectionName === 'about_second_template'){
      //     if(this.landingPageData.about.text.sub_title === ''){
      //       this.landingPageData.header.welcome.text.title =
      //         'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut';
      //     }
      //     this.landingPageData.about.section_image = 'assets/images/landing_page/landing-page-2-about-me.jpg'
      //     // this.landingPageData.about.style.background_color= '#F8F8F8';
      //     // this.landingPageData.about.style.text_color='#3C3C3C';

      //   }
      //   else if(sectionName === 'about_third_template'){

      //     this.landingPageData.about.image.image_url = 'assets/images/landing_page/landing-page-3-about-me-image.jpg';
      //     this.landingPageData.about.section_image = 'assets/images/landing_page/landing-page-3-about-me.jpg'
      //     // this.landingPageData.about.style.background_color='#CDD1DE';
      //     // this.landingPageData.about.style.text_color='#3C3C3C';
      //     // this.landingPageData.about.style.accessorizes_color='#FFFFFF';

      //   }

      // }

      // selectBulletSectionDesign(sectionName){
      //   this.landingPageData.info_bullets.type = sectionName;
      //   this.selectedSection.type = sectionName;
      //   if(sectionName === 'info_bullets_first_template'){
      //     this.landingPageData.info_bullets.section_image = 'assets/images/landing_page/landing-page-1-icons.jpg'
      //     // this.landingPageData.info_bullets.style.background_color='#ffffff';
      //     // this.landingPageData.info_bullets.style.text_color='#3C3C3C';
      //     // this.landingPageData.info_bullets.style.accessorizes_color='#F6EFD9';

      //     this.landingPageData.info_bullets.bullets['1'].icon_svg = this.userLandingPageService.getFirstTemplateIcons()['1'];
      //     this.landingPageData.info_bullets.bullets['2'].icon_svg = this.userLandingPageService.getFirstTemplateIcons()['2'];
      //     this.landingPageData.info_bullets.bullets['3'].icon_svg = this.userLandingPageService.getFirstTemplateIcons()['3'];
      //     this.landingPageData.info_bullets.bullets['4'].icon_svg = this.userLandingPageService.getFirstTemplateIcons()['4'];
      //   }
      //   else if(sectionName === 'info_bullets_second_template'){
      //     this.landingPageData.info_bullets.section_image = 'assets/images/landing_page/landing-page-2-icons.jpg'
      //     // this.landingPageData.info_bullets.style.background_color= '#F8F8F8';
      //     // this.landingPageData.info_bullets.style.text_color='#3C3C3C';
      //     // this.landingPageData.info_bullets.style.accessorizes_color='#F7DCE0';

      //     this.landingPageData.info_bullets.bullets['1'].icon_svg = '1';
      //     this.landingPageData.info_bullets.bullets['2'].icon_svg = '2';
      //     this.landingPageData.info_bullets.bullets['3'].icon_svg = '3';
      //     this.landingPageData.info_bullets.bullets['4'].icon_svg = '4';

      //   }
      //   else if(sectionName === 'info_bullets_third_template'){

      //     this.landingPageData.info_bullets.section_image = 'assets/images/landing_page/landing-page-3-icons.jpg'
      //     // this.landingPageData.info_bullets.style.background_color='#0C1D39';
      //     // this.landingPageData.info_bullets.style.text_color='#FFFFFF';
      //     // this.landingPageData.info_bullets.style.accessorizes_color='#CDD1DE';

      //     this.landingPageData.info_bullets.bullets['1'].icon_svg = this.userLandingPageService.getThirdTemplateIcons()['1'];
      //     this.landingPageData.info_bullets.bullets['2'].icon_svg = this.userLandingPageService.getThirdTemplateIcons()['2'];
      //     this.landingPageData.info_bullets.bullets['3'].icon_svg = this.userLandingPageService.getThirdTemplateIcons()['3'];
      //     this.landingPageData.info_bullets.bullets['4'].icon_svg = this.userLandingPageService.getThirdTemplateIcons()['4'];

      // conflicts that must be resolved (other changes)
    }
  }

  selectCoursesSectionDesign(sectionName) {
    this.landingPageData.courses.courses_cards.type = sectionName;
    this.selectedSection.courses_cards.type = sectionName;
    if (sectionName === 'courses_lengthwise') {
      this.landingPageData.courses.section_image =
        'assets/images/landing_page/landing-page-2-courses.jpg';

      this.landingPageData.info_bullets.style.background_color = '#fbfbfb';
      this.landingPageData.info_bullets.style.text_color = '#3C3C3C';
      this.landingPageData.info_bullets.style.accessorizes_color = '#F6EFD9';
    } else if (sectionName === 'courses_widthwise') {
      this.landingPageData.courses.section_image =
        'assets/images/landing_page/landing-page-1-courses.jpg';

      this.landingPageData.info_bullets.style.background_color = '#F8F8F8';
      this.landingPageData.info_bullets.style.text_color = '#3C3C3C';
      this.landingPageData.info_bullets.style.accessorizes_color = '#F6EFD9';
    }
  }
}
