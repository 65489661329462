import { MenuItem } from '../inteface/menu-item.enum';
import { IMenuItem } from '../inteface/side-menu';

export const HIVE_MENU_CONFIG: IMenuItem[] = [
  {
    itemName: MenuItem.DASHBOARD,
    itemIcon: 'dashboard',
    itemPath: 'dashboard',
  },
  {
    itemName: MenuItem.COURSES,
    itemIcon: 'desktop_windows',
    itemPath: 'courses',
  },
  {
    itemName: MenuItem.ANALYTICS,
    itemIcon: 'bar_chart',
    itemPath: 'analytics',
  },
];

export const COURSE_MENU_CONFIG: IMenuItem[] = [
  {
    itemName: MenuItem.STRUCTURE,
    itemIcon: 'widgets_outline',
    itemPath: 'structure',
  },
  {
    itemName: MenuItem.DETAILS,
    itemIcon: 'wysiwyg',
    itemPath: 'details',
  },
  {
    itemName: MenuItem.RESOURCES,
    itemIcon: 'attach_file',
    itemPath: 'resources',
  },
  {
    itemName: MenuItem.FINANCE,
    itemIcon: 'attach_money',
    itemPath: 'finance',
  },
  {
    itemName: MenuItem.FORUM,
    itemIcon: 'chat_bubble_outline',
    itemPath: 'forum',
  },
  {
    itemName: MenuItem.LANDING_PAGE,
    itemIcon: 'web',
    itemPath: 'landing_page',
  },
  {
    itemName: MenuItem.SETTINGS,
    itemIcon: 'settings_outline',
    itemPath: 'settings',
  },
];
