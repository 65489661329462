import { CourseLanguages, CourseLevels } from './config/course.congif';
import { IrequiredSkill } from './../../shared/interfaces-enums/course-required-skill';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ICourse } from '../../course/interfaces-enums/course';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-course',
  templateUrl: './create-course.component.html',
  styleUrls: ['./create-course.component.scss'],
})
export class CreateCourseComponent implements OnInit {
  requiredSkillsList: IrequiredSkill[] = [];
  isValid = true;
  formIsValidAfterSubmitted = false;
  newCourse: ICourse;

  courseLevels = CourseLevels;
  courseLanguage = CourseLanguages;

  createCourseForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<CreateCourseComponent>) {}

  ngOnInit(): void {
    // will need to add language + skills + course level
    this.createCourseForm = new FormGroup({
      title: new FormControl(''),
      description: new FormControl(''),
      image: new FormControl(''),
    });
  }

  get skillName(): any {
    return this.createCourseForm.get('skills');
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onAddCourseSkill(skillType, skillLevel) {
    // will need need to refactor
    if (skillType) {
      this.requiredSkillsList.push({ skill: skillType, level: skillLevel });
      this.skillName?.reset();
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  onCourseSkillRemove(item: IrequiredSkill) {
    // will need need to refactor
    for (let skill of this.requiredSkillsList) {
      if (item.level === skill.level && item.skill === skill.skill) {
        let index = this.requiredSkillsList.indexOf(skill);
        this.requiredSkillsList.splice(index, 1);
      }
    }
  }
}
