<app-gallery-popup-template [title]="'Add Video'">
  <div class="video-gallery-content-wrapper">
    <div class="video-gallery-content">
      <div class="url-input">
        <label class="input-label" for="video-url">URL:</label>
        <input class="input-value" type="text" id="video-url" name="URL of Video" #url [(ngModel)]="videoUrl" (ngModelChange)="updateCurrentResult()">
        <span class="material-icons-outlined edit-icon">edit</span>
      </div>
    </div>
    <div class="video-gallery-preview" *ngIf="url.value">
      <h4>Video Preview</h4>
      <div class="video-gallery-player-wrapper">
        <div class="video-gallery-player" >
          <vg-player *ngIf="findFormat(url.value)" >
            <vg-controls vgFor="my-video" [vgAutohide]="true">
              <vg-play-pause vgFor="my-video"></vg-play-pause>
              <vg-scrub-bar vgFor="my-video">
                <vg-scrub-bar-current-time vgFor="my-video" [vgSlider]="true"></vg-scrub-bar-current-time>
                <vg-scrub-bar-buffering-time vgFor="my-video"></vg-scrub-bar-buffering-time>

              </vg-scrub-bar>
            </vg-controls>

            <video [vgMedia]="media" id="my-video" #media preload="auto" [style.width]="400" [style.height]="250" >
              <source src="{{url.value}}" type="{{findFormat(url.value)}}">
            </video>
          </vg-player>

          <youtube-player *ngIf="findYoutube(url.value)"
                          videoId = "{{findYoutube(url.value)['url']}}"
                          [height]="250"
                          [width]="400"
                          [playerVars]="{fs:0,modestbranding:1}"
                          class="yt-player"
          ></youtube-player>
          <p *ngIf="!findFormat(url.value) && !findYoutube(url.value)" class="p-error"> NO VIDEO FOUND!</p>
        </div>
      </div>
    </div>
  </div>
</app-gallery-popup-template>
