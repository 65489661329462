import { Component, OnInit } from '@angular/core';
import { UserLandingPageModel } from '../user-landing-page.model';
import { UserLandingPageService } from '../user-landing-page.service';

@Component({
  selector: 'app-create-user-landing-page',
  templateUrl: './create-user-landing-page.component.html',
  styleUrls: ['./create-user-landing-page.component.scss'],
})
export class CreateUserLandingPageComponent implements OnInit {
  landingPageData: UserLandingPageModel;
  firstTemplate: boolean;
  secondTemplate: boolean;
  thirdTemplate: boolean;

  constructor(private userLandingPageService: UserLandingPageService) {}

  ngOnInit(): void {
    // this.sidebarService.setCurrentComponentPath('bridge');
  }

  selectTemplate(template) {
    if (template === 'firstTemplate') {
      this.userLandingPageService.setSelectedLandingPage(
        this.userLandingPageService.getFirstTemplate()
      );
      console.log('first template selected');
    } else if (template === 'secondTemplate') {
      this.userLandingPageService.setSelectedLandingPage(
        this.userLandingPageService.getSecondTemplate()
      );
      console.log('second template selected');
    } else if (template === 'thirdTemplate') {
      this.userLandingPageService.setSelectedLandingPage(
        this.userLandingPageService.getThirdTemplate()
      );
      console.log('third template selected');
    }
  }

  showBasicDialog(template) {
    if (template === 'firstTemplate') {
      this.firstTemplate = true;
    } else if (template === 'secondTemplate') {
      this.secondTemplate = true;
    } else if (template === 'thirdTemplate') {
      this.thirdTemplate = true;
    }
  }
}
