import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HivePopupService } from './shared/hive-popup/hive-popup.service';
import { MessageHandleService } from './core/firebase-handle/message-handle.service';
import { MessageService } from 'primeng/api';
import { LoadingScreenService } from './layout/components/loading-screen/loading-screen.service';
import { ValidatorService } from './shared/validator/validator.service';
import { ErrorsHandlerService } from './core/services/errors-handler.service';
import { MessageTypes } from './shared/interfaces-enums/message-types.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  isValidating: boolean;
  isLoadingSub: Subscription;
  isValidatingSub: Subscription;
  isSidebar: boolean = true;
  isPopup: boolean;
  isPopupSub: Subscription;
  notificationSub: Subscription;
  modalPopupSub: Subscription;
  showMessageSub: Subscription;

  displayModal: boolean;
  modalHeaderText: string;
  modalBodyText: string;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private loadingService: LoadingScreenService,
    private validator: ValidatorService,
    private popupService: HivePopupService,
    private firebase: MessageHandleService,
    private messageService: MessageService,
    private errorsHandlerService: ErrorsHandlerService
  ) {}

  ngOnInit(): void {
    document.documentElement.setAttribute('lang', this.locale);

    this.isPopupSub = this.popupService.isActiveSub.subscribe((result) => {
      this.isPopup = result;
    });
    this.isLoadingSub = this.loadingService.isActiveSub.subscribe((data) => {
      this.isLoading = data;
    });
    this.isValidatingSub = this.validator.isActiveSub.subscribe((data) => {
      this.isValidating = data;
    });

    this.notificationSub = this.firebase.notification.subscribe((data) => {
      if (data.sub_type === 'alert') {
        this.showNotification(data['head'], data['body'], 'error', true);
      } else if (data.sub_type === 'system') {
        this.showNotification(data['head'], data['body'], 'warn', false, 5000);
      } else {
        this.showNotification(data['head'], data['body']);
      }
    });
    this.showMessageSub = this.errorsHandlerService?.message.subscribe(
      (data) => {
        this.showMessage(data.severity, data.summary, data.detail);
      }
    );
  }

  ngOnDestroy(): void {
    this.isPopupSub?.unsubscribe();
    this.isLoadingSub?.unsubscribe();
    this.isValidatingSub?.unsubscribe();
    this.notificationSub?.unsubscribe();
    this.showMessageSub?.unsubscribe();
  }

  showNotification(
    title,
    body,
    severity = 'info',
    sticky: boolean = false,
    life: number = 2000
  ) {
    this.messageService.add({
      severity: severity,
      summary: title,
      detail: body,
      life: life,
      sticky: sticky,
    });

    this.firebase.updateNotificationViewed();
  }

  showMessage(
    severity: MessageTypes,
    summary: string,
    detail: string,
    sticky: boolean = false,
    life: number = 3000
  ) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail,
      life: life,
      sticky: sticky,
    });
  }

  onConfirm() {
    this.messageService.clear('c');
  }

  onReject() {
    this.messageService.clear('c');
  }

  clear() {
    this.messageService.clear();
  }
}
