import { Injectable } from '@angular/core';
import { UserLandingPageModel } from './user-landing-page.model';
import { UserService } from '../../user.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserLandingPageService {
  selectedLandingPage: BehaviorSubject<UserLandingPageModel> =
    new BehaviorSubject<UserLandingPageModel>(null);
  selectedSection: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  firstTemplate: UserLandingPageModel;
  secondTemplate: UserLandingPageModel;
  thirdTemplate: UserLandingPageModel;

  setSelectedLandingPage(landingPageTemplate) {
    this.selectedLandingPage.next(landingPageTemplate);
  }
  setSelectedSection(section) {
    this.selectedSection.next(section);
  }

  getSelectedLandingPage() {
    return this.selectedLandingPage;
  }

  getFirstTemplateIcons() {
    return {
      '1': 'assets/images/icons/working.png',
      '2': 'assets/images/icons/handshake.png',
      '3': 'assets/images/icons/project.png',
      '4': 'assets/images/icons/talking.png',
    };
  }

  getFirstTemplate() {
    this.firstTemplate = {
      header: {
        section_image: 'assets/images/landing_page/landing-page-1-header.jpg',
        section_name: 'header',
        logo: {
          is_visible: true,
          is_text: true,
          style: {
            text_color: '#FFAA00',
            background_color: '#3C3C3C',
            alignment: 'center',
          },
          text: 'Your Logo',
        },
        welcome: {
          section_name: 'welcome',
          type: 'header_first_template',
          text: {
            title: 'Lorem ipsum dolor sit amet consectetur',
            sub_title:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod',
          },
          image: {
            image_url: 'assets/images/landing_page/landing-page-image-png.png',
          },
          style: {
            selected_background_type: 'background_color',
            text_color: '#3C3C3C',
            background_color: '#FFFAE9',
            opacity: '1',
            direction: 'ltr',
          },
        },
      },
      about: {
        section_image: 'assets/images/landing_page/landing-page-1-about-me.jpg',
        section_name: 'about',
        type: 'about_first_template',
        text: {
          title: 'ABOUT ME',
          paragraph:
            'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        image: {
          image_url: '',
        },
        style: {
          selected_background_type: 'background_color',
          text_color: '#3C3C3C',
          background_color: '#F8F8F8',
          opacity: '1',
          direction: 'ltr',
          accessorizes_color: '#F6EFD9',
        },
      },
      info_bullets: {
        section_image: 'assets/images/landing_page/landing-page-1-icons.jpg',
        section_name: 'info bullets',
        type: 'info_bullets_first_template',
        title: 'WHAT WILL YOU GET FROM MY COURSES',
        bullets: {
          1: {
            icon_svg: 'assets/images/icons/working.png',
            title: 'Lorem ipsum',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          },
          2: {
            icon_svg: 'assets/images/icons/handshake.png',
            title: 'Lorem ipsum',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          },
          3: {
            icon_svg: 'assets/images/icons/project.png',
            title: 'Lorem ipsum',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          },
          4: {
            icon_svg: 'assets/images/icons/talking.png',
            title: 'Lorem ipsum',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          },
        },
        style: {
          selected_background_type: 'background_color',
          text_color: '#3C3C3C',
          background_color: '#ffffff',
          opacity: '1',
          direction: 'ltr',
          accessorizes_color: '#F6EFD9',
        },
      },
      courses: {
        section_image: 'assets/images/landing_page/landing-page-1-courses.jpg',
        section_name: 'courses',
        courses_cards: {
          type: 'courses_lengthwise',
          title: 'MY COURSES',
          course_card: {},
          style: {
            selected_background_type: 'background_color',
            text_color: '#3C3C3C',
            background_color: '#F8F8F8',
            opacity: '1',
            direction: 'ltr',
            accessorizes_color: '#F6EFD9',
          },
        },
        footer: {
          is_visible: true,
          text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing\n' +
            '© user name 2021',
          style: {
            text_color: '#ffffff',
            background_color: '#3C3C3C',
            alignment: 'center',
          },
        },
      },
    };

    for (let course of this.userService.getUserDetails().user_courses) {
      this.firstTemplate.courses.courses_cards.course_card[course.id] = {
        title: course.title,
        sub_title:
          'Created on ' +
          course.created.toString() +
          ' | last saved: ' +
          course.last_updated.toString(),
        paragraph:
          'max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT',
        image_url: course.image,
      };
    }

    return this.firstTemplate;
  }

  getSecondTemplate() {
    this.secondTemplate = {
      header: {
        section_image: 'assets/images/landing_page/landing-page-2-header.jpg',
        section_name: 'header',
        logo: {
          is_visible: true,
          is_text: true,
          style: {
            text_color: '#F799A7',
            background_color: '#3C3C3C',
            alignment: 'center',
          },
          text: 'Your Logo',
        },
        welcome: {
          section_name: 'welcome',
          type: 'header_second_template',
          image: {
            image_url: '',
          },
          text: {
            title: '',
            sub_title: '',
          },
          style: {
            selected_background_type: 'background_url',
            text_color: '#3C3C3C',
            background_url:
              'assets/images/landing_page/landing-page-2-header.jpg',
            opacity: '1',
            direction: 'ltr',
          },
        },
      },
      about: {
        section_image: 'assets/images/landing_page/landing-page-2-about-me.jpg',
        section_name: 'about',
        type: 'about_second_template',
        text: {
          title: 'Lorem ipsum dolor sit amet',
          sub_title:
            'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut',
          paragraph:
            'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        image: {
          image_url: '',
        },
        style: {
          selected_background_type: 'background_color',
          text_color: '#3C3C3C',
          background_color: '#F8F8F8',
          opacity: '1',
          direction: 'ltr',
        },
      },
      info_bullets: {
        section_image: 'assets/images/landing_page/landing-page-2-icons.jpg',
        section_name: 'info bullets',
        type: 'info_bullets_second_template',
        title: 'WHAT WILL YOU GET FROM MY COURSES',
        bullets: {
          1: {
            icon_svg: '1',
            title: 'Experience',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          },
          2: {
            icon_svg: '2',
            title: 'Final project',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          },
          3: {
            icon_svg: '3',
            title: 'From a to z',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          },
          4: {
            icon_svg: '4',
            title: 'Your not alone',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          },
        },
        style: {
          selected_background_type: 'background_color',
          text_color: '#3C3C3C',
          background_color: '#F8F8F8',
          opacity: '1',
          direction: 'ltr',
          accessorizes_color: '#F7DCE0',
        },
      },
      courses: {
        section_image: 'assets/images/landing_page/landing-page-2-courses.jpg',
        section_name: 'courses',
        courses_cards: {
          type: 'courses_widthwise',
          title: 'MY COURSES',
          course_card: {},
          style: {
            selected_background_type: 'background_color',
            text_color: '#3C3C3C',
            background_color: '#fbfbfb',
            opacity: '1',
            direction: 'ltr',
            accessorizes_color: '#F6EFD9',
          },
        },
        footer: {
          is_visible: true,
          text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing\n' +
            '© user name 2021',
          style: {
            text_color: '#ffffff',
            background_color: '#3C3C3C',
            alignment: 'center',
          },
        },
      },
    };

    for (let course of this.userService.getUserDetails().user_courses) {
      this.secondTemplate.courses.courses_cards.course_card[course.id] = {
        title: course.title,
        sub_title:
          'Created on ' +
          course.created.toString() +
          ' | last saved: ' +
          course.last_updated.toString(),
        paragraph:
          'max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT',
        image_url: course.image,
      };
    }

    return this.secondTemplate;
  }

  getThirdTemplate() {
    this.thirdTemplate = {
      header: {
        section_image: 'assets/images/landing_page/landing-page-3-header.jpg',
        section_name: 'header',
        logo: {
          is_visible: true,
          is_text: true,
          style: {
            text_color: '#3C3C3C',
            background_color: '#FFFFFF',
            alignment: 'center',
          },
          text: 'Your Logo',
        },
        welcome: {
          section_name: 'welcome',
          type: 'header_third_template',
          text: {
            title: 'Lorem ipsum dolor sit amet consectetur',
            sub_title:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod',
          },
          image: {
            image_url: '',
          },
          style: {
            selected_background_type: 'background_url',
            text_color: '#FFFFFF',
            background_url:
              'assets/images/landing_page/landing-page-3-header.jpg',
            opacity: '1',
            direction: 'ltr',
          },
        },
      },
      about: {
        section_image: 'assets/images/landing_page/landing-page-3-about-me.jpg',
        section_name: 'about',
        type: 'about_third_template',
        text: {
          title: 'ABOUT ME',
          paragraph:
            'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        image: {
          image_url:
            'assets/images/landing_page/landing-page-3-about-me-image.jpg',
        },
        style: {
          selected_background_type: 'background_color',
          text_color: '#3C3C3C',
          background_color: '#CDD1DE',
          opacity: '1',
          direction: 'ltr',
          accessorizes_color: '#FFFFFF',
        },
      },
      info_bullets: {
        section_image: 'assets/images/landing_page/landing-page-3-icons.jpg',
        section_name: 'info bullets',
        type: 'info_bullets_third_template',
        title: 'WHAT WILL YOU GET FROM MY COURSES',
        bullets: {
          1: {
            icon_svg: 'assets/images/icons/customer-support.png',
            title: 'Lorem ipsum',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          },
          2: {
            icon_svg: 'assets/images/icons/promotion.png',
            title: 'Lorem ipsum',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          },
          3: {
            icon_svg: 'assets/images/icons/success.png',
            title: 'Lorem ipsum',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          },
          4: {
            icon_svg: 'assets/images/icons/online-course-1.png',
            title: 'Lorem ipsum',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          },
        },
        style: {
          selected_background_type: 'background_color',
          text_color: '#FFFFFF',
          background_color: '#0C1D39',
          opacity: '1',
          direction: 'ltr',
          accessorizes_color: '#CDD1DE',
        },
      },
      courses: {
        section_image: 'assets/images/landing_page/landing-page-1-courses.jpg',
        section_name: 'courses',
        courses_cards: {
          type: 'courses_lengthwise',
          title: 'MY COURSES',
          course_card: {},
          style: {
            selected_background_type: 'background_color',
            text_color: '#3C3C3C',
            background_color: '#CDD1DE',
            opacity: '1',
            direction: 'ltr',
            accessorizes_color: 'none',
          },
        },
        footer: {
          is_visible: true,
          text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing\n' +
            '© user name 2021',
          style: {
            text_color: '#ffffff',
            background_color: '#3C3C3C',
            alignment: 'center',
          },
        },
      },
    };

    for (let course of this.userService.getUserDetails().user_courses) {
      this.thirdTemplate.courses.courses_cards.course_card[course.id] = {
        title: course.title,
        sub_title:
          'Created on ' +
          course.created.toString() +
          ' | last saved: ' +
          course.last_updated.toString(),
        paragraph:
          'max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT max needs to add description to user model untill then it will say CUNT',
        image_url: course.image,
      };
    }

    return this.thirdTemplate;
  }

  getThirdTemplateIcons() {
    return {
      '1': 'assets/images/icons/customer-support.png',
      '2': 'assets/images/icons/promotion.png',
      '3': 'assets/images/icons/success.png',
      '4': 'assets/images/icons/online-course-1.png',
    };
  }

  getWelcomeText_with_image() {}

  constructor(private userService: UserService) {}
}
