import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class LoadingScreenService {

  private isActive: boolean = false;
  isActiveSub: Subject<boolean> = new Subject<boolean>();


  setIsActive(to?: boolean){
    if(to != null){
      this.isActive = to;
      this.isActiveSub.next(this.isActive);
    }
    else{
      this.isActive = !this.isActive;
      this.isActiveSub.next(this.isActive)
    }
  }

  getIsActive(): boolean{
    let temp = this.isActive;
    return temp
  }
}
