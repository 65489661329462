import { ToastModule } from 'primeng/toast';
import { AuthModule } from './auth/auth.module';
import { MatDialogModule } from '@angular/material/dialog';
import { LayoutModule } from './layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CodeGalleryPanelComponent } from './course/course-editor/element-content-types/code-element/code-element-gallery/code-gallery-panel/code-gallery-panel.component';
import { UnitestPopupComponent } from './course/course-editor/element-content-types/code-element/code-element-editor/unitest-popup/unitest-popup.component';
import { GalleryPopupTemplateComponent } from './shared/hive-popup/gallery-popup-template/gallery-popup-template.component';
import { CodeElementGalleryComponent } from './course/course-editor/element-content-types/code-element/code-element-gallery/code-element-gallery.component';
import { ImageGalleryComponent } from './course/course-editor/element-content-types/image-element/image-gallery/image-gallery.component';
import { VideoGalleryComponent } from './course/course-editor/element-content-types/video-element/video-gallery/video-gallery.component';
import { HivePopupComponent } from './shared/hive-popup/hive-popup.component';
import { SafeHtmlPipe } from './shared/safe-html.pipe';
import { SafeUrlPipe } from './shared/pipes/safe-url.pipe';
import { UserEditorLandingPageSidebarComponent } from './hive/user/user-settings/user-landing-page/user-landing-page-editor/user-editor-landing-page-sidebar/user-editor-landing-page-sidebar.component';
import { UserLandingPageEditorComponent } from './hive/user/user-settings/user-landing-page/user-landing-page-editor/user-landing-page-editor.component';
import { CreateUserLandingPageComponent } from './hive/user/user-settings/user-landing-page/create-user-landing-page/create-user-landing-page.component';
import { UserLandingPageComponent } from './hive/user/user-settings/user-landing-page/user-landing-page.component';
import { InboxMessageComponent } from './hive/user/user-inbox/inbox-message/inbox-message.component';
import { UserInboxComponent } from './hive/user/user-inbox/user-inbox.component';
import { ValidatorComponent } from './shared/validator/validator.component';
import { CoreModule } from './core/core.module';
import { CourseModule } from './course/course.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { AppRoutesModule } from './routes/app-routes.module';
import { UserSettingsComponent } from './hive/user/user-settings/user-settings.component';
import { TermsComponent } from './shared/terms/terms.component';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TabViewModule } from 'primeng/tabview';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ColorPickerModule } from 'ngx-color-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SidebarHiveModule } from './shared/sidebar-hive/sidebar-hive.module';
import { ButtonModule } from 'primeng/button';
import { TreeModule } from 'primeng/tree';
import { DialogModule } from 'primeng/dialog';
import { NgxEditorModule } from 'ngx-editor';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { MenuModule } from 'primeng/menu';
import { SelectButtonModule } from 'primeng/selectbutton';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {firebaseConfig} from '../environments/environment';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {getAuth, provideAuth} from '@angular/fire/auth';

@NgModule({
  declarations: [
    AppComponent,
    TermsComponent,
    UserSettingsComponent,
    ValidatorComponent,
    UserInboxComponent,
    InboxMessageComponent,
    UserLandingPageComponent,
    CreateUserLandingPageComponent,
    UserLandingPageEditorComponent,
    UserEditorLandingPageSidebarComponent,
    SafeUrlPipe,
    SafeHtmlPipe,
    HivePopupComponent,
    VideoGalleryComponent,
    ImageGalleryComponent,
    CodeElementGalleryComponent,
    GalleryPopupTemplateComponent,
    UnitestPopupComponent,
    CodeGalleryPanelComponent,
  ],
  imports: [
    CoreModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    HttpClientModule,
    MaterialModule,
    AppRoutesModule,
    ReactiveFormsModule,
    ContextMenuModule,
    TabViewModule,
    InputTextareaModule,
    ColorPickerModule,
    DragDropModule,
    SidebarHiveModule,
    ButtonModule,
    TreeModule,
    DialogModule,
    NgxEditorModule,
    VgCoreModule,
    SelectButtonModule,
    VgControlsModule,
    VgBufferingModule,
    YouTubePlayerModule,
    MonacoEditorModule.forRoot(),
    MenuModule,
    InputTextareaModule,
    MatDialogModule,
    CourseModule,
    LayoutModule,
    AuthModule,
    ToastModule,
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1054170784184-ho0oaldioiqdro4o1d822mep8di8r2br.apps.googleusercontent.com'
            ),
          },
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
