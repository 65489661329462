import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CourseService } from 'src/app/course/course.service';

@Injectable({ providedIn: 'root' })
export class HiveIdGuard {
  constructor(private courseService: CourseService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let courseId = route.params['course_id'];
    let idFromService = this.courseService.getCourseID();

    return new Promise<boolean>((resolve) => {
      if (courseId) {
        if (idFromService && idFromService === courseId) {
          resolve(true);
        } else if (idFromService !== courseId) {
          this.courseService.updateCurrentCourseById(courseId).subscribe({
            error: (error) => {
              resolve(false);
            },
            complete: () => {
              console.log('course-id updated to ' + courseId);
              resolve(true);
            },
          });
        } else {
          resolve(false);
        }
      }
    });
  }
}
