import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { ElementFrameService } from '../../element-frame/element-frame.service';
import { HivePopupService } from 'src/app/shared/hive-popup/hive-popup.service';
import { IElement } from '../interfaces-enums/IElement';

@Injectable({ providedIn: 'root' })
export class ImageElementService {
  imageElementSize: BehaviorSubject<{
    height: number;
    width: number;
    id: string;
  }> = new BehaviorSubject<{ height: number; width: number; id: string }>(null);
  private editedImageID: string = null;

  constructor(
    private popupService: HivePopupService,
    private frameService: ElementFrameService
  ) {}

  setImageElementSize(height: number, width: number, id: string) {
    this.imageElementSize.next({ height: height, width: width, id: id });
  }

  getImageElementSize() {
    return { ...this.imageElementSize };
  }

  getContainedSize(heightImg, widthImg) {
    let ratio = widthImg / heightImg;
    let width = heightImg * ratio;
    let height = heightImg;
    if (width > widthImg) {
      width = widthImg;
      height = widthImg / ratio;
    }
    return [width, height];
  }

  getContextMenu(element) {
    let contextMenu: MenuItem[] = [
      { separator: true },
      {
        label: 'Change Image',
        command: () => {
          this.popupService
            .startPopupResult(this.popupService.popupTypes.imageGallery)
            .then((result) => {
              element['data']['url'] = result;
            });
        },
      },

      {
        label: 'Fit Frame To Image',
        command: () => {
          if (this.imageElementSize.value !== null) {
          }
        },
      },
    ];

    return contextMenu;
  }

  setImageID(id: string) {
    this.editedImageID = id;
  }
  getImageID(): string {
    return this.editedImageID;
  }

  getHeaderMenu(element: IElement) {
    return [];
  }
}
