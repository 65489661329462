<div *ngIf="isTeacher">
  <div
    class="element-header {{ element_type }}"
    [ngStyle]="
      element_type === 'TOPIC'
        ? { backgroundColor: element.color + '09', paddingLeft: '40px' }
        : {}
    "
    [ngClass]="{
      isClosed: !element.metaData.open && element_type === 'LESSON'
    }"
  >
    <div class="element-title-edit" *ngIf="nameEdit">
      <label class="element-title-name" for="element-name-edit">
        <strong *ngIf="element_type === 'TOPIC'">{{ element.index }}. </strong>
        <strong *ngIf="element_type === 'SECTION'"
          >{{ romanize(element.index) }}.
        </strong>
        <strong> {{ getElementTypeForTranslation() }}:</strong></label
      >
      <input
        (keyup.enter)="onNameSave(elementName.value)"
        (click)="$event.stopPropagation()"
        [(ngModel)]="titleValue"
        name="titleValue"
        class="element-title-name-input"
        #elementName
        type="text"
        id="element-name-edit"
        (keydown)="checkIfValid(titleValue)"
        placeholder="{{ getElementName() }}"
      />
      <mat-icon (click)="onNameSave(elementName.value)">check</mat-icon>
      <mat-icon (click)="onNameEdit()">close</mat-icon>
    </div>
    <div
      (click)="$event.stopPropagation(); canEdit ? onNameEdit() : ''"
      class="element-title"
      *ngIf="!nameEdit"
    >
      <p class="element-title-name-edit-mode">
        <strong *ngIf="element_type === 'TOPIC'">{{ element.index }}. </strong>
        <strong *ngIf="element_type === 'SECTION'"
          >{{ romanize(element.index) }}.
        </strong>
        <strong> {{ getElementTypeForTranslation() }}: </strong>
      </p>
      <p class="input-line">{{ getElementName() }}</p>
    </div>
    <p *ngIf="!isNameValid && nameEdit" class="inValidName">
      {{ getErrorMessage() }}
    </p>

    <div class="element-icons">
      <button mat-icon-button *ngIf="!nameEdit">
        <mat-icon
          (click)="$event.stopPropagation(); canEdit ? onNameEdit() : ''"
          >edit</mat-icon
        >
      </button>
      <button mat-icon-button>
        <mat-icon (click)="onDelete()">delete_outline</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon class="material-icons-outlined">settings</mat-icon>
      </button>
      <button mat-icon-button *ngIf="element_type === 'TOPIC'">
        <mat-icon
          [cpAlphaChannel]="'disabled'"
          (click)="$event.stopPropagation()"
          [(colorPicker)]="color"
          [ngStyle]="{ color: color }"
          (colorPickerChange)="updateColor(color)"
          (colorPickerClose)="updateCurrentCourse()"
          >color_lens</mat-icon
        >
      </button>
      <mat-menu class="setting-menu" #menu="matMenu" xPosition="after">
        <a
          class="setting-item"
          *ngIf="element_type === 'TOPIC'"
          (click)="addNewLesson()"
        >
          <mat-icon class="material-icons-outlined"
            >vertical_align_center</mat-icon
          >
          Add Lesson
        </a>
        <a
          class="setting-item"
          (click)="indexDown()"
          [disabled]="element.index === 1"
        >
          <mat-icon class="material-icons-outlined"
            >vertical_align_top</mat-icon
          >
          Move Up
        </a>
        <a class="setting-item" (click)="indexUp()" [disabled]="isLast">
          <mat-icon class="material-icons-outlined"
            >vertical_align_bottom</mat-icon
          >
          Move Down
        </a>
      </mat-menu>
    </div>
    <div
      (click)="onGoToEditor()"
      *ngIf="element_type === 'SECTION'"
      class="forwardBtn"
    >
      <a
        i18n="
          course panel editor btn|course panel editor editor
          button@@coursePanelEditorBTN"
        >Editor</a
      >
      <span *ngIf="isHebrew" class="material-icons-outlined forwardArrow"
        >arrow_back</span
      >
      <span *ngIf="!isHebrew" class="material-icons-outlined forwardArrow"
        >arrow_forward</span
      >
    </div>
    <div
      *ngIf="element_type !== 'SECTION'"
      [ngStyle]="
        element_type !== 'SECTION' ? { display: 'flex' } : { display: 'none' }
      "
    >
      <div class="addNewTopicWrapper">
        <button
          [disabled]="!canEdit"
          class="addNewTopicBtn"
          [ngClass]="element_type === 'TOPIC' ? 'topicButton' : 'lessonButton'"
          (click)="addItemToBottom()"
        >
          <span class="material-icons-outlined addNewItem">add</span>
          {{ getElementTypeForTranslation() }}
        </button>
      </div>
      <div
        class="toggle-icon"
        (click)="canEdit ? onToggle() : ''; checkNameForAll()"
      >
        <mat-icon *ngIf="!element.metaData.open">expand_more</mat-icon>
        <mat-icon *ngIf="element.metaData.open">expand_less</mat-icon>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isTeacher">
  <div
    (click)="element_type !== 'SECTION' ? onToggle() : ''"
    class="element-header {{ element_type }} user-panel-header"
    [ngStyle]="
      element_type === 'TOPIC'
        ? {
            backgroundColor: element.color + '09',
            paddingLeft: '40px',
            cursor: 'pointer'
          }
        : {} || element_type !== 'SECTION'
        ? { cursor: 'pointer' }
        : {}
    "
    [ngClass]="{
      isClosed: !element.metaData.open && element_type === 'LESSON',
      noPadding: element_type === 'LESSON'
    }"
  >
    <div
      class="element-title element-title-user-panel"
      [ngClass]="element_type === 'SECTION' ? 'element-name-title-section' : ''"
    >
      <p class="element-title-name-edit-mode element-title-name-user-panel">
        <strong *ngIf="element_type === 'SECTION'"
          >{{ romanize(element.index) }}.
        </strong>
        <span *ngIf="checkIfDone()" class="material-icons-outlined checkIcon"
          >done</span
        >
      </p>

      <p
        [ngClass]="[element_type === 'TOPIC' ? 'element-name-title-topic' : '']"
        (click)="element_type === 'SECTION' ? onGoToViewer() : ''"
        class="input-line input-line-user-panel element-name-title"
      >
        {{ getElementName() }}
      </p>
    </div>

    <div
      *ngIf="element_type !== 'SECTION'"
      [ngStyle]="
        element_type !== 'SECTION' ? { display: 'flex' } : { display: 'none' }
      "
    >
      <div class="toggle-icon" (click)="onToggleUser()">
        <mat-icon *ngIf="!element.metaData.open">expand_more</mat-icon>
        <mat-icon *ngIf="element.metaData.open">expand_less</mat-icon>
      </div>
    </div>
  </div>
</div>
