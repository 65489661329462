import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HivePopupService } from 'src/app/shared/hive-popup/hive-popup.service';

type unitTests = {
  functionName: string;
  tests: unitTest[];
};

type unitTest = {
  params: { name: string; value: string; type: string }[];
  result: { type: string; value: string };
};

@Component({
  selector: 'app-unitest-popup',
  templateUrl: './unitest-popup.component.html',
  styleUrls: ['./unitest-popup.component.scss'],
})
export class UnitestPopupComponent implements OnInit {
  tests: { code: string; tests: unitTests };
  shouldCompile: boolean = false;

  rightButton: string = 'Next';
  leftButton: string = 'Cancel';
  currentStep: number = 1;

  // Editor
  editorOptions = {
    theme: 'vs-dark',
    language: 'python',
    automaticLayout: true,
    scrollBeyondLastLine: false,
    minimap: { enabled: false },
    folding: false,
    links: false,
    showInlineDetails: true,
    readOnly: true,
  };
  editorPreview;
  testCode: string;

  submitted: boolean = false;

  paramsAmount = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  resultType = ['string', 'int', 'boolean', 'list', 'dictionary'];

  firstStep: UntypedFormGroup = new UntypedFormGroup({
    functionName: new UntypedFormControl('', [Validators.required]),
    paramAmount: new UntypedFormControl(0),
    paramNames: new UntypedFormGroup({}),
  });
  secondStep: UntypedFormGroup = new UntypedFormGroup({
    resultType: new UntypedFormControl('string'),
    tests: new UntypedFormGroup({}),
  });
  thirdStep: UntypedFormGroup = new UntypedFormGroup({
    additionalCode: new UntypedFormControl(''),
  });

  constructor(private popupService: HivePopupService) {}

  ngOnInit(): void {
    if (this.popupService.getPrePopupData()) {
      let preData = this.popupService.prePopupData;
      let funcName = preData.tests.functionName;
      let params = preData.params.params;
      let amount = preData.params.amount;
      let tests = preData.tests.tests;

      this.firstStep.setControl(
        'functionName',
        new UntypedFormControl(funcName, [Validators.required])
      );
      this.firstStep.setControl(
        'paramAmount',
        new UntypedFormControl(amount, [Validators.required])
      );

      let newParamNames: UntypedFormGroup = new UntypedFormGroup({});
      for (let param in params) {
        let paramControl: UntypedFormGroup = new UntypedFormGroup({});
        let name = params[param].name;
        let type = params[param].type;
        paramControl.addControl(
          'name',
          new UntypedFormControl(name, [Validators.required])
        );
        paramControl.addControl(
          'type',
          new UntypedFormControl(type, [Validators.required])
        );
        newParamNames.setControl(param, paramControl);
      }
      this.firstStep.setControl('paramNames', newParamNames);

      let newTests = new UntypedFormGroup({
        resultType: new UntypedFormControl('string'),
        tests: new UntypedFormGroup({}),
      });
      let allTests = new UntypedFormGroup({});
      tests.forEach((value, index) => {
        let group = new UntypedFormGroup({
          params: new UntypedFormGroup({}),
          result: new UntypedFormControl(value.result['value'], [Validators.required]),
        });
        let paramControl: UntypedFormGroup = new UntypedFormGroup({});
        for (let param of value.params) {
          let name = param['name'];
          let type = param['type'];
          let value = param['value'];
          paramControl.addControl(
            name,
            new UntypedFormControl(value, [Validators.required])
          );
        }
        group.setControl('params', paramControl);
        allTests.setControl('test' + (index + 1), group);
      });
      newTests.setControl('tests', allTests);

      if (tests[0].result.type) {
        this.secondStep.setControl(
          'resultType',
          new UntypedFormControl(tests[0].result.type)
        );
      }

      this.secondStep.setControl('tests', allTests);
    }
  }

  closePopUp() {
    this.popupService.finishPopupResult(this.tests);
  }

  counter(i: number) {
    let tmp = [];
    for (let num = 1; num <= i; num++) {
      tmp.push(num);
    }
    return tmp;
  }
  numberToString(num: number) {
    return num.toString();
  }

  goNext() {
    this.submitted = true;
    if (this.customIsValid()) {
      this.submitted = false;
      if (this.currentStep === 1) {
        this.currentStep = 2;
        this.setStep(this.currentStep);
      } else if (this.currentStep === 2) {
        this.currentStep = 3;
        this.setStep(this.currentStep);
        this.shouldCompile = false;
      }
    }
  }
  goPrevious() {
    if (this.customIsValid()) {
      if (this.currentStep === 2) {
        this.currentStep = 1;
        this.setStep(this.currentStep);
      } else if (this.currentStep === 3) {
        this.currentStep = 2;
        this.setStep(this.currentStep);
      }
    }
  }

  setStep(num: number) {
    this.submitted = true;
    if (this.customIsValid()) {
      this.submitted = false;
      this.currentStep = num;
      if (num === 1) {
        this.rightButton = 'Next';
        this.leftButton = 'Cancel';
      } else if (num === 2) {
        this.rightButton = 'Next';
        this.leftButton = 'Previous';
      } else if (num === 3) {
        this.rightButton = 'Done';
        this.leftButton = 'Previous';
        this.popupService.updateCurrentResult({
          code: this.getFunctionString(),
          tests: this.getTests().tests,
          params: this.getParamsData(),
        });
      }
    }
  }

  paramChange() {
    let amount = this.firstStep.value.paramAmount;
    let group: UntypedFormGroup = <UntypedFormGroup>this.firstStep.controls['paramNames'];
    let newGroup: UntypedFormGroup = new UntypedFormGroup({});
    for (let i = 1; i <= amount; i++) {
      newGroup.addControl(
        'param' + i,
        new UntypedFormGroup({
          name: new UntypedFormControl('', Validators.required),
          type: new UntypedFormControl(this.resultType[0]),
        })
      );
    }
    for (let item in group.controls) {
      if (newGroup.controls[item]) {
        newGroup.controls[item].setValue(group.value[item]);
      }
    }
    this.firstStep.setControl('paramNames', newGroup);
  }

  getNewTestGroup() {
    let group = new UntypedFormGroup({
      params: new UntypedFormGroup({}),
      result: new UntypedFormControl('', [Validators.required]),
    });
    let paramControl: UntypedFormGroup = new UntypedFormGroup({});
    for (let param in this.firstStep.get('paramNames').value) {
      let name = this.firstStep.controls['paramNames'].value[param]['name'];
      let type = this.firstStep.controls['paramNames'].value[param]['type'];
      paramControl.addControl(name, new UntypedFormControl('', [Validators.required]));
    }
    group.setControl('params', paramControl);

    return group;
  }

  print(event) {
    console.log(event);
  }

  addNewTests(tests: string) {
    let num = Number(tests);
    let testsGroup: UntypedFormGroup = <UntypedFormGroup>this.secondStep.get('tests');
    let currentTests = Object.keys(this.secondStep.get('tests').value).length;

    for (let test of this.counter(num)) {
      testsGroup.addControl(
        'test' + (test + currentTests),
        this.getNewTestGroup()
      );
    }
    this.secondStep.setControl('tests', testsGroup);
  }

  noDuplicateParams() {
    let params = this.firstStep.get('paramNames').value;
    for (let index in params) {
      for (let index2 in params) {
        if (index !== index2 && params[index] === params[index2]) {
          return false;
        }
      }
    }
    return true;
  }

  customIsValid(): boolean {
    let isValid: boolean =
      this.noDuplicateParams() &&
      this.firstStep.valid &&
      this.secondStep.valid &&
      this.thirdStep.valid;

    return isValid;
  }

  deleteTest(test: string) {
    let tests: UntypedFormGroup = <UntypedFormGroup>this.secondStep.get('tests');
    let testNumber: number = Number(test.split('test')[1]);
    for (let key in tests['controls']) {
      if (Number(key.split('test')[1]) > testNumber) {
        tests.setControl('test' + testNumber.toString(), tests.get(key));
        testNumber++;
      }
      if (
        Number(key.split('test')[1]) === Object.keys(tests['controls']).length
      ) {
        tests.removeControl(key);
      }
    }
  }

  previewEditorInit(event, isFirst?: boolean) {
    this.editorPreview = event;
    this.editorOptions.readOnly = true;
    this.editorPreview.updateOptions(this.editorOptions);
    if (isFirst) {
      this.updatePreview(isFirst);
    } else {
      this.updatePreview();
    }
  }

  updatePreview(isFirst?: boolean) {
    if (this.testCode && !isFirst) {
      this.editorPreview.setValue(this.testCode);
    } else {
      this.editorPreview.setValue(this.getFunctionString());
    }
  }

  getFunctionString() {
    let codePreview: string = 'def ';
    let params: string = '';
    for (let param in this.firstStep.get('paramNames').value) {
      params += this.firstStep.get('paramNames').value[param].name + ',';
    }
    params = params.substr(0, params.length - 1);

    codePreview +=
      this.firstStep.get('functionName').value + '(' + params + '): \n    ';
    return codePreview;
  }

  testRunnerInit(event) {
    this.editorPreview = event;
    this.editorOptions.readOnly = false;
    this.editorPreview.updateOptions(this.editorOptions);
    this.updatePreview();
  }

  getToCompile() {
    // to continue
    this.shouldCompile = false;

    setTimeout(() => {
      this.tests = this.getTests();
      this.shouldCompile = true;
    }, 100);
  }

  getTests() {
    let toCompile: { code: string; tests: unitTests } = {
      code: this.editorPreview.getValue(),
      tests: {
        functionName: this.firstStep.value['functionName'],
        tests: [],
      },
    };
    for (let test in this.secondStep.value['tests']) {
      let toPush: unitTest = {
        result: {
          type: this.secondStep.value['resultType'],
          value: this.secondStep.value['tests'][test]['result'],
        },
        params: [],
      };
      for (let param in this.secondStep.value['tests'][test]['params']) {
        for (let paramType in this.firstStep.value['paramNames']) {
          if (this.firstStep.value['paramNames'][paramType]['name'] === param) {
            toPush.params.push({
              name: param,
              value: this.secondStep.value['tests'][test]['params'][param],
              type: this.firstStep.value['paramNames'][paramType]['type'],
            });
          }
        }
      }

      toCompile.tests.tests.push(toPush);
    }

    return toCompile;
  }

  updateTestCode() {
    this.testCode = this.editorPreview.getValue();
  }

  getParamsData() {
    let paramsData = { params: {} };
    paramsData['amount'] = this.firstStep.get('paramAmount').value;
    for (let param in this.firstStep.get('paramNames').value) {
      paramsData['params'][param] = JSON.parse(
        JSON.stringify(this.firstStep.get('paramNames').value[param])
      );
    }
    return paramsData;
  }
}
