import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from "../../hive/user/user.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private router: Router,
              private userService: UserService){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.userService.authSetLocalUser().then(result => {
      if(result){
        return true
      }else{
        this.router.navigateByUrl("/login").then()
      }
      })
  }

}
