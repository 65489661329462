<div class="course-panel-container position-center">
  <div class="course-panel-wrapper">
    <h1 *ngIf="course && !isHebrew">"{{ course?.title }}" {{ pageName }}</h1>
    <h1 *ngIf="course && isHebrew">{{ pageName }} של "{{ course?.title }}"</h1>
    <h1 *ngIf="!course && pageName">{{ pageName }}</h1>

    <div class="go-back-btn">
      <a
        *ngIf="backToLocation"
        routerLink="{{ backToRoute }}"
        i18n="go back to hive btn| go back to hive button@@goBackToHive"
      >
        <mat-icon *ngIf="!isHebrew">arrow_back</mat-icon>
        <mat-icon *ngIf="isHebrew">arrow_forward</mat-icon>
        back to the {{ backToLocation | uppercase }}</a
      >
      <div
        *ngIf="userRule === UserRole.student || onStudentView"
        class="forum-btn-wrapper"
      >
        <button
          class="user-panel-forum-btn"
          i18n="go to forum btn| go to forum btn@@goToForumBTN"
        >
          <span class="material-icons-outlined forum-icon">chat</span>
          Forum
        </button>
        <div class="bubble-toolip">
          <p
            i18n="go to forum page btn| go to forum page btn@@goToForumPageBTN"
          >
            Go To Forum Page
          </p>
        </div>
      </div>

      <div *ngIf="pageName === 'structure' || showIcons" class="title-icons">
        <mat-icon class="icon" (click)="onStructureViewChange('listview')"
          >format_list_bulleted</mat-icon
        >
        <p>|</p>
        <mat-icon class="icon" (click)="onStructureViewChange('tabview')">
          dashboard
        </mat-icon>
      </div>
    </div>
  </div>
</div>
